import React from 'react'
import Header from '../header/Header'
import Footer from './footer/Footer'

const Privacypolicy = () => {
  return (
     <>
       <Header/>
       <div className='terms'>
       <div className='container'>
        <h1>Canopro Privacy Policy</h1>
        <ul >
            <li>
                <a href='#1'>Introduction:</a>
            </li>
            <li>
            <a href='#2'> Data Collection</a>
            </li>
            <li>
            <a href='#3'> Policy for Children</a>
            </li>
            <li >
            <a href='#4'>  Postal policy</a>
            </li>
            <li >
            <a href='#5'>  Data Storage and Retention</a>
            </li>
        </ul>
   
    <div id='1'>
       
            <h2>1.Introduction:</h2>
            <p>
                This Privacy Policy contains the terms of a legal agreement between
                you, the user of the Online website. You give us your express permit
                to use, collect, access, process, store, disclose and protect the
                personal information that you voluntarily provide to us or Otherwise
                visiting/accessing the online site. Third-party platforms that are
                linked to the Online Platform (and may have their own privacy policies
                or privacy notices) are not covered by this Policy. By checking the
                box next to the phrase "I agree to the terms and conditions", you
                acknowledge your understanding of this policy.
            </p>

            <p>
                IF YOU DO NOT AGREE WITH OUR POLICY, DO NOT USE OR ACCESS THIS ONLINE
                SITE. You can choose whether to give us your consent or to withdraw
                consent before you gave it, unless you notify us in writing of your
                choice. If you refuse to provide us with personal information or
                cancel your agreement to provide us with personal information at any
                time, we reserve the right to refuse to provide benefits or services
                for the purposes related to the above personal information.
            </p>
        
    </div>
    <div id='2'>
       
            <h2>2.Data Collection</h2>
            <p>
                By using our services, you agree to this agreement, either for
                yourself or for the entity you represent. You confirm that you have
                the legal right and authority to do so. If you're under 21, you can't
                use our services. If you don't agree to all the terms, please don't
                access or use our site.
            </p>
        
       
            <h3>Information Users Provide Us:</h3>
            <p>
                We may collect and use the following information about you: Information You Give Us - When creating a
                user account or using our Services, we may ask you for personal information, including sensitive
                information. </p>

            <p>Online based platforms. You hereby undertake to provide us with true information and to ensure that we
                update it as and when required. You directly provide us with the information listed below, which allows
                you to use our online sites:

            <ul>
                <li>Identifying information, such as your name and photo, which is required to register a legitimate
                    profile on an online site.
                </li>
                <li>National Identifiers – Any information on a government-issued ID card will be used to match your
                    identity to the profile you set up and registered with.</li>
                <li>Correspondence Information: Email, Telephone and Postal Address. These details are used to contact
                    you, answer your questions and provide other support services. If you participate in our surveys,
                    you may voluntarily provide us with additional information.
                </li>
                <li>Financial data such as bank account information, UPI, tax identification information and information
                    about digital assets such as wallet addresses and other information are all used to process and
                    execute transactions on online platforms.
                </li>
                <li>Location Data - We may ask for your location data in order to provide you with access to certain
                    online platform features or to provide you with certain services if we suspect any transactions to
                    be fraudulent.
                </li>
                <li>Any additional information you choose to share with us during customer service interactions,
                    surveys, or to assist with legal or investigative issues.
                </li>
            </ul>
            </p>
        
       
            <h3>Information we collect about you</h3>
            <p>
                When you engage with us, we may additionally receive the following information from you:
            </p>

            <p>
            <ul>
                <li>Technical data such as Internet Protocol (IP) address, login credentials, browser specifications,
                    time zone settings, types and versions of browser plug-ins, operating system, platform and other
                    data of a similar nature.

                </li>
                <li>Timestamped Uniform Resource Locators (URL) clickstream, transactions you have made or attempted to
                    make, items you have viewed and searched for, how quickly a page responds to your request,
                    information about your visit including download errors, how long you spent on specific pages, how
                    you scrolled through the page, You clicked, moused over, how you got away from it, and any phone
                    number you use to call our customer service number.
                </li>
                <li>Details of any funds transfer requests you make when using our online platforms to receive services.
                    We may collect information about such transactions provided by you or selected third parties in such
                    transactions.
                </li>
                <li>Metadata is generally technical information about the content you upload, post or share on a
                    website. Users have the option to add metadata such as location, date, attribute and other
                    information to their content.
                </li>
                <li>Depending on the features available, our online platforms use cookies and tracking technology. In
                    addition, it helps us improve our services and online sites. If you engage with content provided by
                    third parties through our online sites, they may also use cookies and tracking technology. We try to
                    inform you about these cookies before they are used and give you the option to accept or reject
                    them.
                </li>
            </ul>
            The processing of the information mentioned below enables us to constantly monitor and update our online
            platforms and services, ensuring that the services provided to you are in good condition.
            </p>
        
       
            <h3>Information we collect from outside sources</h3>
            <p>
                We may receive information about you from third parties if you have given us your permission to share
                information with us. For our Services, we may aggregate and process such data internally. We also
                collaborate closely with certain carefully selected third parties (for example, subcontractors such as
                ad networks, analytics providers, search information providers and infrastructure services, including
                cloud service providers), and may receive information about you from them. For analysis, we may
                additionally aggregate data from different browser types.
            </p>
        
       
            <h3>The main purpose of the collection</h3>
            <p>
                Personal information and other information mentioned above will only be used to set up users' respective
                accounts. As stated earlier, reserves the right to temporarily or permanently block users' accounts if
                any suspicious behavior is detected.
            </p>
        
       </div>
       <div id="3">
            <h2>3.Policy for Children </h2>
            <p>
                People of any age are prohibited from entering canopro.exchange. Only persons over the age of 21 are
                eligible to use the Website. canopro.exchange disclaims any responsibility to any youth who falsifies
                their personal information.
            </p>
        </div>
        <div id="4">
       
            <h2>4.Postal policy</h2>
            <p>
                canopro.exchange collects the email addresses of its users to provide access to its service and to
                inform users about the latest developments through newsletters, advertisements, and press releases.
                Collected email addresses will not be rented or sold to anyone else. However, users can change their
                subscription preferences. They are obligated to receive every canopro.exchange promotion, newsletter
                and press release unless they click the "unsubscribe" button in their email.
            </p>
        
       
            <h4>Changes to Privacy Policy </h4>
            <p>
                The canopro.exchange Privacy Policy outlined in this document is subject to change at any time. Our
                users must stay abreast of changes. However, we may send them a letter informing them of the changes 30
                days in advance.
            </p>
        </div>
        <div id="5">
            <h2>5.Data Storage and Retention</h2>
            <p>
                Not with standing anything stated here or in the Terms, we will retain information and/or data you
                provide on file for as long as your user account is active, to resolve disputes, and as necessary for
                ongoing investigations or legal proceedings. Any suspicious or illegal transactions, to enforce any
                contracts, and/or as required by applicable laws. The retention period may be extended by applicable
                law, but only for the time required to uphold the law and provide our services to you.
            </p>
            <p>

                We make no promises and shall not be liable for your User Account concerning the unavailability or
                retention of any information and/or data submitted by you after deletion.
            </p>
        </div>
        
    </div>
    </div>
    <Footer/>
     </>
  )
}

export default Privacypolicy