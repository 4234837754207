import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PublicRoute = ({ Component }) => {
   
    let isShow = Cookies.get("jwtToken") === null || Cookies.get("jwtToken") === undefined ? true : false ;

    return isShow ? <Component /> : <Navigate to="/" />;
};

export default PublicRoute;