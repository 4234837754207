import React,{useState} from "react";
import { postData, postGetData } from "../../../AxiosInstance/AxiosInstance";
import { Apiservices } from "../../../ApiServices/ApiServices";
import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";
import { useNavigate } from "react-router-dom";
import Header from "../../header/Header";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import Loader from 'react-js-loader';

const ManualDeposit = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fetchBank, setFetchBank] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState(null);
  const [uproEquivalent, setUproEquivalent] = useState("");
  const [imageError, setImageerror] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectErrors, setSelectErrors] = useState(null);
  const [errors, setErrors] = useState({});
  const [checkError, setCheckError] = useState(false);
  const [uproPrice, setUproPrice] = useState(null);
  const [imgSize, setImgSize] = useState()
  const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [selc, setSelc] = useState(false);
    const [noAccounts, setNoAccounts] = useState("")


    const [formData, setFormData] = useState({
      accountNumber: "",
      IFSC: "",
      bankName: "",
      branchName: "",
      amount: "",
      accountHolderName: ""
    });

    const [adminBankDetails, setAdminBankDetails] = useState({
      accountNumber: '',
      accountHolderName: '',
      bankName: '',
      accountIfscCode: '',
      branchName:''
    });

  const getAdminBankDetails =async () => {
   
      
        const response=await postGetData(Apiservices.USER_ADMIN_REST_API)
        if(response.status){

            const { accountNumber, accountHolderName, bankName, IFSC,branchName } = response.bankDetails[0];
            setAdminBankDetails({
              accountNumber,
              accountHolderName,
              bankName,
              accountIfscCode: IFSC,
              branchName
            });
        }
        
      
      
    };
    const getUser = async () => {
      const response = await postGetData(Apiservices.USER_GETUSER_REST_API);
      if (response.status) {
        const user = response.user;
        if (user.bankDetails && user.bankDetails.length > 0) {
          const verifiedBankDetails = user.bankDetails.filter(detail => detail.bankVerifyStatus === 2);
          if (verifiedBankDetails.length > 0) {
            setFetchBank(verifiedBankDetails);
          } else {
            setNoAccounts("No verified bank accounts found.");
          }
        } else {
          setNoAccounts("No bank accounts added. Please add your bank account in the Banking Page.");
        }
      } else {
        setNoAccounts("No user found.");
      }
    }
    useCustomEffect(()=>{
      getAdminBankDetails()
      getUser()
    })

    const handleChange = async (e) => {
      const { name, value } = e.target;
      let filteredValue = value;
console.log(filteredValue,"^$^$^$^$^$^$^$^$^$^");
  
      setFormData({ ...formData, [name]: filteredValue });
  

      setErrors({ ...errors, [name]: "" });
    };
  
 
  
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0]; // Get the selected file
      if (selectedFile) {
        if (selectedFile.size > 4000 && selectedFile.size < 200000) {
  
          setImageerror(prevFormData => ({
            ...prevFormData,
            proffront: ""
          }));
  
          const allowedTypes = ['image/jpeg', 'image/png'];
  
          if (!allowedTypes.includes(selectedFile.type)) {
            toast.error("Upload valid image format");
            setProfileImage(null);
            console.log("Invalid file format");
          } else {
            setSelectedFile(selectedFile);
  
            // Create URL for the file and set it to profileImage state
            setProfileImage(URL.createObjectURL(selectedFile));
          }
        } else {
          setImageerror(prevFormData => ({
            ...prevFormData,
            proffront: "Image Size 40 - 200 kb"
          }));
        }
      } else {
        console.error("No file selected");
      }
    };

   async function handlePost() {
    
   
      
      
      const formDataToSend = new FormData();
      const sanitizedAmount = formData.amount.toString().replace(/,/g, '');
      formDataToSend.append("amount",sanitizedAmount);
      formDataToSend.append("accountNo", formData.accountNumber);
      formDataToSend.append("IFSC", formData.IFSC);
      formDataToSend.append("bankName", formData.bankName);
      formDataToSend.append("branchName", formData.branchName);
      formDataToSend.append("currencyName", "INR");
      formDataToSend.append("depositeMethod", "Manual");
      
      
      formDataToSend.append("image", selectedFile);
      
      const response=await postData(Apiservices.USER_DEPOSITE_VERIFY,formDataToSend)
          if (response.status) {
            toast.success(response.message);
            setProfileImage("");
            setShow(false)
            setFormData((prev)=>({
              ...prev,
              amount:""

            }));
            setLoading(false)
            setSelectedAccount(null);
            setSelectedFile("")
            setErrors({})
            setImageerror("")
          } else {
            setLoading(false)

            setFormData((prev)=>({
              ...prev,
              amount:""

            }));
            toast.dismiss();
            setProfileImage("");
        
            setSelectedFile("")
  
     
            setShow(false)
  
            toast.error(response.message);
            
            setSelectedAccount(null);
            
  setErrors({})
  setImageerror("")
  
          }
        
        
    }
  
    const handleSubmit = (e) => {

  
      let ImgSize1 = false;
  
      if (!selectedFile) {
        setImageerror((prevFormData) => ({
          ...prevFormData,
          proffront: "Receipt Image Required",
        }));
      } 
      else 
      {
  
        if (selectedFile.size > 4000 && selectedFile.size < 200000) {
          setImageerror((prevFormData) => ({
            ...prevFormData,
            proffront: "",
          }));
  
          ImgSize1 = true;
  
        }
        else {
          setImageerror((prevFormData) => ({
            ...prevFormData,
            proffront: "Image 40 - 200 kb",
          }));
        }
      }
      console.log(formData,"FORMDATA",errors,")))))))))");
      let isAmountEmpty = false;
  if(formData.amount !=''){
    isAmountEmpty = true;
  }
  else{
    setErrors((prevFormData)=>({
      prevFormData,
      amount:"Amount Is Required"

    }))
  }
 
    
          if (selectedFile && selectedAccount) {
            if(ImgSize1 && isAmountEmpty)
              {
                setLoading(true)
                handlePost(formData);
                setErrors({});
              
            }
  
          }
  
     
      
      
    
  
   
    };
    const handleDropdownSelect = (selectedBank) => {
      if (selectedBank) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          accountHolderName: selectedBank.accountHolderName,
          accountNumber: selectedBank.accountNumber,
          IFSC: selectedBank.IFSC,
          bankName: selectedBank.bankName,
          branchName: selectedBank.branchName,
          
        }));
        setSelectErrors("")
        setSelectedAccount(selectedBank.accountHolderName);
        setSelc(true);
      }
    };

    const handleShow = (e) => {
      e.preventDefault();
      if (!selectedAccount) {
        setSelectErrors("Please select an account");
        return;
      } else {
        setSelectErrors("");
        setShow(true)
      }
  
    };
    const copyToClipboard = (copy) => {
      const el = document.createElement('textarea');
      el.value = copy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      toast.success("Copied successfully");
      toast.dismiss()
  };

  return (
   <>
   {loading ? (
     <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />

   ):(
    <>
    <Modal
           show={show}
           onHide={() => setShow(false)}
           className="buycr suportmod"
           backdrop="static" size="md"
         >

           <Modal.Header closeButton>
             <Modal.Title>User's Account Details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
             <div className="upic">

               <Form autoComplete="off">
               <Form.Group
                   className="mb-3 inpt inptbx flin"
                   controlId="formBasicEmail"
                 >
                   <Form.Label>Account Number</Form.Label>
                   <Form.Control
                     value={formData.accountNumber}
                     name="utrId"
                     type="text"
                     readOnly
                     
                   />
                   <p id="error">{errors.accountNumber}</p>
                 </Form.Group>
                 <Form.Group
                   className="mb-3 inpt inptbx flin"
                   controlId="formBasicEmail"
                 >
                   <Form.Label>Bank Name</Form.Label>
                   <Form.Control
                     value={formData.bankName}
                     name="utrId"
                     readOnly
                     type="text"
                   />
                 </Form.Group>
                 <Form.Group
                   className="mb-3 inpt inptbx flin"
                   controlId="formBasicEmail"
                 >
                   <Form.Label>Account Holder Name </Form.Label>
                   <Form.Control
                     value={formData.accountHolderName}
                     name="utrId"
                    readOnly
                     type="text"
                    
                   />
                 </Form.Group>
                 <Form.Group
                   className="mb-3 inpt inptbx flin"
                   controlId="formBasicEmail"
                 >
                   <Form.Label>IFSC Code</Form.Label>
                   <Form.Control
                     value={formData.IFSC}
                     name="utrId"
                     readOnly
                  
                     type="text"
                     maxLength={23}
                   />
                 </Form.Group>
                 <Form.Group
                   className="mb-3 inpt inptbx flin"
                   controlId="formBasicEmail"
                 >
                        <Form.Label>Amount</Form.Label>
                                 <CurrencyFormat
                                   value={formData.amount}
                                   name="amount"
                                   thousandSeparator={true}
                                   prefix={""}
                                   decimalScale={2}
                                   allowNegative={false}
                                   allowEmptyFormatting={false}
                                   allowLeadingZeros={false}
                                   decimalSeparator="."
                                   fixedDecimalScale={true}
                                   inputMode="numeric"
                                   onChange={handleChange}
                                 />
                                 <p id="error" className="error-message">
                                   {errors.amount}
                                 </p>
                 </Form.Group>

                
                 <div className='upld'>
                   <div className="upl">
                     <span>Upload Deposit Receipt</span>
                     <label>
                       <input name='proofFront' accept="image/*" onChange={handleFileChange} type='file'></input>
                       {profileImage ? (
                         <p>
                           <img
                             style={{ width: "100px" }}
                             src={profileImage}
                             alt="profile"
                             draggable="false"
                           />
                         </p>

                       ) : (
                         <p>Receipt Image</p>
                       )}
                     </label>
                     <p id="error">{imageError.proffront}</p>
                   </div>
                   <div className="showim">
                     <strong>Upload Receipt Image</strong>
                     <p> receipt image should be clear and the minimum size image 40kb and  maximum size imgae 200kb</p>

                   </div>
                 </div>

               </Form>
             </div>
           </Modal.Body>
           <Modal.Footer>
             <button type="submit" className="btmt btns" onClick={handleSubmit}>
               Submit
             </button>
             <button type="submit" className="cans btns" onClick={() => setShow(false)} >
               cancel
             </button>
           </Modal.Footer>

         </Modal>
   <Header/>
    <div className='man_depo'>
      
    <div className='by_crypc'>
           <div className='container'>
             <div className='bys_co'>
               <div className='buy_title'>
                 <h1>Bank Deposit</h1>
                 <ul className="sup_his">
                   <li className='his' onClick={() => navigate('/buyCryptoHistory')} >History</li>
                   <li className='supports' onClick={() => navigate('/support')}  >
                     Support
                   </li>
                 </ul>
               </div>
               <div className="by_content">
                 <div className="admi_det">
                   <strong>Admin's Bank details</strong>
                   <ul>
                     <li><strong>Account Number </strong><span>:</span>{adminBankDetails.accountNumber} <img onClick={()=>copyToClipboard(adminBankDetails.accountNumber)} src='images/copy_icon.svg' alt='copy' draggable="false"></img><span></span></li>
                     <li><strong>Bank Name </strong><span>:</span> <span>{adminBankDetails.bankName} </span></li>
                     <li><strong>IFSC Code </strong><span>:</span> <span>{adminBankDetails.accountIfscCode}<img onClick={()=>copyToClipboard(adminBankDetails.accountIfscCode)} src='images/copy_icon.svg' alt='copy' draggable="false"></img></span></li>
                     <li><strong>Account Holder Name </strong><span>:</span> <span>{adminBankDetails.accountHolderName}</span></li>

                   </ul>
                 </div>
               </div>
               <div className='by_man'>
                   <div className='mandepo_lft'>
                       <strong>Disclaimer : </strong>
                       <p>Depositing cryptocurrencies involves significant risk, including potential loss of your entire investment. Transactions are irreversible; verify details carefully. Canopro Exchange does not provide financial advice. Ensure compliance with local laws and take security measures seriously. By proceeding, you agree to our terms and conditions.</p>
                   </div>
                   <div className='mandepo_rgt'>
                       <p>Please proceed with the payment to the recipient details provided below.</p>
                       <div className="selnc">
                  
                         
                       <DropdownButton
                       variant="outline-secondary"
                       title={selectedAccount || "Select Account"}
                       id="input-group-dropdown-1"
                       className="drd"
                     // onSelect={handleDropdownSelect}
                     >
                       {fetchBank.map((bank, index) => (
                         <Dropdown.Item
                           onClick={() => handleDropdownSelect(bank)}
                           key={index}
                         >
                           {bank.accountNumber} {bank.bankName}

                         </Dropdown.Item>
                       ))}
                       {noAccounts}
                     </DropdownButton>
                     </div>
                     <p id="error">{selectErrors}</p>
                       <div className='note'>
                          <strong> Note :  </strong>
                          <ul>
                           <li><p>Transfer funds only from your Canopro Exchange account.</p></li>
                           <li><p>Don't use Gpay, PhonePe, or Paytm; only bank transfers are accepted.</p></li>
                           <li><p>Avoid using words like bitcoin or cryptocurrency in transaction remarks; </p></li>
                           <li><p>add your registered phone number instead.</p></li>
                          </ul>
                       </div>
                       <div className='mndep'>
                         
                          {/* <ul>
                           <li><span>Account Number</span><span>123645564564694</span></li>
                           <li><span>Bank Name</span> <span>State Bank Of India</span></li>
                           <li><span>Account Holder Name </span> <span>John </span></li>
                           <li><span>IFSC Code</span> <span>SBIN544654</span></li>
                          </ul> */}
                          <button type='button' className='btns btnr' onClick={handleShow}>Proceed</button>
                       </div>
                       
                    
                   </div>
               </div>
           </div>
       </div>
    </div>
    </div>
    </>
   )}
  
   </>
  )
}

export default ManualDeposit