import React from 'react'
import Header from '../header/Header'
import Footer from './footer/Footer'

const TermsConditions = () => {
  return (
      <>
        <Header/>
        <div className='main-content'>
         <div className='terms'>
            <div className='container'>
        <h1>Canopro Terms of Services</h1>
        <ul >
            <li >
              <a href='#1'>  Introduction:</a>
            </li>
            <li>
            <a href='#2'>  Terms of acknowledgement:</a>
            </li>
            <li>
            <a href='#3'>  Alteration of Terms & Services: </a>
            </li>
            <li>
            <a href='#4'> Understanding Key Terms and Concepts: </a>
            </li>
            <li>
            <a href='#5'>   Account Management and Responsibilities:</a>
            </li>
            <li>
            <a href='#6'>   Your Commitments and Obligations:</a>
            </li>
            <li>
            <a href='#7'>   Respecting Intellectual Property </a>
            </li>
            <li>
            <a href='#8'>   Important Points to Note</a>
            </li>
            <li>     <a href='#9'>Get in Touch </a></li>
        </ul>
    
    <div id='1'>
       
            <h2 >1.Introduction:</h2>
            <p>
                By using our website or mobile apps, you agree to follow these terms.
                When we say "Canopro Exchange," we mean us, and when we say "you," we
                mean you or the business you represent.
            </p>
       
       
            <h3>Accepting the Terms:</h3>
            <p>
                By using our services, you're confirming that you have the authority
                to agree to these terms.
            </p>
       
       
            <h3>Our Services:</h3>
            <p>
                We provide an online platform for trading digital assets. These terms
                cover all your interactions with Canopro Exchange.
            </p>
       
       
            <h3>Priority of Terms:</h3>
            <p>
                These terms are the main rules for using Canopro Exchange. They're
                more important than any other guidelines
            </p>
       
    </div>
    <div id="2">
       
            <h2>2.Terms of acknowledgement:</h2>
            <p>
                By using our services, you agree to this agreement, either for
                yourself or for the entity you represent. You confirm that you have
                the legal right and authority to do so. If you're under 21, you can't
                use our services. If you don't agree to all the terms, please don't
                access or use our site.
            </p>
       
    </div>
    <div id="3">
       
            <h2>3.Alteration of Terms & Services:</h2>
            <p>
                We may change our terms of service at any time, with or without
                notifying you beforehand. While we'll try our best to inform you, we
                can't guarantee it. Be sure to check our terms of service periodically
                for any updates. If you continue to use our services after we change
                our terms, it means you accept those changes.
            </p>
       
    </div>
    <div id='4'>
       
            <h2>4.Understanding Key Terms and Concepts:</h2>
            <p>
                Canopro Exchange is a global platform where you can buy, sell, and
                trade digital assets like cryptocurrencies anytime, anywhere.
            </p>
       
       
            <h3>Digital Currencies:</h3>
            <p>
                These are digital tokens or cryptocurrencies with a set value, built
                on blockchain and cryptography technologies, managed in a
                decentralized way.
            </p>
       
       
            <h3>Spot Trading:</h3>
            <p>
                This is buying and selling assets at the current market rate (spot
                price), aiming to get the asset immediately. It's popular among day
                traders for its affordability and absence of expiration dates.
            </p>
       
       
            <h3>Content:</h3>
            <p>
                This includes any data, text, graphics, or materials uploaded by the
                Company or users, visible to others on our platforms.
            </p>
       
       
            <h3>Funds:</h3>
            <p>
                This term covers both fiat money (traditional currencies) and digital
                assets, depending on the context.
            </p>
       
       
            <h3>Linked Bank Account:</h3>
            <p>
                This is any bank account owned by an Indian User, maintained with a
                Scheduled Commercial Bank, and provided during the activation process.
            </p>
       
    </div>
    <div id='5'>
       
            <h2>5.Account Management and Responsibilities:</h2>
       
       
            <h3>Account Creation:</h3>
            <p>
                To use our services, you'll need to register an account with us. You
                must be at least 21 years old to register. Once you provide the
                required information and it's verified, we'll issue your account.
                Please ensure the information you provide is accurate. We reserve the
                right to deactivate your account if we find any inaccuracies.
            </p>
       
       
            <h3>Account Verification:</h3>
            <p>
                We may need to verify your account for various reasons, including
                legal requirements, trade disputes, or fraud investigations. In rare
                cases, we may ask for more information about the origin of your funds.
            </p>
       
       
            <h3>Account Responsibilities:</h3>
            <p>
                You're responsible for keeping your login information and other
                credentials secure. Any actions taken under your account are your
                responsibility. If you suspect unauthorized use of your account, let
                us know immediately. Failure to do so may result in losses for which
                Canopro Exchange won't be liable.
            </p>
       
       
            <h3>Canopro Wallet:</h3>
            <p>
                Once your account is activated, you'll have a default Wallet in your
                dashboard. You can use this Wallet to send, receive, and store fiat
                and digital assets supported by our platform. You'll need a minimum
                balance in your wallet to make trades. We may refuse transactions that
                violate our AML Policy or require compliance with official directives.
            </p>
       
    </div>
    <div id="6"> 
       
            <h2>6.Your Commitments and Obligations:</h2>
            <p>
                By using our website or mobile apps, you agree to follow these terms.
                When we say "Canopro Exchange," we mean us, and when we say "you," we
                mean you or the business you represent.
            </p>
       
       
            <h3>Accepting the Terms:</h3>
            <p>
                <span>1.Understanding Your Duties: </span>These terms outline your
                responsibilities and behavior on our online platforms. By using our
                services, you declare that you've read, understood, and agreed to
                these terms.
            </p>
            <p>
                <span>2.Government Sanctions: </span>If your account is linked to a
                government-sanctioned program requiring us to restrict funds or
                digital assets, we may suspend, terminate, or set limits on your
                account. We're not liable for any losses due to compliance with laws
                or government directives.
            </p>
            <p>
                <span>3.Illegal Activities: </span>We'll freeze accounts engaged in
                illegal activities like bot trading. After verification, deposited
                amounts will be disclosed, and accounts will be permanently
                deactivated.
            </p>
            <p>
                <span>4.Content Responsibility: </span>You're responsible for all
                content on your account.
            </p>
            <p>
                <span>5.Account Security: </span>Keep your account information secure,
                including passwords. We're not responsible for losses if you neglect
                security measures. Cancelling your account means permanently losing
                digital assets.
            </p>
            <p>
                <span> 6.Providing Accurate Information: </span>Provide accurate
                information, including identification documents.
            </p>
            <p>
                <span>7.Order Management: </span>Orders can't be canceled once placed,
                but partially filled orders may be canceled. Orders can't be modified
                after submission.
            </p>
            <p>
                <span> 8.Sufficient Funds: </span>Ensure you have enough funds in your
                wallet before placing orders. Insufficient funds may result in order
                cancellation or partial completion.
            </p>
            <p>
                <span>9.Tax Responsibility:</span> You're responsible for any taxes
                incurred from trading digital assets, by applicable laws.
            </p>
            <p>
                <span>10.Market Volatility:</span> Understand that market rates for
                digital assets can change rapidly, affecting order execution.
            </p>
       
    </div>
    <div >
       
            <h3>Charges and Costs:</h3>
            <p>
                In exchange for using our services at Canopro Exchange, you agree to
                pay the fees listed on our website. We'll debit your account for these
                fees once the service is complete. Canopro Exchange reserves the
                right to change fees or pricing methods at any time in line with this
                agreement's terms.
            </p>
       
       
            <h4>Account Opening:</h4>
            <p>There's no fee to open an account on our online platform.</p>
       
       
            <h4>Transaction Fees:</h4>
            <p>
                Each transaction completed on our platform incurs a transaction fee.
                This fee covers costs for incoming and outgoing trades, as well as
                charges for successfully executed deals. Refer to our Fee Schedule for
                details on Transaction Fees.
            </p>
       
       
            <h4>Cryptocurrency Transfers:</h4>
            <p>
                Fees may apply when you add or withdraw supported cryptocurrencies
                from your wallet to another exchange. No fees are charged for
                transactions within your Canopro Exchange account.
            </p>
       
       
            <h4>Third-Party Intellectual Property:</h4>
            <p>
                Parts of our online platforms may contain third-party intellectual
                property, such as software used for Digital Assets. Users are subject
                to the license terms for this third-party IP, even if Canopro
                Exchange doesn't own any rights to it.
            </p>
       
    </div>
    <div id='7'>
       
            <h2>7.Respecting Intellectual Property</h2>
            <p>
                In exchange for using our services at Canopro Exchange, you agree to
                pay the fees listed on our website. We'll debit your account for these
                fees once the service is complete. Canopro Exchange reserves the
                right to change fees or pricing methods at any time in line with this
                agreement's terms.
            </p>
       
       
            <h3>Ownership</h3>
            <p>
                Unless stated otherwise, Canopro Exchange owns or holds licenses for
                all content on our Online Platform. This content is protected by
                copyright, trademark, and other laws. Logos, trademarks, and service
                marks used on our platform belong to their respective owners,
                displayed under a license from Canopro Exchange. You're not allowed
                to copy or use them without our written permission.
            </p>
       
       
            <h3>Software and Services:</h3>
            <p>
                The software used for Digital Assets and other services on our
                platform is also intellectual property. You're bound by the same
                limits as Canopro Exchange. You can use these limited rights to
                access and use our platform for non-profit purposes only. Commercial
                use requires a license from Canopro Exchange.
            </p>
       
       
            <h3>Exclusion of Guarantees</h3>
            <p>
                Canopro Exchange, its affiliates, and all individuals associated with
                us won't be held responsible for indirect, consequential, or
                accidental damages resulting from using our services. We can't
                guarantee that our website or server is free of viruses, or that we're
                immune to security breaches or data loss due to online activity. We
                don't promise anything, including merchantability or fitness for a
                particular purpose, regarding the trading services we provide.
                Canopro Exchange isn't flawless, and our services may not always be
                available or error-free. Fluctuations in cryptocurrency values aren't
                our responsibility. Users are responsible for staying updated with
                daily changes.
            </p>
       
       
            <h3>Protection</h3>
            <p>
                You agree to defend, indemnify, and hold harmless Canopro Exchange,
                its officers, employees, and agents from any claims or demands made by
                third parties due to or arising from:
            <ul>
                <li>Your use of our service,</li>
                <li>Violation of this agreement,</li>
                <li>Violation of laws or regulations,and</li>
                <li>Use of third-party services.</li>
            </ul>
            At your expense, Canopro Exchange reserves
            the right to take over the defense of any issue for which you're
            obligated to indemnify us, and you agree to assist in our defense. You
            also agree not to settle any issues without Canopro Exchange's prior
            written approval. We'll make every effort to inform you of any such
            claims, actions, or proceedings once we become aware of them.
            </p>
       
    </div>
     <div >
   
        <h3>Charges and Costs:</h3>
        <p>
            In exchange for using our services at Canopro Exchange, you agree to
            pay the fees listed on our website. We'll debit your account for these
            fees once the service is complete. Canopro Exchange reserves the
            right to change fees or pricing methods at any time in line with this
            agreement's terms.
        </p>
   
   
        <h4>Account Opening:</h4>
        <p>There's no fee to open an account on our online platform.</p>
   
   
        <h4>Transaction Fees:</h4>
        <p>
            Each transaction completed on our platform incurs a transaction fee.
            This fee covers costs for incoming and outgoing trades, as well as
            charges for successfully executed deals. Refer to our Fee Schedule for
            details on Transaction Fees.
        </p>
   
   
        <h3>Managing Accounts</h3>
        <h4>Violation Consequences: </h4>
        <p>
            If we find you've violated the Terms or Applicable Law, we can suspend, block, reverse, or cancel
            transactions and deny access to our Online Platforms, even if money has been deducted from your account.
        </p>
   
   
        <h4>Account Closure:</h4>
        <p>
            When a User Account is closed, all remaining balances (including charges and liabilities) become immediately
            payable to Canopro Exchange. You'll have a reasonable number of business days to withdraw funds after
            paying outstanding charges. If an account is suspended or closed due to fraud, investigations, or
            violations, Canopro Exchange may retain custody of funds and user data.
        </p>
   
    </div>
    <div id="8">
       
            <h2>8.Important Points to Note </h2>
       
       
            <h3>English Language:</h3>
            <p>
                Our Acceptable Use Policy, Privacy Policy, and Terms & Conditions are all written in English. The
                English versions of these documents take precedence over translations into other languages.
            </p>
       
       
            <h3>No Oral Agreements:</h3>
            <p>
                Any verbal statements or information provided by us won't change how this Agreement is understood. By
                agreeing to this Agreement, you confirm you haven't relied on any representations not specifically
                mentioned in these Terms and Conditions.
            </p>
       
       
            <h3>Liability Disclaimer:</h3>
            <p>
                While this Agreement doesn't disclaim liability for false claims or actions, it doesn't make any
                representations about it either.
            </p>
       
       
            <h3>Transfer of Rights:</h3>
            <p>
                Any rights or obligations we transfer or assign to another party won't affect your rights or our
                obligations under this Agreement. Similarly, you can't transfer your rights or responsibilities under
                this Agreement to others.
            </p>
       
    </div>
    <div id="9">
       
            <h2>9.Get in Touch</h2>
       
       
            <h3>1.For General Inquiries:</h3>
            <p>
                Email us at info@Canopro.exchange for any questions, grievances, or feedback.
            </p>
       
       
            <h3>2.Account Deactivation:</h3>
            <p>
                If you decide to stop using our Services and want to deactivate your account, please email
                support@Canopro.exchange
            </p>
       

    </div>
    </div>
    </div>
    </div> 
    <Footer/>
      </>
  )
}

export default TermsConditions