import React,{useState} from 'react'
import { postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import ReactPaginate from "react-paginate";
import Header from '../../header/Header';

function PremiumHistory() {
    const [twentyFourStakingHistoryData, setTwentyFourStakingHistoryData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page
    const pageCount = Math.ceil(twentyFourStakingHistoryData.length / itemsPerPage);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
      };

    const displayedData = twentyFourStakingHistoryData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      );
    const twentyFourMonthsStakeHistory = async() => {
        const response=await postGetData(Apiservices.USER_PREMIUM_HISTORY)

        if(response.status){

            setTwentyFourStakingHistoryData(response.data)
        }

       
      }
      useCustomEffect(twentyFourMonthsStakeHistory)
    return (
      <>
      <Header/>
      <div className='prihis'>
        <div className='container'>
          <strong>Premium History</strong>
          <div className='table-responsive con'> 
                <table>
                                        <thead>
                                          <tr>
                                            <th>S.no</th>
                                            {/* <th>Name</th> */}
                                            <th>Currency Symbol</th>
                                            <th>Referral Code</th>
                                            <th>INR Amount</th>
                                            <th>Staked Amount</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {displayedData.length > 0 ? (
                                            displayedData.map((data, index) => {

                                              const monthNames = ["January", "February", "March", "April", "May", "June",
                                                "July", "August", "September", "October", "November", "December"
                                              ];

                                              const endDate = new Date(data.endTime);
                                              const endYear = endDate.getFullYear();
                                              const endMonth = monthNames[endDate.getMonth()];
                                              const endDay = endDate.getDate().toString().padStart(2, '0');
                                              const endHours = endDate.getHours().toString().padStart(2, '0');
                                              const endMinutes = endDate.getMinutes().toString().padStart(2, '0');
                                              const endSeconds = endDate.getSeconds().toString().padStart(2, '0');

                                              const endFormattedDate = `${endMonth} ${endDay}, ${endYear} ${endHours}:${endMinutes}:${endSeconds}`;

                                              const startDate = new Date(data.Create_at);
                                              const startYear = startDate.getFullYear();
                                              const startMonth = monthNames[startDate.getMonth()];
                                              const startDay = startDate.getDate().toString().padStart(2, '0');
                                              const startHours = startDate.getHours().toString().padStart(2, '0');
                                              const startMinutes = startDate.getMinutes().toString().padStart(2, '0');
                                              const startSeconds = startDate.getSeconds().toString().padStart(2, '0');

                                              const startFormattedDate = `${startMonth} ${startDay}, ${startYear} ${startHours}:${startMinutes}:${startSeconds}`;


                                              return (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{data.cur_name}</td>
                                                  {/* <td>{data.cur_symbol}</td> */}
                                                  <td>{data.referralCode}</td>
                                                  <td>{data.amount_inr}</td>
                                                  <td>{data.uproAmount}</td>
                                                  <td>{startFormattedDate}</td>
                                                  <td>{endFormattedDate}</td>
                                                </tr>
                                              );
                                            })
                                          ) : (
                                            <tr className="notr py-2 w-100 ">
                                              <td colSpan="8">No Records Found</td>
                                            </tr>
                                          )}


                                        </tbody>

                                      </table>
                                      {displayedData.length > 10 &&
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName={"page-link"}
                  breakLinkClassName={"break-link"}
                  disabledClassName={"disabled"}
                  initialPage={currentPage}
                  disableInitialCallback={true}
                  forcePage={currentPage}
                  // disableInitialCallback={true}
                  renderLink={(props) => {
                    if (
                      props.page === "..." &&
                      props.pageCount > props.pageRangeDisplayed
                    ) {
                      return (
                        <a
                          {...props}
                          onClick={() =>
                            handlePageClick({
                              selected: pageCount - 1,
                            })
                          }
                        >
                          {pageCount}
                        </a>
                      );
                    }
                    return <a {...props} />;
                  }}
                />
              }
          </div>
       </div>
    </div>
    </>
  )
}

export default PremiumHistory