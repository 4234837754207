import React from 'react'
import "./Footer.css"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Footer = () => {
  const navigate = useNavigate();
   const scrltop = () => {
     window.scroll(0,0);
   }
  return (
    <footer>
    
      <Container>
        <Row className='fots'>
          <Col className="foot-a foot">

            <p>
              Embark your journey with Canopro for fast and seamless trading!
            </p>
          </Col>
          <Col className="foot-c foot">
            <strong>About Canopro</strong>
            <ul className='fcb'>

              <li>NFT</li>
              <li>Fees</li>
              <li>Campaigns</li>
              <li>Referral</li>
              <li>DeFi</li>
            </ul>
          </Col>
          <Col className="foot-d foot">
            <strong>Futures</strong>
            <ul className='fcb'>

              <li>Futures Trading</li>
              <li>Futures 101</li>
              <li>Insurance Fund</li>
              <li>Contract Information</li>
              <li>Futures Guide</li>
            </ul>
          </Col>
          <Col className="foot-e">
            <div className='input-flt'>
              <strong>Support</strong>
              <ul className='fcb'>

                <li>Contact Support</li>
                <li>API</li>
                <li>Token Listing</li>
                
              </ul>
            </div>

          </Col>
        </Row>
        <div className='fot_med'>
            <strong>Contact Us</strong>
            <ul className='ftm'>
               <li>  <a href='tell:9280232864'><img src='images/fcall.svg' alt='call' draggable="false"></img> <span>92802 32864</span></a></li>
               
               <li>  <a href='mailto:contact@canopro.com' target='_blank'><img src='images/fmail.svg' alt='mail' draggable="false"></img> <span>contact@canopro.com</span></a></li>
            </ul>
            <strong>Follow Us</strong>
            <ul className='ftms'>
               <li>  <a href='https://medium.com/@Canoproexchange' target='_blank'><img src='images/medi.svg' alt='medium' draggable="false"></img> <span>Medium</span></a></li>
               
               <li>  <a href='https://www.facebook.com/CanoproExchange/' target='_blank'><img src='images/fb.svg' alt='fb draggable="false"'></img> <span>Facebook</span></a></li>
               <li>  <a href=' https://x.com/CanoproExchange ' target='_blank'><img src='images/twi.svg' alt='twitter' draggable="false"></img> <span>Twitter</span></a></li>
               <li>  <a href=' https://www.youtube.com/@Canopro_Exchange' target='_blank'><img src='images/utube.svg' alt='youtube' draggable="false"></img> <span>Youtube</span></a></li>
               <li>  <a href='https://t.me/Canopro_Exchange_Announcement' target='_blank'><img src='images/teleg.svg' alt='Telegram' draggable="false"></img> <span>Telegram</span></a></li>
               <li>  <a href='mailto:contact@canopro.com' target='_blank'><img src='images/ffmail.svg' alt='mail draggable="false"'></img> <span>Mail</span></a></li>
               <li>  <a href='https://www.instagram.com/canopro_exchange/ ' target='_blank'><img src='images/fins.svg' alt='Instagram' draggable="false"></img> <span>Instagram</span></a></li>
               <li>  <a href='https://linkedin.com/company/canopro-exchange' target='_blank'><img src='images/in.svg' alt='Linked In' draggable="false"></img> <span>Linkedin</span></a></li>
            </ul>
        </div>
        <div className='fot_ed'>
          <ul>
            <li onClick={() => navigate('/')}><img src="https://images-ultra.s3.amazonaws.com/hlogod.svg" alt="foot" draggable="false"></img></li>
            {/* <li>© Canopro 2024</li> */}
          </ul>
          <ul className='lst_mnu justify-content-end'>
            <li onClick={scrltop}><Link to="/termsconditions">Terms & Conditions</Link></li>

            <li onClick={scrltop}><Link to="/privacypolicy">Privacy Policy</Link></li>
            {/* <li>Cookie Policy</li>
            <li>E-sign Consent</li> */}
          </ul>
        </div>


      </Container>
    </footer>
  )
}

export default Footer