import React, { useState, useEffect } from 'react'
import Header from "../../header/Header"
import "./Home.css"
import Footer from '../footer/Footer'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";



const Homepage = () => {

   const [show, setShow] = useState(true);

   useEffect(() => {
      let isShow = Cookies.get("jwtToken") !== null && Cookies.get("jwtToken") !== undefined ? false : true;
      setShow(isShow);

   })


   const [bgTheme, setBgTheme] = useState();

   const navigate = useNavigate();

   useEffect(() => {

      const theme = localStorage.getItem('theme');

      setBgTheme(theme);

      // console.log(theme);

   }, [bgTheme])

   // console.log(bgTheme);


   return (
      <>
         <Header />
         <div className='main-content'>
            <div className='banner'>
               <div className='container'>
                  <div className='ban_c'>
                     <div className='ban_lft col-md-7'>

                        <h1>Trusted Cryptocurrency Exchange Platform</h1>
                        <p>Trade Bitcoin, Ethereum, and 150+ cryptos in seconds on our ultra-secure global cryptocurrency exchange</p>

                        <>
                           {show ? (
                              <ul className='hombt'>
                                 <li><button type='button' className='btns' onClick={() => navigate("/auth?tab=signin")}>Sign In</button></li>
                                 <li><button type='button' className='btns' onClick={() => navigate("/auth?tab=signup")}>Sign Up</button></li>

                              </ul>
                           )
                              :
                              (
                                 <ul>
                                    <li><button type='button' className='btns' onClick={() => navigate("/")}>Get Started</button></li>
                                 </ul>
                              )
                           }


                        </>
                     </div>
                     <div className='ban_rgt col-md-5'>
                        <img src='images/banrgt.png' alt='Canopro Exchange' draggable="false"></img>
                     </div>
                  </div>
               </div>
            </div>
            <div className='quick_guide'>
               <div className='container'>
                  <h2 className='hed'>Your Quick Start Guide</h2>
                  <div className='quick'>
                     <div className='quck_bx'>
                        <img src='images/pr.svg' alt='Sign Up' draggable="false"></img>
                        <h3>Sign Up On Canopro</h3>
                        <p>Initiate your crypto journey with a quick registration and KYC verification process</p>
                     </div>
                     <div className='quck_bx'>
                        <img src='images/lr.svg' alt='Transfer' draggable="false"></img>
                        <h3>Transfer INR to Wallet</h3>
                        <p>Transfer your fiat money(INR) to Canopro wallet with our secured payment gateway with ease</p>
                     </div>
                     <div className='quck_bx'>
                        <img src='images/bs.svg' alt='Buy and Sell' draggable="false"></img>
                        <h3>Trade any cryptocurrencies</h3>
                        <p>You can buy, sell, and trade any crypto and keep records with our eminent Canopro Exchange
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <div className='jwdrop py-5'>
               <div className='container'>
                  <div className='txtw'>
                     <h2 className='ps-5'>Other Jaw-Dropping Features of Canopro Exchange</h2>
                  </div>

                  <div className='d-flex col-12'>


                     <div className='col-lg-7 col-sm-9 d-flex justify-content-center'>

                        <div className='p-5 feac'>


                           <div className='py-2 txtwaa'>
                              <div className='d-flex txtwa'>
                                 <img src='images/arrw.svg' alt='' draggable="false" ></img>
                                 <h4 className='ps-3'>Impressive dashboard </h4>
                              </div>
                              <p className='p-3 text-secondary'>
                                 Stay updated with the eminent features involved with an all-encompassing dashboard that ensures you're always proactive
                              </p>
                           </div>

                           <div className='txtwaa'>
                              <div className='d-flex txtwa'>
                                 <img src='images/arrw.svg' alt='' draggable="false"></img>
                                 <h4 className='ps-3'>Live tracking</h4>
                              </div>
                              <p className='p-3 text-secondary'>
                                 You can update any of your records and withdraw for any reference. All these details will be provided up-to-date
                              </p>
                           </div>
                           <div className='txtwaa'>
                              <div className='d-flex txtwa'>
                                 <img src='images/arrw.svg' alt='' draggable="false"></img>
                                 <h4 className='ps-3'>Proper updates</h4>
                              </div>
                              <p className='p-3 text-secondary'>
                                 Empower yourself with enhanced P&L(profit and loss) tracking to make more informed decisions.
                              </p>
                           </div>
                           <div className='txtwaa'>
                              <div className='d-flex txtwa'>
                                 <img src='images/arrw.svg' alt='' draggable="false"></img>
                                 <h4 className='ps-3'>Referral program</h4>
                              </div>
                              <p className='p-3 text-secondary'>
                                 Refer and earn more with our referral link and earn hefty rewards. Get more profited with those rewards by trading!
                              </p>
                           </div>
                        </div>


                     </div>

                     <div className='col-5 frs'>

                        <div className='d-flex justify-content-end'>
                           <img src='images/dimond.svg' alt='Buy and Sell' draggable="false"></img>
                        </div>
                     </div>

                  </div>
               </div>
            </div>

            <div className='cor_feat'>
               <div className='container'>
                  <div className='p-5 unw'>
                     <h2 className='coref ps-5'>Core Features Of Canopro Exchange</h2>
                  </div>

                  <div className='d-flex  pb-5'>

                     <div className='col-12 d-lg-flex justify-content-center py-4 cor_bx'>

                        <div className='d-flex justify-content-center col-3 px-3'>
                           <div className=''>
                              <div className='d-flex justify-content-center imco'>
                                 <img src='images/kyc1.svg' alt='Buy and Sell' draggable="false"></img>
                              </div>
                              <div className='d-flex justify-content-center '>
                                 <h3 className='coref'>Flawless KYC verification</h3>
                              </div>
                              <div className='py-4'>
                                 <p className='text-secondary text-center'>
                                    Get started quickly and easily with our fast KYC process, ensuring all rules and regulations are followed up.
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div className='d-flex justify-content-center col-3'>
                           <div className=''>
                              <div className='d-flex justify-content-center imco'>
                                 <div>
                                    <div className='lit'>
                                       <img src='images/tims.svg' alt='Buy and Sell' draggable="false"></img>
                                    </div>
                                    <div className='drk'>
                                       <img src='images/timsd.svg' alt='Buy and Sell' draggable="false"></img>
                                    </div>
                                 </div>
                              </div>
                              <div className='d-flex justify-content-center '>
                                 <h3 className='coref'>24/7 customer support</h3>
                              </div>
                              <div className='py-4'>
                                 <p className='text-secondary text-center'>
                                    Our support team is here for you like a friend, available anytime to help you with the verification process smoothly and easily.
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div className='d-flex justify-content-center col-3 px-3'>
                           <div className=''>
                              <div className='d-flex justify-content-center  imco'>
                                 <img src='images/api.svg' alt='Buy and Sell' draggable="false"></img>
                              </div>
                              <div className='d-flex justify-content-center '>
                                 <h3 className='coref'>Ideal API trading</h3>
                              </div>
                              <div className='py-4'>
                                 <p className='text-secondary text-center'>
                                    Enhance your crypto trading experience with Canopro Exchange through smooth and easy API integration.
                                 </p>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>

               </div>
            </div>


            <div>
               <div className='jwdrops gtst'>
                  <div className='container'>
                     <div className='p-5'>
                        <h2 className='coref ps-5'>Security features of Canopro exchange</h2>
                     </div>
                     <div className='securitybx'>
                        <div className='security_lft'>
                           <img src='images/mobile.png' alt='mobile' draggable="false"></img>
                        </div>
                        <div className='security_rgt'>
                           <div className='securit'>
                              <div className='imsec'><img src='images/safeguard.svg' alt='safeguard' draggable="false"></img></div>
                              <h3>Safeguarding Your Digital Assets</h3>
                              <p>We prioritize the security of your digital assets with robust measures</p>
                           </div>
                           <div className='securit'>
                              <div className='imsec'><img src='images/transparent.svg' alt='transparent' draggable="false"></img></div>
                              <h3>Transparency</h3>
                              <p>We practice what we preach. Check out our transparency reports and proof of reserves, because informed users make better decisions.</p>
                           </div>
                           <div className='securit'>
                              <div className='imsec'><img src='images/twofa.svg' alt='twofa' draggable="false"></img></div>
                              <h3>2-Factor Authentication</h3>
                              <p>Strengthen your Canopro account with 2-factor authentication, choosing the setup that best suits you.</p>
                           </div>
                           <div className='securit'>
                              <div className='imsec'><img src='images/encryption.svg' alt='encryption' draggable="false"></img></div>
                              <h3>End-to-End Encryption</h3>
                              <p>Benefit from advanced encryption and top industry standards, ensuring your crypto trading and investments are secure and stress-free.</p>
                           </div>
                        </div>
                     </div>


                  </div>


               </div>
            </div>

            <div>
               <div className='our_com'>
                  <div className='container'>
                     <div className='p-5'>
                        <h2 className='coref ps-5'>Join Our Community</h2>
                        <p className='ps-5 text-secondary'>Keep up with the Canopro team and our traders from across the world. we have everything you need to become a crypto expert</p>

                        <div className='ps-5 py-3 ourcn'>
                           <div className='col-lg-6 col-12 soci'>

                              <div className='d-flex justify-content-between orcx'>
                                 <div>
                                    <div className='lit'>
                                       <a href='https://medium.com/@ultraproexchange' target='_blank'>
                                          <img src='images/mdm.svg' alt='Medium' draggable="false"></img><span><img src="images/mediumh.svg" alt="medium" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='https://medium.com/@ultraproexchange' target='_blank'>
                                          <img src='images/mdmd.svg' alt='Medium' draggable="false"></img>
                                          <span><img src="images/mediumh.svg" alt="medium" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>
                                    <div className='lit'>
                                       <a href='https://www.facebook.com/Ultrapro.exchange/' target='_blank'>
                                          <img src='images/fbs.svg' alt='facebook' draggable="false"></img>
                                          <span><img src="images/fbh.svg" alt="facebook" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='https://www.facebook.com/Ultrapro.exchange/' target='_blank'>
                                          <img src='images/fbsd.svg' alt='facebook' draggable="false"></img>
                                          <span><img src="images/fbh.svg" alt="facebook" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>
                                    <div className='lit'>
                                       <a href='https://twitter.com/UltraproXchange' target='_blank'>
                                          <img src='images/tetr.svg' alt='x' draggable="false"></img>
                                          <span><img src="images/twitterh.svg" alt="twitter" draggable="false"  ></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='https://twitter.com/UltraproXchange' target='_blank'>
                                          <img src='images/tetrd.svg' alt='x' draggable="false"></img>
                                          <span><img src="images/twitterh.svg" alt="twitter" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>
                                    <div className='lit'>
                                       <a href='https://www.youtube.com/@UltraproExchange' target='_blank'>
                                          <img src='images/ytb.svg' alt='youtube' draggable="false"></img>
                                          <span><img src="images/youtubeh.svg" alt="youtube" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='https://www.youtube.com/@UltraproExchange' target='_blank'>
                                          <img src='images/ytbd.svg' alt='youtube' draggable="false"></img>
                                          <span><img src="images/youtubeh.svg" alt="youtube" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>
                                    <div className='lit'>
                                       <a href=' https://www.linkedin.com/company/ultrapro-blockchain/' target='_blank'>
                                          <img src='images/link.svg' alt='linkedin' draggable="false"></img>
                                          <span><img src="images/linkedinh.svg" alt="LinkedIn" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href=' https://www.linkedin.com/company/ultrapro-blockchain/' target='_blank'>
                                          <img src='images/linkd.svg' alt='linkedin' draggable="false"></img>
                                          <span><img src="images/linkedinh.svg" alt="LinkedIn" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>

                                    <div className='lit'>
                                       <a href='https://t.me/Ultrapro_Exchange_Announcement' target='_blank'>
                                          <img src='images/tele.svg' alt='telegram' draggable="false"></img>
                                          <span><img src="images/telegramh.svg" alt="Telegram" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='https://t.me/Ultrapro_Exchange_Announcement' target='_blank'>
                                          <img src='images/teled.svg' alt='telegram' draggable="false"></img>
                                          <span><img src="images/telegramh.svg" alt="Telegram" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>

                                    <div className='lit'>
                                       <a href='https://www.instagram.com/ultraproupdates/' target='_blank'>
                                          <img src='https://instagram-images1.s3.amazonaws.com/Insta.svg' alt='instagram' draggable="false"></img>
                                          <span><img src="images/instah.svg" alt="instagram" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='https://www.instagram.com/ultraproupdates/' target='_blank'>
                                          <img src='https://instagram-images11.s3.amazonaws.com/Instad.svg' alt='instagram' draggable="false" ></img>
                                          <span><img src="images/instah.svg" alt="instagram" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                                 <div>
                                    <div className='lit'>
                                       <a href='mailto:techteamultrapro@gmail.com' target='_blank'>
                                          <img src='images/mail.svg' alt='mail' draggable="false"></img>
                                          <span><img src="images/mailh.svg" alt="mail" draggable="false"></img></span>
                                       </a>
                                    </div>
                                    <div className='drk'>
                                       <a href='mailto:techteamultrapro@gmail.com' target='_blank'>
                                          <img src='images/maild.svg' alt='mail' draggable="false"></img>
                                          <span><img src="images/mailh.svg" alt="mail" draggable="false"></img></span>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>





            {/* <div className='quick_guide ultraex'>
               <div className='container'>
                  <h2 className='hed'>Features of Canopro exchange</h2>
                  <div className='quick'>
                     <div className='quck_bx'>
                        <span> <img src='images/fea_a.svg' alt='KYC-Swift & Complaint'></img></span>
                        <h3>KYC-Swift & Complaint</h3>
                        <p>Experience seamless onboarding with KYC processes, ensuring full compliance with regulations</p>
                     </div>
                     <div className='quck_bx'>
                        <span> <img src='images/fea_b.svg' alt='24/7 Support'></img></span>
                        <h4>24/7 Support</h4>
                        <p>Like a trusted friend, our 24/7 expert support is always there, making your crypto investment journey smoother</p>
                     </div>
                     <div className='quck_bx'>
                        <span><img src='images/fea_c.svg' alt='Seamless API Trading'></img></span>
                        <h5>Seamless API Trading</h5>
                        <p>Amplify your crypto trading experience with Canopro through seamless API integration</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='security'>
               <div className='container'>
                  <h2 className='hed'>Your Security Matters</h2>
                  <div className='security_blk'>
                     <div className='sec_bc'>
                        <img src='images/signup.svg' alt='Sign Up'></img>
                        <h3>Safekeeping your digital assets</h3>
                        <p>We keep you digital assets safe with an extra layer of security.</p>
                     </div>
                     <div className='sec_bc'>
                        <img src='images/signup.svg' alt='Sign Up'></img>
                        <h3>2-Factor Authentication</h3>
                        <p>Double your Canopro account security with 2-factor authentication-choose the setup that suits you best.</p>
                     </div>
                     <div className='sec_bc'>
                        <img src='images/signup.svg' alt='Sign Up'></img>
                        <h3>End-to-End Encryption</h3>
                        <p>We use advanced encryption and follow the highest industry standards, ensuring worry-free crypto trading and investment for you.</p>
                     </div>
                     <div className='sec_bc'>
                        <img src='images/signup.svg' alt='Sign Up'></img>
                        <h6>Transparency- Your Right to know</h6>
                        <p>We don’t just talk, we practice it, explore our transparency reports, proof of reserves, and more, because informed users make better decisions.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='community'>
               <div className='container'>
                  <h2 className='hed'>Join Our Community</h2>
                  <p>Keep up with the Canopro team and our traders from across the world. we have everything you need to become a crypto expert</p>
               </div>
            </div> */}
            <div className='power_cryp'>

               <div className='d-flex  justify-content-center'>
                  <div className='pb-4'>
                     <h1 className='text-center text-light'>Connecting you to the crypto potential world</h1>
                     <h1 className='text-center text-light'>Initiate your crypto journey now!</h1>
                  </div>
               </div>
               <div className='d-flex justify-content-center'>

                  <a href='/trade'>
                     <button type='button' className='btn btn-light text-success px-5 rounded-1'>Start Trading</button>
                  </a>
               </div>
               {/* <div className=''>
                  <div className=''>
                     <h2 className=''>Connecting you to the power of crypto</h2>
                     <p>Start Your Crypto Journey Now</p>
                     <button type='button' className='btns'>Start Trading</button>
                  </div>
               </div> */}
            </div>


         </div>
         <Footer />
      </>
   )
}

export default Homepage