import React, { useState, useEffect } from 'react';
import Header from "../../header/Header";
import "./Verify.css";
import OtpInput from 'react-otp-input';
import { useParams, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { postData } from '../../../AxiosInstance/AxiosInstance';
import Loader from 'react-js-loader';
import { ReactErrorToast, ReactWarningToast, ReactSuccessToast } from '../../../ReactToast/ReactToast';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const Verifyotp = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");

  const location = useLocation();
  const formData = location.state;
  const [otp, setOtp] = useState('');
  const [getotp, setGetOtp] = useState("");

  const [errors, setErrors] = useState();
  const [countdown, setCountdown] = useState(localStorage.getItem('countdown') ? parseInt(localStorage.getItem('countdown'), 10) : 60);
  const { id } = useParams();



  // countdown.js
  const startCountdown = (setCountdown) => {
    if (localStorage.getItem('countdown')) {
      setCountdown(parseInt(localStorage.getItem('countdown'), 10));
    }

    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => {
        const newCountdown = prevCountdown - 1;
        localStorage.setItem('countdown', newCountdown);
        if (newCountdown <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem('countdown');
        }
        return newCountdown;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  };
  useCustomEffect(() => startCountdown(setCountdown), []);

  // const handleKeyDownForAccountNumber = (e) => {
  //   if (!(e.key === "Backspace" || /^[0-9]*$/.test(e.key))) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDownForAccountNumber = (e) => {
    const isControlKey = e.ctrlKey || e.metaKey; // For Mac support with Command key
    const isAllowedKey =
      e.key === "Backspace" ||
      e.key === "Delete" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "Tab" ||
      e.key === "Enter";
  
    if (!(isAllowedKey || (isControlKey && e.key === 'v') || /^[0-9]$/.test(e.key))) {
      e.preventDefault();
    }
  };
  

  // const handleOtpChange = (otpValue) => {
  //   if (!isNaN(otpValue)) {
  //     otpValue = otpValue.replace(/\D/g, "");

  //     setOtp(otpValue);
  //     setOtpError("");
  //   } else {
  //     setErrors("Numbers Only");
  //   }
  // };

  const handleOtpChange = (e) => {
    const otpValue = e.target.value;

    if (!isNaN(otpValue)) {
      const sanitizedOtp = otpValue.replace(/\D/g, "");
      setOtp(sanitizedOtp);

      if (sanitizedOtp.length === 6) { // Check if the length is exactly 6
        setGetOtp(sanitizedOtp);
        handleOTP(sanitizedOtp); // Pass sanitizedOtp directly to handleOTP
        setOtpError("");
      } else {
        setErrors("Please enter a 6-digit code");
      }
    } else {
      setErrors("Numbers Only");
    }
  };



  const [otpVerified, setOtpVerified] = useState(false);
  const handleOTP = async (otpValue) => {
    if (otpValue === "") {
      setOtpError("OTP is required");
    } else if (otpValue.length !== 6) {
      setOtpError("OTP length should be 6");
    } else {
      setLoading(true);
      const postFormData = {
        email: formData.data.email,
        otp: otpValue, // Use otpValue directly
      };
      const result = await postData(Apiservices.USER_VERIFY_OTP_REST_API, postFormData);


      setLoading(true);
      if (result.status) {
        toast.success(result.message);
        setGetOtp("");
        setOtp("")
        setLoading(false);
        setOtpVerified(true);
        navigate('/auth?tab=signin')
        // navigate("/log");
      } else {
        toast.error(result.message);
        setLoading(false);
        // setFormData({});
      }


    }
  };

  const handleResend = async () => {
    setLoading(true);

    const result = await postData(Apiservices.USER_RESEND_OTP_REST_API, { email: formData.data.email });

    setLoading(true);
    if (result.status) {
      ReactSuccessToast(result.message);

      setLoading(false);

      setCountdown(60);
      localStorage.setItem('countdown', 60);
      startCountdown(setCountdown)
      // navigate("/login");
    } else {
      ReactErrorToast(result.message);

      setLoading(false);
    }

  };







  return (
    <>
      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
      ) : (
        <>
          <Header />
          <div className="verify verifys col-md-6 col-lg-4">

            <div className="veri_blk">
              <div className="veri_otp">
                <p>
                  Enter the 6 digit code sent to
                  <strong>{formData.data.email}</strong>
                </p>
                <span>Don’t see it? Check your spam folder</span>
                <div className="inptbx mobl ">
                  <input
                    value={otp}
                    onChange={handleOtpChange}
                    onKeyDown={handleKeyDownForAccountNumber}
                    id="eye"
                    type="text"
                    placeholder="Email OTP"
                    name="otp"
                    autoComplete='off'
                    maxLength={6}
                  />
                  {countdown <= 0 ? (
                    <button
                      className='closeeye'
                      variant="secondary"
                      onClick={() => handleResend()}
                    >
                      Resend
                    </button>
                  ) : (
                    ""
                  )}

                </div>
                <p>
                  Haven’t received it yet? Resend in <span> {countdown <= 0 ? (<>0</>) : (<>{countdown}</>)} seconds</span>
                </p>
                <p>
                  If the email address entered is wrong, logout and signup again
                  with the correct email address
                </p>
              </div>


            </div>
          </div>
        </>

      )}

    </>
  )
}

export default Verifyotp