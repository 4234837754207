import logo from './logo.svg';
import { useState } from 'react';
import './Style.css';
import { Routes, Route } from "react-router-dom";
// import Home from "./Componant/pages/Homepage"
import Home from "./Componant/pages/home/Homepage"
import Sign from "./Componant/pages/sign/Sign";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import Verfication from './Componant/pages/verification/Verification';
import Wallet from './Componant/pages/wallet/Wallet';
import WalletDeposit from './Componant/pages/wallet/WalletDeposit';
import Verifyotp from './Componant/pages/verification/Verifyotp';
import WalletWithdraw from './Componant/pages/wallet/WalletWithdraw';
import AccountDetail from './Componant/pages/Bankdetail/AccountDetail';
import Forgot from './Componant/pages/forgot&reset/Forgot'
import Reset from './Componant/pages/forgot&reset/Reset';
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import VerifyEmail from './Componant/pages/verification/VerifyEmail';
import BuyCrypto from './Componant/pages/Buysel/BuyCrypto';
import Support from './Componant/pages/support/Support'
import Security from './Componant/pages/Bankdetail/Security';
import Referral from './Componant/pages/referral/Referral';
import Stake from './Componant/pages/stake/Stake';
import StakeHistory from './Componant/pages/stake/StakeHistory';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BuycryptoHistory from './Componant/pages/Buysel/BuycryptoHistory';
import TermsConditions from './Componant/pages/TermsConditions';
import { useCustomEffect } from './useEffectCommon/useCommonEffect';
import { postGetData } from './AxiosInstance/AxiosInstance';
import { Apiservices } from './ApiServices/ApiServices';
import Fornotfor from './Fornotfor';
import Privacypolicy from './Componant/pages/Privacypolicy';
import PremiumHistory from './Componant/pages/stake/premiumHistory';
import ManualDeposit from './Componant/pages/Buysel/ManualDeposit';
import Trade from './Componant/pages/Trade/Trade';
import Nft from './Componant/pages/nft/Nft';

function App() {
  const [site, setSite] = useState()
  // useEffect(() => {

  //   axiosInstance
  //     .post(`/siteStatus`)
  //     .then((response) => {
  //       if (response.data.status) {
  //         setSite(response.data.siteStatus)

  //       } else {
  //         console.error("User data not found in the response.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user data:", error);
  //     });
  // }, []);
  const siteStatus=async()=>{
    const response=await postGetData(Apiservices.USER_SITE_STATUS)
    if (response.status) {
      setSite(response.siteStatus)

    } else {
      console.error("User data not found in the response.");
    }

  }

  // useCustomEffect(siteStatus)

  const toastContainerStyle = {
    width: '100%', // Full width
    maxWidth: '250px', // Limit maximum width
    margin: '0 auto', // Center align
  };
  
  const toastStyle = {
    backgroundColor: '#fff', // White background
    color: '#333', // Dark text
    borderRadius: '8px', // Rounded corners
    padding: '0px 5px', // Reduced padding for smaller height
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    fontSize: '14px', // Reduced font size for compact appearance
    lineHeight: '1.2', // Adjusted line height for text
  };


  
  document.addEventListener('contextmenu', (e) => e.preventDefault());

  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, 'I') ||
      ctrlShiftKey(e, 'J') ||
      ctrlShiftKey(e, 'C') ||
      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    )
      return false;
  };
  
  return (
    <>
    {site == 2 ? (
          < Fornotfor />

    ):(

    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true} 
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={toastContainerStyle} 
        toastStyle={toastStyle}
        closeButton={false}
      />
      <Routes>

        <Route path="*" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/auth/:string?" element={<PublicRoute Component={Sign} />} />
        {/* <Route path="/auth" element={<PublicRoute Component={Sign} />} /> */}

        <Route path='/verification-otp' element={<PublicRoute Component={Verifyotp} />} />
        
        <Route path='/verification' element={<PrivateRoute Component={Verfication} />} />
        <Route path='/wallet' element={<PrivateRoute Component={Wallet} />} />
        {/* <Route path='/wallet-deposit' element={<PrivateRoute Component={WalletDeposit} />} /> */}
        <Route path='/wallet-withdrawal' element={<PrivateRoute Component={WalletWithdraw} />} />
        <Route path='/account-details' element={<PrivateRoute Component={AccountDetail} />} />

        <Route path="/buyCryptoPage" element={<PrivateRoute Component={BuyCrypto} />} />
        <Route path="/support" element={<PrivateRoute Component={Support} />} />
        <Route path="/security" element={<PrivateRoute Component={Security} />} />
        <Route path="/referral" element={<PrivateRoute Component={Referral} />} />
        <Route path="/BuyCryptoHistory" element={<PrivateRoute Component={BuycryptoHistory} />} />
        <Route path="/stakePremiumHistory" element={<PrivateRoute Component={PremiumHistory} />} />

        
        
        <Route path="/trade" element={<Trade />} />

        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/resetPassword/:string" element={<Reset />} />
        <Route path="/user/verify/:string" element={<VerifyEmail />} />
        <Route path="/stake" element={<Stake />} />
       
        <Route path="/stakeHistory" element={<StakeHistory />} />
        
        <Route path="/privacypolicy" element={<Privacypolicy/>} />
        <Route path="/termsconditions" element={<TermsConditions/>} />
        <Route path="/manual-deposit" element={<ManualDeposit/>} />
        <Route path="/nft-comingsoon" element={<Nft/>} />
      </Routes>
    </div>
    )}
    </>
  );
}

export default App;
