import React, { useState } from "react";
import Header from "../../header/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../sign/sign.css";
import { forgetEmailScheema } from "../../../Yup";
import { postData, getData, putData, deleteData, postGetData } from '../../../AxiosInstance/AxiosInstance';
import { ReactErrorToast, ReactWarningToast, ReactSuccessToast } from '../../../ReactToast/ReactToast';
import { Apiservices } from '../../../ApiServices/ApiServices';
import Loader from 'react-js-loader';
import { useNavigate } from "react-router-dom";
const Forgot = () => {
  const navigate = useNavigate()

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [forgetForm, setForgetForm] = useState({
    forgetEmail: "",

});

  const handleForgetChange = (e) => {
    const { name, value } = e.target;
    setForgetForm({ ...forgetForm, [name]: value });
    forgetEmailScheema
        .validateAt(name, { [name]: value })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((error) => {
            setErrors({ ...errors, [name]: error.message });
        });
};
 
  const handleForgetBlur = (e) => {
    const { name } = e.target;
    forgetEmailScheema
        .validateAt(name, { [name]: forgetForm[name] })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((error) => {
            setErrors({ ...errors, [name]: error.message });
        });
};
const handleForget = async (e) => {
  e.preventDefault();

  const emptyFields = {};
  const labelMapping = {
    
    forgetEmail: "Email",
    
    
  };

  Object.entries(forgetForm).forEach(([key, value]) => {
    if (
      (value === "") ||
      value === undefined ||
      value === null
    ) {
      const label = labelMapping[key] || key;
      emptyFields[key] = `${label} is Required`;
    }
  });

  if (Object.keys(emptyFields).length > 0) {
    setErrors(emptyFields);
    return Promise.resolve();
  }
  forgetEmailScheema
      .validate(forgetForm, { abortEarly: false })
      .then(async () => {
          setLoading(true)
          const postForm = {
              email: forgetForm.forgetEmail,
          };
          const result = await postData(Apiservices.USER_FORGOT_REST_API, postForm);

          if (result.status) {
              setLoading(false)
              setData(result.data);
              navigate('/auth?tab=signin')
              ReactSuccessToast(result.message);
              setForgetForm((prevFormData) => ({
                  ...prevFormData,
                  forgetEmail: "",
              }));

          } else {
              setForgetForm((prevFormData) => ({
                  ...prevFormData,
                  forgetEmail: "",
              }));
              setLoading(false)
              ReactErrorToast(result.message);
          }
          // console.log(result, "RESSSS");

          setErrors({});

      })
      .catch((error) => {
          const validationErrors = {};
          error.inner.forEach((err) => {
              validationErrors[err.path] = err.message;
          });
          setErrors(validationErrors);
      });



};
  return (
    <>
    {loading ? (
      <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
    ):(
      <>
      <Header />
      <div className="lgc">
            <div className="log_c frgt">

              <strong>Forgot Password</strong>
              <div className="inptbx  ">
                    
              <input
                                            autoComplete='off'
                                            type="text"
                                            placeholder="Email Address"
                                            name="forgetEmail"
                                            onBlur={handleForgetBlur}

                                            value={forgetForm.forgetEmail}
                                            onChange={handleForgetChange}



                                        />
                                        <p  id='error'>{errors.forgetEmail}</p>
                </div>
                
                <button type='submit'  onClick={handleForget} className='col-4  btns'>SUBMIT </button>
            </div>
          
        </div>
    </>
    )}
  
    </>
  );
};
export default Forgot;
