import React, { useState, useEffect } from 'react'
import Header from '../../header/Header'
import "./support.css"
import Accordion from 'react-bootstrap/Accordion';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage } from '../../../ErrorMessage/ErrorMessage';
import { ReactErrorToast, ReactWarningToast, ReactSuccessToast } from '../../../ReactToast/ReactToast';
import { postData, getData, putData, deleteData, postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Loader from 'react-js-loader';
import { toast } from 'react-toastify';

const Support = () => {

   const [profileImage, setProfileImage] = useState(null);
   const [selectedFile, setSelectedFile] = useState(null);

   const [imageError, setImageError] = useState({
      proffront: "",
    });

   const [showModal, setShowModal] = useState(false);
   const modClick = () => {
      setShowModal(true)
      // console.log("modal")
   }
   const modClose = () => {
      setShowModal(false)
   }

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => {
      setShow(true);
   };
   const [loading, setLoading] = useState(false);
   const [title, setTitle] = useState("");
   const [errors, setErrors] = useState({});
   const [message, setMessage] = useState("");
   const [supportData, setSupportData] = useState([]);

   const handleSubmit = async (e) => {
      console.log(supportData,"");
      e.preventDefault();

      const errors = {};
      if (!title.trim()) {
         errors.title = "Ticket Title is required";
      }

      if (!message.trim()) {
         errors.message = "Message is required";
      } else if (message.trim().length > 500) {
         errors.message =
            "Message must be less than or equal to 500 characters long";
      }

      if (Object.keys(errors).length > 0) {
         setErrors(errors);
         return;
      }
      setLoading(true);
      const sendTheSupportTicketDetails = { title, message };

      const formDataToSend = new FormData();
    
      formDataToSend.append("message",message);
      formDataToSend.append("title", title);
      formDataToSend.append("image", selectedFile);


      const response = await postData(Apiservices.USER_CREATE_TICKET, formDataToSend);
      // console.log('response---', response);
      if (response.status === true) {
         setLoading(false);
         ReactSuccessToast(response.message);

         handleClose();
         specifiedUserTicketList();

         setTitle("");
         setMessage("");
         setErrors({});
      }

   };

   const onChangeTitleHandle = (value) => {
      // console.log("value----", value);
      setTitle(value);
      setErrors((prevErrors) => ({
         ...prevErrors,
         title: value.trim() ? "" : "Ticket Title is required",
      }));
   };

   const onChangeMessageHandle = (e) => {
      const { value } = e.target;
      // console.log('value----', value);
      setMessage(value);

      if (value.trim().length === 0) {
         setErrors((prevErrors) => ({
            ...prevErrors,
            message: "Message is required",
         }));
      } else if (value.trim().length > 500) {
         setErrors((prevErrors) => ({
            ...prevErrors,
            message: "Message must be less than or equal to 500 characters long",
         }));
      } else {
         setErrors((prevErrors) => ({
            ...prevErrors,
            message: "",
         }));
      }
   };

   const specifiedUserTicketList = async () => {
      try {
         setLoading(true);

         const response = await postGetData(Apiservices.USER_TICKET_API);
         // console.log('response---', response.data);
         if (response.status === true) {
            setSupportData(response.data);
         }
         setLoading(false);

      } catch (error) {
         setLoading(false);
      }
   };

   const [showTicketTitle, setShowTicketTitle] = useState([]);

   const showAllTicketTitleList = async () => {
      try {
         const response = await postGetData(Apiservices.USER_SHOW_USER_ALL_TICKET);
         // console.log('response.data---', response.data[0]);

         if (response.status === true) {
            setShowTicketTitle(response.data);
         }

      } catch (error) { }
   };
   const [mains, setMains] = useState(false);
   const hso = () => {
      setMains(!mains);
   };
   useEffect(() => {
      // const intervalId = setInterval(() => {
      specifiedUserTicketList();
      showAllTicketTitleList();
      // }, 2000);
   }, []);

   const handleFileChange = (event, setSelectedFile, setProfileImage, imageErrorStateToUpdate) => {
      const selectedFile = event.target.files[0]; // Get the selected file
  
      if (selectedFile) {
        // Set the selected file to the state
  
        if (selectedFile.size > 4000 && selectedFile.size < 200000) {
  
          setImageError(prevFormData => ({
            ...prevFormData,
            [imageErrorStateToUpdate]: ""
          }));
  
          setSelectedFile(selectedFile);
  
          // Create URL for the file and set it to profileImage state
          setProfileImage(URL.createObjectURL(selectedFile));
  
  
          const allowedTypes = ['image/jpeg', 'image/png'];
  
          if (!allowedTypes.includes(selectedFile.type)) {
            // Reset the input field
            setProfileImage();
            // event.target.form.reset();
            setImageError(prevFormData => ({
               ...prevFormData,
               [imageErrorStateToUpdate]: "Upload valid image format"
             }));
          }
        }
        else {
          // toast.error("We Accept Only Image");
          setImageError(prevFormData => ({
            ...prevFormData,
            [imageErrorStateToUpdate]: "Image Size 40 - 200 kb"
          }));
        }
  
  
      }
      else {
        console.error("No file selected");
        setImageError((prevData) => ({
          ...prevData,
          proffront: "Image Required"
        }));
      }
    };
    const utcToNormalTime = (utcString) => {
      const date = new Date(utcString);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
      
      let hour = date.getHours();
      const ampm = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12 || 12; // Convert hour to 12-hour format
      const formattedTime = `${hour.toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")} ${ampm}`;
    
      return { date: formattedDate, time: formattedTime };
    };
    

   return (
      <>
         {loading ? (
            <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />

         ) : (
            <>


               <Modal
                  show={show}
                  onHide={() => {
                     setTitle("");
                     setShow(false)
                  }}

                  className="sec_mod supomod"
                  backdrop="static"
                  centered="bool"
               >

                  <Modal.Header closeButton onClick={handleClose}>
                     <Modal.Title>Add Ticket</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     {/* <div className='in_box'>
                           <span>Ticket</span>
                           <div className="inptbx  ">
                              <input
                                 type="text"
                                 placeholder="Enter Ticket"
                                 name="email" />
                           </div>
                        </div> */}
                     <div className='sel_drp'>
                        <span>Title</span>
                        <DropdownButton
                           variant="outline-secondary"
                           title={title || "Select Ticket Title"}
                           name="title"
                           className={`form-control ${errors.title && "is-invalid"
                              } dropdownButton`}
                           id="title"
                        >
                           {showTicketTitle.length === 0 ? (
                              <Dropdown.Item>No record Found</Dropdown.Item>
                           ) : (
                              showTicketTitle.map((show, index) => {
                                 // console.log('show----', show);
                                 return (
                                    <Dropdown.Item
                                       key={index}
                                       value={show.ticketTitle}
                                       onClick={() =>
                                          onChangeTitleHandle(show.ticketTitle)
                                       }
                                    >
                                       {show.ticketTitle}
                                    </Dropdown.Item>
                                 )
                              })
                           )}
                        </DropdownButton>
                        {errors.title && (
                           <div className="invalid-feedback">{errors.title}</div>
                        )}
                     </div>

                     <div className='in_box'>
                        <span>Message</span>
                        <div className="inptbx  ">

                           <textarea placeholder="Message"
                              className={`form-control msg ${errors.message && "is-invalid"
                                 }`}
                              id="message"
                              value={message}
                              name='message'
                              onChange={onChangeMessageHandle}
                           > </textarea>


                        </div>

                     <div className="supup upl">
                        <label htmlFor="">
                        <input name='proofFront' onChange={(event) => handleFileChange(event, setSelectedFile, setProfileImage, "proffront")} type='file'></input>
                          {profileImage ? <p><img src={profileImage} width={150} draggable='false'></img></p> : <p><img src="images/upl.svg" draggable='false'></img><span>Upload any Proof Images (Optional)</span></p>}
                        </label>

                        <p id='error'>{imageError.proffront}</p>
                     </div>
                     </div>
                     {errors.message && (
                        <div className="invalid-feedback">{errors.message}</div>
                     )}




                  </Modal.Body>
                  <Modal.Footer>



                     <Button
                        variant="btn btn-success"
                        onClick={handleSubmit}
                        className="btns"
                     >
                        Submit
                     </Button>



                  </Modal.Footer>

               </Modal>
               <Header />
               <div className='support'>
                  <div className='container'>
                     <div className='sup_cont'>
                        <div className='support_lft'>
                           <strong>Welcome to support</strong>
                           <p>At Canopro exchange, our commitment to providing exceptional customer support is unwavering. Our dedicated team is available 24/7 to assist you with any inquiries or issues you may encounter. Whether you need help with account setup, trading, or security concerns, we're here to ensure your experience is smooth and hassle-free. Reach out to us via live chat, email, or phone, and let us help you make the most of your trading journey. Your satisfaction is our priority.</p>
                           <a href='#' className='btns' onClick={() => {
                              handleShow();
                           }}>Add Ticket</a>
                        </div>
                        <div className='support_rgt'>
                           <img src='images/cup_sup.png' alt='support_img' draggable="false"></img>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='support_page'>
                  <div className='container'>
                     <>
                        {supportData.length === 0 ? (
                           <p>Record not found</p>
                        ) : (
                           supportData.map((section, index) => {

                              console.log(section);

                              let statusOfSupport;
                              if (section.status === 0) {
                                 statusOfSupport = "Pending";
                              } else if (section.status === 1) {
                                 statusOfSupport = "Resolved";
                              } else if (section.status === 2) {
                                 statusOfSupport = "Rejected";
                              }

                              let textColorClass = "";
                              switch (statusOfSupport) {
                                 case "Pending":
                                    textColorClass = "yel";
                                    break;
                                 case "Verified":
                                    textColorClass = "gr";
                                    break;
                                 case "Rejected":
                                    textColorClass = "clos";
                                    break;
                                 default:
                                    textColorClass = "none";
                              }

                              const date = new Date(section.createdAt);

                              const day = date.getUTCDate();
                              const month = date.toLocaleString('default', { month: 'short' });
                              const year = date.getUTCFullYear();

                              const hours = date.getUTCHours();
                              const minutes = date.getUTCMinutes();
                              const seconds = date.getUTCSeconds();

                              // Format the time to be two digits
                              const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                              // console.log(`${day} ${month} ${year} ${formattedTime}`);
                              const adminDate = new Date(section.updatedAt);

                              const adminday = adminDate.getUTCDate();
                              const adminmonth = adminDate.toLocaleString('default', { month: 'short' });
                              const adminyear = adminDate.getUTCFullYear();

                              const adminhours = adminDate.getUTCHours();
                              const adminminutes = adminDate.getUTCMinutes();
                              const adminseconds = adminDate.getUTCSeconds();

                              // Format the time to be two digits
                              const adminReplyTime = `${adminhours.toString().padStart(2, '0')}:${adminminutes.toString().padStart(2, '0')}:${adminseconds.toString().padStart(2, '0')}`;


                              return (
                                 <>
                                    <Accordion defaultActiveKey="0">
                                       <Accordion.Item eventKey="0">


                                          <Accordion.Header>
                                             <ul>
                                                <li><img src="images/msg.svg" alt="message" draggable="false"></img></li>
                                                <li><strong>Ticket ID</strong><span>{section.ticket_id}</span></li>
                                                <li><strong>Title</strong><span>{section.title}</span></li>
                                                <li><strong>Status</strong><span className={textColorClass}>{statusOfSupport}</span></li>
                                                <li><strong>Updated Time</strong><span>{`${day} ${month} ${year} ${utcToNormalTime(section.createdAt).time}`}</span></li>
                                             </ul>

                                          </Accordion.Header>
                                          <Accordion.Body>
                                             <ul>
                                                <li>
                                                   <img src="images/dpim.svg" alt='dp' draggable="false"></img></li>
                                                <li>
                                                   <p className='pro_c'>
                                                      <strong>{section.name} <span>User</span></strong>

                                                      <span> {formattedTime}</span>
                                                   </p>
                                                   <p>{section.message}  </p>
                                                </li>
                                             </ul>
                                             {section.replay && (
                                                <>
                                                   <ul>
                                                      <li><img src="images/dpim.svg" alt='dp' draggable="false"></img></li>
                                                      <li><p className='pro_c'><strong>Admin </strong><span>{adminReplyTime}</span></p>
                                                         <p>{section.replay} </p>
                                                      </li>
                                                   </ul>
                                                </>
                                             )}
                                          </Accordion.Body>



                                       </Accordion.Item>

                                    </Accordion>
                                 </>
                              );
                           })
                        )}
                     </>
                  </div>
               </div>
            </>
         )}
      </>
   )
}

export default Support