export const ErrorMessage = {
    SIGNUP_ERROR_MESSAGE: 'Name is Required',
    SIGNUP_NO_ERROR_MESSAGE: '',
    SIGNUP_INVALID_EMAIL_ERROR_MESSAGE: "Invalid email address",
    SIGNUP_EMAIL_REQUIRED_ERROR_MESSAGE: 'Email is required',
    SIGNUP_PASSWORD_8_CHARATERS_ERROR_MESSAGE :'Password must be at least 8 characters long and contain at least one uppercase letter and one number',
    SIGNUP_PASSWORD_REQUIRED_ERROR_MESSAGE: "Password is required",
    SIGNUP_CONFIRM_PASSWORD_REQUIRED_ERROR_MESSAGE: "Confirm password is required",
    SIGNUP_PASSWORD_DOESNOT_MATCH_ERROR_MESSAGE : 'Passwords do not match',
    SIGNUP_NAME_REQUIRED_ERROR_MESSAGE: "Name is required",
    SIGNUP_CHECKBOX_ERROR_MESSAGE:"Please Click Check Box"
}