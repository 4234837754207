export const Apiservices = {
    // BACKEND_LOCALHOST_SERVER: 'http://192.168.0.4:3002',
    BACKEND_LOCALHOST_SERVER: 'https://backend.canopro.com',
    // BACKEND_LOCALHOST_SERVER: 'https://staging.backend.ultrapro.exchange',

    BACKEND_ADMIN_SERVER: 'admin',
    BACKEND_USER_SERVER: 'user',
    USER_SIGNUP_REST_API: '/signup',
    USER_SIGNIN_REST_API: '/loginByEmail',
    USER_SIGNUP_LIST_REST_API: '/allUserLiSt',
    USER_FORGOT_REST_API: '/forgotPassword',
    USER_VERIFY_TOKEN_REST_API: '/verifyToken',
    USER_VERIFY_OTP_REST_API: '/verifyByOtp',
    USER_RESEND_OTP_REST_API: '/resendOtp',
    USER_PROFILE_REST_API: '/profile',
    USER_MOBILE_VERIFY_REST_API: '/updatePhoneNumber',
    USER_KYC_REST_API: '/userKYC',
    USER_RESET_REST_API: '/reset-password',
    USER_GETUSER_REST_API: '/getuser',
    USER_TFA_REST_API: '/verifyTFA',
    USER_VERIFY_LOGIN_REST_API: '/verifyLogin',
    USER_BANK_OTP_REST_API: '/userBankOtp',
    USER_BANK_REST_API: '/bankAccount',
    USER_GET_BANK_REST_API: '/getBankAccount',
    USER_DELETE_BANK_REST_API: '/deleteBankDetailById',
    USER_VERIFY_REST_API: '/verify',
    USER_ADMIN_REST_API: '/getAdminBankdetails',
    USER_UPRO_BALANCE_API: '/getUproByName/UPRO',
    USER_BUYCRYPTO_API: '/buyCrypto',
    USER_BALENCE_API: '/getBalance',
    USER_TICKET_API: '/getPaticularUserTicketList',
    USER_SHOW_USER_ALL_TICKET: '/showAllTheTicketTitleList',
    USER_CREATE_TICKET: '/createTicket',
    USER_WITHDRAW_AVAILABLE_API: '/depositeBalance',
    USER_WITHDRAW_API: '/withdraw',
    USER_WITHDRAW_HISTORY_API: '/withdrawHistory',
    USER_CHANGE_PASSWORD_API: '/changePassword',
    USER_CHANGE_PASSWORD_OTP_API: '/updatePassword',
    USER_WITHDRAW_DYNOMIC: '/getAllCurrency',
    USER_STAKE_API: '/getAllStakes',
    USER_WITHDRAW_DYNOMIC: '/getAllCurrency',
    USER_STAKE_BALANCE: '/depositeBalance',
    USER_UPRO_BALANCE: '/getUproByName/UPRO',
    USER_NAME_REFERRAL: '/getNameByReferral',
    USER_GROWTH_HISTORY: '/checkGrowth',
    USER_GETSTAKE_HISTORY: '/getStake',
    USER_REDEEM_STAKE: '/redeemStake',
    USER_PREMIUM_STAKE: '/twentyFourMonthsFixerStaking',
    USER_ENABLE_TFA: '/verifyTFA',
    USER_BUYCRYPTO_HISTORY: '/getUserCryptoDetails',
    USER_SKIP_API: '/skipTfaKyc',
    USER_EARN_BANALNCE_API: '/earnBalance',
    USER_REFERRAL_LEVEL_API: '/getReferralLevel',
    USER_STAKE_DETAILS_API: '/stake',
    USER_SITE_STATUS: '/siteStatus',
    USER_PREMIUM_HISTORY: '/twentyFourMonthsFixerStakingHistory',
    USER_AUTOMATIC_DEPOSITE: '/cryptoDeposite',
    USER_DEPOSITE_VERIFY: '/depositeVerify',


    // Trade
    USER_TRADE_ORDER_LIST: '/trade/tradePairdetails',
    USER_TRADE_SELECT_LIST: '/trade/pairdetails',
    USER_TRADE_CURRECY_LIST: '/userTradeBalance',
    USER_TRADE_SEND_LIST: '/trade/createLimitOrder',
    USER_OPEN_ORDER_HIS_LIST: '/trade/openOrderHistory',
    USER_TRADE_ORDER_HIS_LIST: '/trade/tradeOrderHistory',
    USER_ORDER_HIS_LIST: '/trade/OrderHistory',
    USER_ORDER_BOOK_LIST: '/trade/OrderBook',

    USER_OPEN_ORDER_CANCEL_LIST: '/trade/cancelLimitOrder',
    USER_TRADE_ASSEST_LIST: '/trade/tradeAssest',
    CHART_TIME: '15 mins',
    PAIR_NAME: "UPRO/INR"








}