
import React, { useState, useEffect } from 'react';
import Header from '../../header/Header'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./sign.css"
import { signupSchema, signinScheema, forgetEmailScheema } from '../../../Yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage } from '../../../ErrorMessage/ErrorMessage';
import { ReactErrorToast, ReactWarningToast, ReactSuccessToast } from '../../../ReactToast/ReactToast';
import { postData, getData, putData, deleteData, postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import Yup from '../../../Yup';
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import Cookies from "js-cookie";

import Loader from 'react-js-loader';
const Sign = () => {
  
  let { string } = useParams();


  

  const navigate = useNavigate()
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [referalShow, setReferalShow] = useState(true);
  const [checkError, setCheckError] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [shworeffral, setshworeffral] = useState(referralCode === "");
  const [showPassword, setShowPassword] = useState(false);
  const [SignupShowPassword, setSignupShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [forget, setForget] = useState(false);



  const [getotp, setGetOtp] = useState("");

  const [showNext, setNext] = useState(false);

  const [otpError, setOtpError] = useState("");
  // const queryParams = new URLSearchParams(location.search);
  // const initialTab = queryParams.get('tab') || 'signin';
  // const referralID = queryParams.get('referralID') || '';


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",

  });
  const [signInForm, setSinginForm] = useState({
    signinEmail: "",
    signinpassword: "",
  });
  const [forgetForm, setForgetForm] = useState({
    forgetEmail: "",

  });

  const [activeTab, setActiveTab] = useState('signup');
  const location = useLocation();

  const getString = async() => {
    const referalParams = string;
    setReferralCode(referalParams)

  
  }
  useEffect(() => {
    getString()

    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam === 'signin') {
      setActiveTab('signin');
    } else {
      setActiveTab('signup');
    }
  }, [location.search]);


  const handleOnlyLetters = (e) => {
    if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlesignupPassword = () => {
    setSignupShowPassword((prevState) => !prevState);
  };

  const handleConfirmTogglePassword = () => {
    setShowConPassword((prevState) => !prevState);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;
    let spaceError = "";

    if (name === "name") {
      sanitizedValue = value.trimStart();
    }


    if (name !== "name") {
      sanitizedValue = sanitizedValue.replace(/\s/g, "");
    }
    if (name === "referralCode") {
      sanitizedValue = value.replace(/\s/g, "").toUpperCase();
      setReferralCode(sanitizedValue);
    }

    setFormData({ ...formData, [name]: sanitizedValue });
    setErrors({ ...errors, [`${name}SpaceError`]: spaceError }); // Set space error separately for each field

    signupSchema
      .validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emptyFields = {};
    const labelMapping = {
      name: "Name",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
    };

    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });


    setErrors({ ...errors, ...emptyFields })


    // If there are empty fields, stop further validation
    if (Object.keys(emptyFields).length > 0) {
      return;
    }


    try {
      // Proceed with schema validation
      await signupSchema.validate(formData, { abortEarly: false });

      // Check for password mismatch
      if (formData.password !== formData.confirmPassword) {
        setErrors({
          confirmPassword: "Passwords do not match",
        });
        return;
      }

      // Clear errors and proceed with further actions
      setErrors({});
      setReferalShow(false);
      // handlePost(); // Uncomment this if you have a function to handle the form submission
    } catch (error) {
      // Handle validation errors
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };






  const handleCheckboxClick = () => {
    if (!isChecked) {
      setIsChecked(true); // Set isChecked to true to hide the error
      setCheckError("")
    }
    else {
      setCheckError(ErrorMessage.SIGNUP_CHECKBOX_ERROR_MESSAGE)
    }

  };

  const handleCreate = () => {
    if (!isChecked) {
      setCheckError(ErrorMessage.SIGNUP_CHECKBOX_ERROR_MESSAGE)
    } else {
      handlePost()
      setLoading(true)
    }


  }

  const handlePost = async () => {
    let postForm = { ...formData };

    if (referralCode !== undefined && referralCode !== null) {
      postForm.referralCode = referralCode;
    } else {
      postForm.referralCode = ""; // Assign an empty string if referralCode is undefined or null
    }
    const result = await postData(Apiservices.USER_SIGNUP_REST_API, postForm);

    if (result.status) {
      setLoading(false)
      setData(result.data);
      ReactSuccessToast(result.message);

      navigate('/verification-otp', { state: { data: formData } });



    } else {
      setReferalShow(true);
      setIsChecked(false)
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
      }));
      setLoading(false)
      ReactErrorToast(result.message);
    }
    // console.log(result, "RESSSS");

  }

  const handleBlur = (e) => {
    const { name } = e.target;
    signupSchema
      .validateAt(name, { [name]: formData[name] })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handleSignInBlur = (e) => {
    const { name } = e.target;
    signinScheema
      .validateAt(name, { [name]: signInForm[name] })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };




  const handleSigninChange = (e) => {
    const { name, value } = e.target;
    setSinginForm({ ...signInForm, [name]: value });
    signinScheema
      .validateAt(name, { [name]: value })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };



  const handleSigninSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = {};
    const labelMapping = {

      signinEmail: "Email",
      signinpassword: "Password",

    };

    Object.entries(signInForm).forEach(([key, value]) => {
      if (
        (value === "") ||
        value === undefined ||
        value === null
      ) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })

      return Promise.resolve();
    }


    signinScheema
      .validate(signInForm, { abortEarly: false })
      .then(() => {
        setErrors({});
        setLoading(true)
        return handleSigninPost();
      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });



  };


  const handleSigninPost = async () => {
    // console.log(signInForm, "Sign");
    const postForm = {
      email: signInForm.signinEmail,
      password: signInForm.signinpassword,
    };

    const result = await postData(Apiservices.USER_SIGNIN_REST_API, postForm);

    if (result.status) {

      // console.log("Result Token", result.token);


      const token = result.token; // assuming token is returned in result
      // expires in 1 day


      setLoading(false)


      // console.log(result.user);


      if (result.tfStatus) {
        setNext(true);
      }
      else {
        Cookies.set("jwtToken", token, { expires: 1 });
        navigate('/verification')
      }




      // navigate('/buyCryptoPage')


      setData(result.data);
      ReactSuccessToast(result.message);
      setSinginForm((prevFormData) => ({
        ...prevFormData,
        signinpassword: "",
      }));

      // const gettoken = Cookies.get("token");  ============ This is used to get the Token ==============

      // console.log("Get Token ----- ", gettoken);

      // Cookies.remove("token");   ================ This will remove Token in the Cookies ================



    } else {
      setSinginForm((prevFormData) => ({
        ...prevFormData,
        signinpassword: "",
      }));
      setLoading(false)
      ReactErrorToast(result.message);
    }
    // console.log(result, "RESSSS");

  }
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleOtpChange = (e) => {

    const { name, value } = e.target;


    var otpValue = '';

    if (!isNaN(value)) {
      otpValue = value.replace(/\D/g, "");
      setGetOtp(otpValue);
      setOtpError("");
    }
    else if (otpValue.length < 6) {
      setOtpError("Invalid OTP Lenght");
    }
    else {
      setOtpError("Numbers Only");
    }
  };

  const handleTFAOTPSubmit = async () => {

    // console.log(getotp);


    if (getotp == "" || getotp == undefined || getotp == null) {

      // setErrors({ ...errors, tfa: "2FA is Required" });
      setOtpError("2FA Required")

    }
    else if (getotp.length == 6) {

      // setErrors({ ...errors, tfa: "" });
      setOtpError("")


      // { tfaCode: otp, email, mobileNumber}

      // console.log("-------- > ",signInForm.signinEmail )
      const emailLower = signInForm.signinEmail.toLowerCase()
      const data = {
        tfaCode: getotp,
        email: emailLower,
        mobileNumber: ""
      }

      setLoading(true);

      const response = await postData(Apiservices.USER_VERIFY_LOGIN_REST_API, data);

      const token = response.token;


      // console.log("Response  ----- > ", response);

      if (response.status) {
        toast.success(response.message)

        setOtpError("");

        Cookies.set("jwtToken", token, { expires: 1 });

        setLoading(false);
        setGetOtp('');
        navigate('/verification');


      }
      else {
        setGetOtp('');
        setLoading(false);
        toast.error(response.message)
        setOtpError("Invalid TFA");
      }


    }
    else {
      // console.log(getotp);
      setErrors({ ...errors, tfa: "2FA Should be 6 Digit" });
    }
  }
  const handleSubmitEnter = (e) => {
    if (e.key === "Enter") handleSigninSubmit(e);
  };

  return (
    <>

      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
      ) : (

        <>
          <Header />


          <Modal
            show={showNext}

            className="sec_mod"
            backdrop="static"
            centered="bool"

          >
            <Modal.Header closeButton onClick={() => setNext(!showNext)}>
              <Modal.Title>2FA Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa to_fas">
                <strong>Two-Factor Authentication</strong>
                <p>Enter 6 Digit Authentication Code</p>
                <div className="inptbx  ">

                  <input
                    autoComplete='off'
                    type="text"
                    name="tfa"
                    value={getotp}
                    onChange={handleOtpChange}
                    maxLength={6}
                  />

                  {/* <h6 id="error">{errors.tfa}</h6> */}
                  <p id="error">{otpError}</p>
                </div>

                <button type="button" className="btns" onClick={handleTFAOTPSubmit}>Submit</button>
                {/* <p>Didn’t receive OTP yet? <br></br>
              Resend in <span>55 Seconds</span></p> */}
              </div>
            </Modal.Body>

          </Modal>




          <div className="lgc">
            {referalShow ? (
              <div className="log_c ">
                <div className='im_lg'>  <img src='https://images-ultra.s3.amazonaws.com/hlogod.svg' alt='hlogo'></img> <span><img src='https://images-ultra.s3.amazonaws.com/hlogo.svg' alt='hlogo'></img></span></div>
                <Tabs activeKey={activeTab}
                  onSelect={handleTabChange}
                  id="noanim-tab-example"
                  className="mb-3 emil_c col-9"
                >
                  <Tab eventKey="signin" title="Sign In" className="sig">
                    <div className="email_c">
                      <strong>Sign In to Canopro</strong>
                      <div className="in_textb ">
                        <div className="inptbx  ">

                          <input
                            autoComplete='off'
                            type="text"
                            placeholder="Email Address"
                            name="signinEmail"
                            onBlur={handleSignInBlur}

                            value={signInForm.signinEmail}
                            onChange={handleSigninChange}



                          />
                          <p id='error'>{errors.signinEmail}</p>
                        </div>

                      </div>
                      <div className="in_textb ">
                        <div className="inptbx  ">

                          <input
                            id="eye"
                            autoComplete='off'
                            type={showPassword ? "text" : "password"}
                            onBlur={handleSignInBlur}
                            placeholder="Password"
                            name="signinpassword"
                            value={signInForm.signinpassword}
                            onChange={handleSigninChange}
                            onKeyPress={handleSubmitEnter}

                          // onFocus={() => handleFocusChange("password")}


                          />
                          <p id='error'>{errors.signinpassword}</p>

                          <span className='closeeye' onClick={handleTogglePassword}>
                            {showPassword ? (
                              <img src='https://images-ultra.s3.amazonaws.com/view_709612_1.png' alt="eye" draggable="false" />
                            ) : (
                              <img src='https://images-ultra.s3.amazonaws.com/Vector.png' alt='eye' draggable="false"></img>
                            )}
                          </span>
                        </div>

                      </div>
                      <a onClick={() => (navigate('/forgot-password'))}>Forgot Password?</a>
                      <p>Don't Have an account ?
                        <a onClick={() => navigate('/auth?tab=signup')}>Sign Up</a>
                      </p>
                      <button type='submit' className='col-4  btns' onClick={handleSigninSubmit}>SIGN IN</button>
                    </div>
                  </Tab>

                  <Tab eventKey="signup" title="Sign Up" className="sig">
                    <div className="email_c">
                      <strong>Sign Up to Canopro</strong>
                      <div className="in_textb ">
                        <div className="inptbx  ">
                          <input
                            autoComplete='off'
                            maxLength={50}

                            placeholder="Your Name"
                            type="text"
                            name='name'
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={handleOnlyLetters}
                          />
                          <span id='error'>{errors.name}</span>

                        </div>

                      </div>
                      <div className="in_textb ">
                        <div className="inptbx  ">

                          <input
                            autoComplete='off'

                            placeholder="Email Address"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}

                          />
                          <span id='error'>{errors.email}</span>
                          <span className='closeeye tik'>{errors.email === '' ? (<img src='https://images-ultra.s3.amazonaws.com/CHECK.svg' alt='eye' draggable="false"></img>) : ('')}</span>
                        </div>

                      </div>
                      <div className="in_textb ">
                        <div className="inptbx ">

                          <input
                            autoComplete='off'
                            type={SignupShowPassword ? "text" : "password"}
                            id="eye"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            onBlur={handleBlur}

                          />
                          <span id='error'>{errors.password}</span>
                          <span className='closeeye' onClick={handlesignupPassword}>
                            {SignupShowPassword ? (
                              <img src='https://images-ultra.s3.amazonaws.com/view_709612_1.png' alt="eye" draggable="false" />
                            ) : (
                              <img src='https://images-ultra.s3.amazonaws.com/Vector.png' alt='eye' draggable="false"></img>
                            )}
                          </span>

                        </div>

                      </div>
                      <div className="in_textb ">
                        <div className="inptbx ">

                          <input
                            autoComplete='off'
                            id="eye"
                            type={showConPassword ? "text" : "password"}

                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span id='error'>{errors.confirmPassword}</span>
                          <span onClick={handleConfirmTogglePassword} className='closeeye'>
                            {showConPassword ? (
                              <img src='https://images-ultra.s3.amazonaws.com/view_709612_1.png' alt="eye" draggable="false" />
                            ) : (
                              <img src='https://images-ultra.s3.amazonaws.com/Vector.png' alt='eye' draggable="false"></img>
                            )}
                          </span>

                        </div>

                      </div>
                      <p className='log-textabout'>By creating an account, I agree to Canopro's <Link to="/termsconditions">Terms of Service</Link> and <Link to="/privacypolicy">Privacy Policy</Link>.</p>
                      <p>Have an account ?
                        <a onClick={() => navigate('/auth?tab=signin')}>Sign In</a>
                      </p>
                      <button type='submit' className='col-4  btns' onClick={handleSubmit}>SIGN UP</button>

                    </div>
                  </Tab>
                </Tabs>
              </div>
            ) : (
              <div className="log_c lgcd">
                <div className="refer_bx">
                  <strong>Create Account</strong>
                  <div className="inptbx  ">
                    <p>{formData.email}</p>
                    <input type="text"
                      autoComplete='off'
                      maxLength={10}
                      placeholder="Referral Code(optional)"
                      value={referralCode}
                      onChange={handleChange}
                      name="referralCode" />
                  </div>
                  <div className="chek">
                    <label>
                      <input type="checkbox" className="chkin"
                        checked={isChecked}
                        onClick={handleCheckboxClick}
                        onChange={(e) => setIsChecked(e.target.checked)}
                      ></input>

                      <p>I agree to Canopro’s <Link to="/termsconditions">Terms of service</Link></p>
                    </label>
                    <p id='error'>{checkError}</p>
                  </div>
                  <button type='submit' className='col-4  btns' onClick={handleCreate}>CREATE </button>
                </div>
              </div>


            )}



          </div>
        </>
      )}
    </>
  );
};
export default Sign;
