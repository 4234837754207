import { useState } from "react";
import * as Yup from "yup";

import Header from '../../header/Header'
import Dropdown from 'react-bootstrap/Dropdown';
import CurrencyFormat from "react-currency-format";
import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";
import { Apiservices } from "../../../ApiServices/ApiServices";
import WAValidator from 'multicoin-address-validator'
import { toast } from "react-toastify";
import { postGetData, postData } from "../../../AxiosInstance/AxiosInstance";
import { useNavigate } from 'react-router-dom';
import Loader from 'react-js-loader';


const WalletDeposit = () => {

    const navigate = useNavigate();


    const [errors, setErrors] = useState({});
    const [copy, setCopy] = useState("");
    const [loading, setLoading] = useState(false);
    const [withHis, setWithHis] = useState([]);
    const [dynomicCrypto, setDynomicCrypto] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    const [base64Image, setBase] = useState("");
    const [formData, setFormData] = useState({
        cryptoName: "",
        cryptoAmount: "",
        cryptoAddress: "",
        UPRO: 0
    });
    // console.log(selectedCurrency, "^^^^^^^^^^^");

    const withdrawalSchema = Yup.object().shape({
        cryptoAmount: Yup.string().required(`Amount is Required`)
            .test(
                'min-value',
                `${selectedCurrency?.cur_name} must be at least ${selectedCurrency?.w_min || 0}`,
                value => {
                    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
                    return !isNaN(numericValue) && numericValue >= (selectedCurrency?.w_min || 0);
                }
            )
            .test(
                'max-value',
                `${selectedCurrency?.cur_name} must be at most ${selectedCurrency?.w_max || 1000}`,
                value => {
                    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
                    return !isNaN(numericValue) && numericValue <= (selectedCurrency?.w_max || 1000);
                }
            ),
        cryptoName: Yup.string().required(`${selectedCurrency?.cur_name} is Required`),
        cryptoAddress: Yup.string().required(`Address is Required`),
        UPRO: Yup.string()
    });

    const getDynomic = async () => {

        const response = await postGetData(Apiservices.USER_WITHDRAW_DYNOMIC);
        if (response.status) {
            const filteredValue = response.currencies.filter(currency => currency.cur_type === "crypto");
            setDynomicCrypto(filteredValue)


        }

    }
    // console.log(dynomicCrypto, "#########");
    const getAvailableBalance = async () => {

        const response = await postGetData(Apiservices.USER_WITHDRAW_AVAILABLE_API);
        if (response.status) {
            const depositeDetails = response.balanceDetails;
            const updatedFormData = {};

            // Iterate through depositeDetails and update formData
            for (const currency in depositeDetails) {
                if (Object.hasOwnProperty.call(depositeDetails, currency)) {
                    updatedFormData[currency] = depositeDetails[currency].balance;
                }
            }


            // setFormData(updatedFormData);


            setFormData(prevFormData => ({
                ...prevFormData,
                UPRO: updatedFormData.UPRO,

            }));




        }


    }


    const getWithdrawHistory = async () => {

        const response = await postGetData(Apiservices.USER_WITHDRAW_HISTORY_API);
        if (response.status) {

            if (response.data) {
                setWithHis(response.data);
            } else {
                // Handle the case where there is no user data
            }



        }


    }
    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
                .getHours()
                .toString()
                .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
                    .getSeconds()
                    .toString()
                    .padStart(2, "0")}`;
        return formattedDate;
    };

    const copyToClipboard = (text) => {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success("Copied successfully");
        toast.dismiss()
    };
    useCustomEffect(() => {
        getDynomic()
        getAvailableBalance()
        getWithdrawHistory()

    })
    // console.log(formData, "@@@@@");


    const handleCurrencyChange = (cryptoName) => {
        const currency = dynomicCrypto.find(cur => cur.cur_name === cryptoName);
        setFormData((prevState) => ({ ...prevState, cryptoName }));
        setSelectedCurrency(currency);

        setErrors((prevErrors) => ({
            ...prevErrors,
            cryptoName: "",
            cryptoAmount: "",
            cryptoAddress:""
        }));
    };
    const handleBlur = (e) => {
        const { name } = e.target;
        try {
            withdrawalSchema.validateSyncAt(name, { [name]: formData[name] });
            setErrors({ ...errors, [name]: "" });
        } catch (error) {
            setErrors({ ...errors, [name]: error.message });
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        // Filtered value for cryptoAmount
        let filteredValue = value;
        if (name === "cryptoAmount") {
            filteredValue = value.replace(/[^\d.]/g, "");
        }

        // Update the state with the filtered value
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: filteredValue,
        }));

        // Validate the field
        try {
            await withdrawalSchema.validateAt(name, { [name]: filteredValue });
            // Clear error message if validation succeeds
            setErrors({ ...errors, [name]: "" });
        } catch (error) {
            // Set validation error message if validation fails
            setErrors({ ...errors, [name]: error.message });
        }
    };

    const validAddress = (cryptoAddress, cryptoName) => {
        if (cryptoName == "UPRO") {
            let crypto = 'eth'
            var valid = WAValidator.validate(cryptoAddress, crypto);
            return valid
        }
        else {
            var valid = WAValidator.validate(cryptoAddress, cryptoName);

            return valid
        }


    }

    const handlePost = async () => {
        setLoading(true);

        const data = {
            currencyName: formData.cryptoName,
            amount: Number(formData.cryptoAmount),
            toAddress: formData.cryptoAddress,
        };
        const response = await postData(Apiservices.USER_WITHDRAW_API, data);

        if (response.status) {
            toast.success(response.message);
            getAvailableBalance()
            getWithdrawHistory()
            setCopy(response.address);
            setBase(response.qrCode);
            setFormData({});
            setFormData({ cryptoName: "", cryptoAddress: "", cryptoAmount: "" })
            setLoading(false);
            // toast.success(response.message)
        } else {
            setFormData({});
            setLoading(false);
            toast.error(response.message)


            setFormData({ cryptoName: "", cryptoAddress: "", cryptoAmount: "" })

        }


    }
    console.log(selectedCurrency,"!!!!!!!!");
    const handleSubmit = (e) => {
        const emptyFields = {};


        const labelMapping = {
            cryptoName:selectedCurrency.cur_name,
            cryptoAmount:"Amount",
            cryptoAddress:"Address",
            UPRO: "UPRO"
        };

        Object.entries(formData).forEach(([key, value]) => {
            if (value === "" || value === undefined || value === null) {
                const label = labelMapping[key] || key;
                emptyFields[key] = `${label} is Required`;
            }
        });

        if (Object.keys(emptyFields).length > 0) {
            setErrors({ ...errors, ...emptyFields })
            return Promise.resolve();
        }
        withdrawalSchema
            .validate(formData)
            .then(() => {

                e.preventDefault();
                const validAddrStatus = validAddress(formData.cryptoAddress, formData.cryptoName)

                if (validAddrStatus) {
                    withdrawalSchema
                        .validate(formData)
                        .then(() => {
                            handlePost(formData)

                        })
                        .catch((error) => {
                            // Handle validation errors
                            const validationErrors = {};

                            error.inner.forEach((err) => {
                                validationErrors[err.path] = err.message;

                            });

                            setErrors(validationErrors);
                        });
                } else {
                    setErrors({
                        cryptoAddress: "Invalid Address"
                    })
                }
            })
            .catch(() => {
            })
    };
    return (
        <>
            {loading ? (
                <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />


            ) : (

                <>
                    <Header />
                    <div className='wall_deposit'>
                        <div className='container'>
                            <p ><span onClick={() => { navigate("/wallet") }}><img src='images/lftarw.png'></img></span> <span >Wallet</span> / Withdrawals</p>

                            <div className='deposit_coin'>
                                <div className='depo_cont'>
                                    <ul className='deposit_sel'>
                                        <li>Select Coin</li>
                                        <li>
                                            <div className='coin_content'>
                                                <span>Coin</span>
                                                <Dropdown className="sel_coin">
                                                    <Dropdown.Toggle
                                                        variant="success"

                                                        id="dropdown-basic">
                                                        {selectedCurrency ? (
                                                            <>
                                                                <img width={20} src={selectedCurrency.cur_image} alt={selectedCurrency.cur_name} style={{ marginRight: '8px' }} />
                                                                {selectedCurrency.cur_name}
                                                            </>
                                                        ) : (
                                                            "Select Coin"
                                                        )}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {dynomicCrypto.map((currency) => (
                                                            <Dropdown.Item
                                                                key={currency.cur_id}
                                                                onClick={() => handleCurrencyChange(currency.cur_name)}
                                                                className={currency.cur_symbol.toLowerCase()}
                                                            >
                                                                <img width={20} src={currency.cur_image} alt="" />
                                                                {currency.cur_name}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                            {/* <ul className='selcoins'>
                <li><span>TRX</span><img src="images/trontab.svg" alt="trx"></img></li>
                <li><span>BNB</span><img src="images/bnb.svg" alt="bnb"></img></li>
                <li><span>BTC</span><img src="images/bitcoin.svg" alt="bitcoin"></img></li>
               </ul> */}
                                        </li>
                                    </ul>
                                    <ul className='deposit_sel'>
                                        <li>Send To</li>

                                        <li>
                                            <div className="inptbx  ">
                                                <span>Address</span>
                                                <input
                                                    type="text"
                                                    value={formData.cryptoAddress}
                                                    placeholder="Enter Address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cryptoAddress"
                                                />
                                                <span className='closeeye'><img src="images/book.svg" alt="book" draggable="false"></img></span>
                                             <p id="error">
                                                {errors.cryptoAddress}
                                            </p>
                                            </div>
                                         
                                            {/* <div className='coin_content'>
                <span>Network</span>
                <Dropdown className="sel_coin">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Deposit To
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Trade</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Trade</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Trade</Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
               </div> */}
                                            {formData.cryptoName ? (
                                                <>
                                                    <div className='inptbx'>
                                                        <span>Amount</span>
                                                        <CurrencyFormat
                                                            autocomplete="off"
                                                            value={formData.cryptoAmount}
                                                            name="cryptoAmount"
                                                            thousandSeparator={false}
                                                            prefix={""}
                                                            // decimalScale={}
                                                            allowNegative={false}
                                                            allowEmptyFormatting={false}
                                                            allowLeadingZeros={false}
                                                            decimalSeparator="."
                                                            fixedDecimalScale={true}
                                                            inputMode="numeric"
                                                            placeholder="Enter Amount"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <p id="error">  {errors.cryptoAmount} </p>
                                                    </div>
                                                   
                                                    <button onClick={handleSubmit} className="btns">Submit</button>
                                                </>
                                            ) : ("")}

                                        </li>
                                    </ul>
                                </div>
                                <div className='depos_rgt'>
                                    <div className='depos_rgtc'>
                                        <strong>Related Questions</strong>
                                        <p>Have you had problems with your Deposit? Please click to view deposit related questions</p>
                                        <a>Related Questions</a>
                                    </div>
                                    <div className='depin'>
                                        <p><strong>1,000 UPRO  </strong> daily withdrawal limit remaining</p>
                                    </div>
                                    <div className='depin'>
                                        <p><strong>Available Balance :</strong>{formData.UPRO.toFixed(4)} UPRO</p>
                                    </div>
                                </div>
                            </div>
                            <div className='depos_his'>
                                <div className='depos_conts'>
                                    <strong>Recent Deposits</strong>
                                    {/* <div className='hiscus'>
                                        <div className='chek'>
                                            <label>
                                                <input type='checkbox'></input>
                                                <p>Hide not held assets</p>
                                            </label>
                                        </div>
                                        <a>View All</a>
                                    </div> */}
                                </div>
                                <div className='table-responsive con'>
                                    <table>
                                        <thead>
                                            <th>Time</th>
                                            <th>Address</th>
                                            <th>TxID</th>
                                            <th>Coin</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            {withHis.slice(-5).map((data, index) => (

                                                <tr key={index}>
                                                    <td>{utcToNormalTime(data.createdAt)}</td>
                                                    <td>{data.to_address ? `${data.to_address.slice(0, 6)}...${data.to_address.slice(-4)}` : ''}
                                                        <button type="button" onClick={() => copyToClipboard(data.to_address)}><img src='images/copy.svg' alt='refferal' draggable="false"></img></button>
                                                    </td>
                                                    <td> {data.transactionHash ? `${data.transactionHash.slice(0, 6)}...${data.transactionHash.slice(-4)}` : ''}
                                                        <a href={`https://ultraproscan.io/tx/${data.transactionHash}`} target="_blank" className="go"><img src="images/go.svg" alt="go"></img></a>
                                                    </td>
                                                    <td className='im-hed'>
                                                        <ul>
                                                            <li><img src="images/upro.svg" alt="tron" draggable="false"></img></li>
                                                            <li><strong>{ }</strong><span></span></li>
                                                        </ul>

                                                    </td>
                                                    <td>{data.amount}</td>
                                                    <td className='gr'>Verified</td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </>
    )
}

export default WalletDeposit