import React from 'react'
import { useNavigate } from 'react-router-dom'

const Fornotfor = () => {
    const navigate = useNavigate()
  return (
    <>
      <div className="forenotfour">
        <div className='fourconts'>
        <h1>Oops! Site Under Maintenance</h1>
        <p>We apologize for any inconveniences caused we’ve almost done. <a href='#' onClick={() => {navigate("/")}}>Turn back</a></p>
        </div>
      </div>
    
    </>
  )
}

export default Fornotfor