import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import Header from '../../header/Header';
import InputGroup from "react-bootstrap/InputGroup";
import "./buysel.css"
import Modal from "react-bootstrap/Modal";
import { utrSchema, InrSchema } from '../../../Yup';
import { postData, postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import CurrencyFormat from "react-currency-format";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactErrorToast, ReactSuccessToast } from '../../../ReactToast/ReactToast';
import { toast } from 'react-toastify';

import Loader from 'react-js-loader';


import { useNavigate } from "react-router-dom";

const BuyCrypto = () => {
  const navigate = useNavigate();

   const [read, setRead] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [noAccounts, setNoAccounts] = useState("")
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selc, setSelc] = useState(false);




  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fetchBank, setFetchBank] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState(null);
  const [uproEquivalent, setUproEquivalent] = useState("");
  const [imageError, setImageerror] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectErrors, setSelectErrors] = useState(null);
  const [errors, setErrors] = useState({});
  const [checkError, setCheckError] = useState(false);
  const [uproPrice, setUproPrice] = useState(null);
  const [imgSize, setImgSize] = useState()
  const [formData, setFormData] = useState({
    accountNumber: "",
    IFSC: "",
    bankName: "",
    branchName: "",
    inrValue: "",
    utrId: ""
  });
  const [inrValue, setInrValue] = useState("")
  const [admin, setAdmin] = useState({
    accountNumber: "",
    IFSC: "",
    bankName: "",
    accountHolderName: "",
  });
  const rmor = () =>{
    setRead(!read);
  }

  // const [currency, setCurrency] = useState({
  //   buycrpto: "",
  // });

  const getAdmin = async () => {
    const response = await postGetData(Apiservices.USER_ADMIN_REST_API);
    if (response.status) {
      if (response.bankDetails && response.bankDetails.length > 0) {
        const bankDetails = response.bankDetails[0];
        setAdmin((prevFormData) => ({
          ...prevFormData,
          accountHolderName: bankDetails.accountHolderName,
          accountNumber: bankDetails.accountNumber,
          bankName: bankDetails.bankName,
          IFSC: bankDetails.IFSC
        }));
      }
      else {
      }

    }
  }
  const getUpro = async () => {
    const response = await postGetData(Apiservices.USER_UPRO_BALANCE_API);
    if (response.status) {

      setUproPrice(response.upro.value)




    }
  }
  const getUser = async () => {
    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);
    if (response.status) {
      const user = response.user;
      if (user.bankDetails && user.bankDetails.length > 0) {
        const verifiedBankDetails = user.bankDetails.filter(detail => detail.bankVerifyStatus === 2);
        if (verifiedBankDetails.length > 0) {
          setFetchBank(verifiedBankDetails);
        } else {
          setNoAccounts("No verified bank accounts found.");
        }
      } else {
        setNoAccounts("No bank accounts added. Please add your bank account in the Banking Page.");
      }
    } else {
      setNoAccounts("No user found.");
    }
  }


  useCustomEffect(() => {
    getAdmin()
    getUpro()
    getUser()
  });
  // console.log(uproPrice, "@@@@@");




















  const handleChange = async (e) => {
    const { name, value } = e.target;
    let filteredValue = value;
    if (name === "utrId") {
      filteredValue = value.replace(/\s/g, "").toUpperCase();
    }


    setFormData({ ...formData, [name]: filteredValue });

    utrSchema
      .validateAt(name, { [name]: filteredValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
    // Clear error message if field is empty
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmitEnter = (e) => {
    if (e.key === "Enter");
    // handleSubmit(e)
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the selected file
    if (selectedFile) {
      setImageerror("")
      setImgSize(selectedFile.size / 1024)

      // Set the selected file to the state
      setSelectedFile(selectedFile);

      // Create URL for the file and set it to profileImage state
      setProfileImage(URL.createObjectURL(selectedFile));

      // Reset any previous image error
      // setImageerror("");


      const allowedTypes = ["image/jpeg", "image/png"];

      if (!allowedTypes.includes(selectedFile.type)) {
        // toast.error("We Accept Only Image");
        // Reset the input field
        setProfileImage();
        setSelectedFile("")
        event.target.value = "";
      }
    } else {
      console.error("No file selected");
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    try {
      utrSchema.validateSyncAt(name, { [name]: formData[name] });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
  };

  const handleDropdownSelect = (selectedBank) => {
    if (selectedBank) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        accountHolderName: selectedBank.accountHolderName,
        accountNumber: selectedBank.accountNumber,
        IFSC: selectedBank.IFSC,
        bankName: selectedBank.bankName,
        branchName: selectedBank.branchName,
      }));
      setSelectErrors("")
      setSelectedAccount(selectedBank.accountNumber);
      setSelc(true);
    }
  };
  const decByPer = (amount, per) => {
    return (amount * (per / 100))
  }

  const [percentageDeduction, setPercentageDeduction] = useState(0)

  const [balanceAfterDeduction, setBalanceAfterDeduction] = useState(0)

  const handleInrChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    setFormData(prevData => ({
      ...prevData,
      inrValue: numericValue
    }));
    const onePercentDeduction = parseFloat(numericValue) * 0.01;
    setPercentageDeduction(onePercentDeduction); // Store the percentage deduction in state

    const balanceAfterDeduction = parseFloat(numericValue) - onePercentDeduction;
    setBalanceAfterDeduction(balanceAfterDeduction);


    if (!isNaN(numericValue) && uproPrice !== null) {
      const onePercentDeduction = parseFloat(numericValue) * 0.01;
      const balanceAfterDeduction = parseFloat(numericValue) - onePercentDeduction;

      // Your other calculations
      const uproEquivalent = (balanceAfterDeduction) / uproPrice; // changed this line

      if (!isNaN(uproEquivalent)) {
        setUproEquivalent(uproEquivalent);
      } else {
        setUproEquivalent(0);
        setPercentageDeduction(0);
        setBalanceAfterDeduction(0)
      }
    } else {
      setUproEquivalent(0);
      setPercentageDeduction(0);
      setBalanceAfterDeduction(0)


    }
    // Validation
    InrSchema
      .validateAt('inrValue', { inrValue: String(inputValue) }) // Ensure it's treated as string
      .then(() => {
        setErrors(prevErrors => ({ ...prevErrors, inrValue: '' }));
      })
      .catch((error) => {
        setErrors(prevErrors => ({ ...prevErrors, inrValue: error.message }));
      });


  };


  const handleShow = (e) => {
    e.preventDefault();
    const labelMapping = {
      inrValue: "INR value",
    };
    const emptyFields = {};
    Object.entries(formData.inrValue).forEach(([key, value]) => {
      if (value === "" || value === undefined || value === null) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors(emptyFields);
      return Promise.resolve();
    }
    InrSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (isChecked) {
          setShow(true)

          setErrors({});


        } else {
          setCheckError("Please Click Check Box")

        }

      })
      .catch((error) => {
        // Handle validation errors
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });
    if (!isChecked) {
      setCheckError("Please Click Check Box")
    }

  };
  const handleKeyDownForUTR = (e) => {
    // Allow numbers (0-9), letters (a-zA-Z), and backspace
    if (!(e.key === "Backspace" || /^[a-zA-Z0-9]*$/.test(e.key))) {
      e.preventDefault();
    }
  };


  const handlePost = async () => {
    setLoading(true)
    // const URL = `/buyCrypto`;

    // Create FormData object
    // const formDataToSend = new amount();
    // const  = new selectedFile();

    const formDataToSend = new FormData();

    formDataToSend.append("ifsc", formData.IFSC);
    formDataToSend.append("account_number", formData.accountNumber);
    formDataToSend.append("fcur_amount", formData.inrValue);
    formDataToSend.append("tcur_value", uproEquivalent);
    formDataToSend.append("bank_name", formData.bankName);
    formDataToSend.append("utr", formData.utrId);
    formDataToSend.append("from_currency", "INR");
    formDataToSend.append("to_currency", "UPRO");
    formDataToSend.append("admin_ifsc", admin.IFSC);
    formDataToSend.append("admin_bank", admin.bankName);
    formDataToSend.append("admin_holdername", admin.accountHolderName);
    formDataToSend.append("admin_accountNo", admin.accountNumber);
    formDataToSend.append("image", selectedFile);
    formDataToSend.append("tax", percentageDeduction);


    const response = await postData(Apiservices.USER_BUYCRYPTO_API, formDataToSend);

    if (response.status) {

      setShow(false);
      setIsChecked(false);

      toast.success(response.message);

      // ReactSuccessToast(response.message)
      setLoading(false)
      setProfileImage("");
      setSelectedFile("")
      setFormData({
        inrValue: "",
        accountNumber: "",
        IFSC: "",
        bankName: "",
        branchName: "",
        utrId: ""
      });
      setSelc(false);
      setIsChecked(false);
      setUproEquivalent("")
      setShow(false)
      setSelectedAccount(null);
    } else {
      // ReactErrorToast(response.message)

      toast.error(response.message);

      setLoading(false)
      setProfileImage("");
      setSelectedFile("")
      setSelc(false);
      setUproEquivalent("")
      setFormData({
        inrValue: "",
        accountNumber: "",
        IFSC: "",
        bankName: "",
        branchName: "",
        utrId: ""

      });
      setShow(false)
      setIsChecked(true);
      setSelectedAccount(null);
      setIsChecked(false);
    }


  }
  const [currentPage, setCurrentPage] = useState("");

  // useEffect(() => {
  //   axiosInstance
  //     .post(`/getBankAccount`)
  //     .then((response) => {
  //       if (response.data.status && response.data.bankDetails.length > 0) {
  //         const lastBankDetail =
  //           response.data.bankDetails[response.data.bankDetails.length - 1];
  //         setFormData((prevFormData) => ({
  //           ...prevFormData,
  //           accountHolderName: lastBankDetail.accountHolderName,
  //           bankName: lastBankDetail.bankName,
  //           accountType: lastBankDetail.accountType,
  //           branchName: lastBankDetail.branchName,

  //         }));
  //         // setCurrentPage(response.data.bankDetails.bankVerifyStatus)
  //       } else {
  //         console.error("Bank details not found in the response.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching bank details:", error);
  //     });
  // }, []);

  const handleSubmit = (e) => {



    e.preventDefault();
    let ImgSize1 = false;

    if (!selectedFile) {
      setImageerror("Receipt Image Required");
    }
    else {
      if (selectedFile.size > 4000 && selectedFile.size < 200000) {
        setImageerror("");
        ImgSize1 = true;
      }
      else {
        setImageerror("Image should be between 40 - 200 kb");
      }
    }


    utrSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (selectedFile && ImgSize1) {
          if (selectErrors == "") {
            setShow(false);
            setLoading(true);
            handlePost(formData);
          }
          else {
            setLoading(false);
            setSelectErrors("Please select an account");
          }
        }
      })
      .catch((error) => {
        // Handle validation errors
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });

    const { name, value } = e.target;
    let filteredValue = value;

    if (!selectedFile) {
    } else {
      // setImageerror(null); // Clear the error if a file is selected
    }
    if (!isChecked) {
      setCheckError("Please Click Check Box");
    }
    if (!selectedAccount) {
      setSelectErrors("Please select an account");
      return;
    } else {
      setSelectErrors("");
    }
  };

  const handleCheckboxClick = () => {
    if (!isChecked) {
      setIsChecked(true); // Set isChecked to true to hide the error
      setCheckError("")
    }
    else {
      setCheckError("Please Click Check Box")
    }

  };















  const modClick = () => {
    setShowModal(true)
    // console.log("modal")
  }
  const modClose = () => {
    setShowModal(false)

  }
  const copyToClipboard = (copy) => {
    const el = document.createElement('textarea');
    el.value = copy;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success("Copied successfully");
    toast.dismiss()
};
  return (

    <>

      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
      ) : (

        <>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="buycr suportmod"
            backdrop="static" size="md"
          >

            <Modal.Header closeButton>
              <Modal.Title>User's Account Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="upic">

                <Form autoComplete="off">
                  <div className="selnc">
                    <Form.Label>Select Account</Form.Label>
                    <Form.Group>
                      <DropdownButton
                        variant="outline-secondary"
                        title={selectedAccount || "Select Account"}
                        id="input-group-dropdown-1"
                        className="drd"
                      // onSelect={handleDropdownSelect}
                      >
                        {fetchBank.map((bank, index) => (
                          <Dropdown.Item
                            onClick={() => handleDropdownSelect(bank)}
                            key={index}
                          >
                            {bank.accountNumber} {bank.bankName}

                          </Dropdown.Item>
                        ))}
                        {noAccounts}
                      </DropdownButton>
                      <p id="error">{selectErrors}</p>
                    </Form.Group>
                    <ul>
                      <li><strong>Account Holder Name :</strong> <span>{formData.accountHolderName}</span></li>
                      <li><strong>Account Number :</strong> <span>{formData.accountNumber}</span></li>
                      <li><strong>IFSC Code :</strong> <span>{formData.IFSC}</span></li>
                      <li><strong>Branch Name :</strong> <span>{formData.branchName}</span></li>
                      <li><strong>Bank Name :</strong> <span>{formData.bankName}</span></li>
                    </ul>
                  </div>

                  <Form.Group
                    className="mb-3 inpt inptbx flin"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>UTR Id/Transactions Id/ Referral Id</Form.Label>
                    <Form.Control
                      value={formData.utrId}
                      name="utrId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      onKeyDown={handleKeyDownForUTR}
                      maxLength={23}
                    />
                    <p id="error">{errors.utrId}</p>
                  </Form.Group>
                  <div className='upld'>
                    <div className="upl">
                      <span>Upload Deposit Receipt</span>
                      <label>
                        <input name='proofFront' accept="image/*" onChange={handleFileChange} type='file'></input>
                        {profileImage ? (
                          <p>
                            <img
                              style={{ width: "100px" }}
                              src={profileImage}
                              alt="profile"
                              draggable="false"
                            />
                          </p>

                        ) : (
                          <p>Receipt Image</p>
                        )}
                      </label>
                      <p id="error">{imageError}</p>
                    </div>
                    <div className="showim">
                      <strong>Upload Receipt Image</strong>
                      <p> receipt image should be clear and the minimum size image 40kb and  maximum size image 200kb</p>

                    </div>
                  </div>

                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btmt btns" onClick={handleSubmit}>
                Submit
              </button>
              <button type="submit" className="cans btns" onClick={() => setShow(false)} >
                cancel
              </button>
            </Modal.Footer>

          </Modal>

          <Header />
          <div className='by_crypc'>
            <div className='container'>
              <div className='bys_co'>
                <div className='buy_title'>
                  <h1>Buy Crypto</h1>
                  <ul className="sup_his">
                    <li className='his' onClick={() => navigate('/buyCryptoHistory')} >History</li>
                    <li className='supports' onClick={() => navigate('/support')}  >
                      Support
                    </li>
                  </ul>
                </div>
                <div className="by_content">
                  <div className="admi_det">
                    <strong>Admin's Bank details</strong>
                    <ul>
                      <li><strong>Account Number </strong><span>:</span><span>{admin.accountNumber}<img onClick={()=>copyToClipboard(admin.accountNumber)} src='images/copy_icon.svg' alt='copy' draggable="false"></img></span></li>
                      <li><strong>Bank Name </strong><span>:</span> <span>{admin.bankName}</span></li>
                      <li><strong>IFSC Code </strong><span>:</span> <span>{admin.IFSC}<img onClick={()=>copyToClipboard(admin.IFSC)} src='images/copy_icon.svg' alt='copy' draggable="false"></img></span></li>
                      <li><strong>Account Holder Name </strong><span>:</span> <span>{admin.accountHolderName}</span></li>

                    </ul>
                  </div>
                  <div className="buy_cont">
                    <strong>Crypto Conversion</strong>
                    <div className="buys_in" >
                      <div className="in_putg">
                        <Form.Label>Pay</Form.Label>
                        <InputGroup className="mb-3 butp">
                          <div className="custom-dropdown self">
                            <div className="">

                              <p>
                                <img src="images/inr.svg" alt="inr" draggable="false"></img>
                                <span>INR</span>
                              </p>

                            </div>

                          </div>
                          <div className="inptbx  ">
                            <CurrencyFormat
                              autoComplete='off'
                              maxLength={12}
                              value={formData.inrValue}
                              name="inrValue"
                              thousandSeparator={true}
                              prefix={"₹"}
                              decimalScale={2}
                              allowNegative={false}
                              allowEmptyFormatting={false}
                              allowLeadingZeros={false}
                              decimalSeparator="."
                              fixedDecimalScale={true}
                              inputMode="numeric"
                              onChange={handleInrChange}
                              onKeyPress={handleSubmitEnter}
                            />
                          </div>



                        </InputGroup>
                        <p id="error">{errors.inrValue}</p>

                      </div>
                      <div className="ar_com">
                        <img src="images/ar.svg" className="barc" draggable="false"></img>
                      </div>
                      <div className="bysele mb-3 ">
                        <Form.Label>Receive</Form.Label>
                        <div className="uprocver">

                          <Form.Select aria-label="Default select example" disabled>
                            <option value="1">UPRO</option>
                          </Form.Select>
                          <Form.Control type="text" className="uproget" value={uproEquivalent} readOnly></Form.Control>
                        </div>

                      </div>

                    </div>

                  </div>

                  <div className="upi_b">

                    <div className= {read ? "rad upi_veri" : "upi_veri"}>

                      <strong>Order Details</strong>

                      <div className="admi_det">

                        <ul>
                          <li><strong>Paid Amount </strong><span>:</span> <span> {formData.inrValue ? "₹" + formData.inrValue : " ₹ 0"}</span></li>
                          <li><strong>Tax (1%) </strong><span>:</span> <span>₹ {percentageDeduction}</span></li>
                          <li><strong>1 UPRO</strong><span>:</span> <span>{uproPrice} INR</span></li>
                          <li><strong> {balanceAfterDeduction}  INR </strong><span>:</span> <span>{uproEquivalent} UPRO</span></li>

                        </ul>
                      </div>



                      <p>
                        <span>Disclaimer
                          :
                        </span>
                        <ul>
                        <li>When you deposit fiat funds through bank transfer, the equivalent amount will be credited as crypto on our platform. The processing time for bank transfers may vary based on your bank's policies, your location, and other factors. Generally, it can take a few business days for the funds to be credited to your account.</li>
                        <li>To ensure a smooth deposit, please double-check that your bank transfer details are accurate, including our exchange's bank account information, your customer ID or reference number, and any other required information. Incorrect or incomplete details may lead to delays or failed transactions.<span className='rmor' onClick={rmor}>Read More</span></li>
                        </ul>
                        <ul >
                        <li>If you experience any issues or delays with the Upro deposit, our support team will investigate on our end to resolve the problem. However, if the currencies are not credited to your wallet after a reasonable period, we recommend contacting your bank for further assistance, as there may be issues with the transfer on their end.</li>
                        <li>Please note that bank transfers might incur fees from your bank or intermediary banks, which can affect the final amount received. We recommend checking with your bank regarding any potential fees before initiating a transfer. <span className='rless' onClick={rmor}>Read less</span></li>
                        </ul>

                      </p>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='by_foot'>
            <div className='container'>
              <div className="chk_bx">
                <label>
                  <input type="checkbox" className="chkin"
                    checked={isChecked}
                    onClick={handleCheckboxClick}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  ></input>

                  <p>I have read and agree to the <span className='terms'> <a href='/termsconditions'> Terms of Use </a> </span> </p>
                </label>
                <p id="error">{checkError}</p>
              </div>
              <ul className="upi_c">
                <li>

                </li>

                <li>
                  <button type="submit" className=" btns" onClick={handleShow} >
                    Submit
                  </button>
                </li>
              </ul>
            </div>
          </div>


        </>
      )}
    </>
  )
}

export default BuyCrypto