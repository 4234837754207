import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Header.css"
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';



const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'light') {
      document.body.classList.add('light-theme');
    }
  }, []);

  const daytonight = () => {
    document.body.classList.toggle('light-theme');
    if (document.body.classList.contains('light-theme')) {
      localStorage.setItem('theme', 'light');
    } else {
      localStorage.setItem('theme', 'dark');
    }
  };





  // const daytonight = () => {
  //   // Toggle the class on the body element
  //   document.body.classList.toggle('light-theme');
  // };
  const [show, setShow] = useState(true);

  useEffect(() => {
    let isShow = Cookies.get("jwtToken") !== null && Cookies.get("jwtToken") !== undefined ? false : true;
    setShow(isShow);

  })


  const handleNavigate = (propts) => {


    // console.log("888888888888 ", propts)

    if (propts == "Profile") {
      setText(!text)
      navigate("/account-details?tab=profile");
    }

    if (propts == "Support") {
      navigate("/support");
    }
    if (propts == "Security") {
      setText(!text)
      navigate("/account-details?tab=security");
    }
    if (propts == "Referral") {
      setText(!text)
      navigate("/referral");
    }



  }


  const handleSignout = () => {

    toast.success("Sign Out Successfull");

    Cookies.remove("jwtToken");

    // const token = Cookies.get("jwtToken");

    navigate("/auth");

  }

  const [text, setText] = useState(false);

  const tex = () => {
    setText(!text)
  }



  // console.log(show,"HEAD");


  return (
    <header>
      <div className='heads'>
        {/* {['lg'].map((expand) => (
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3 fixed-top">
            <Container fluid>
              <Navbar.Brand href="#"><img src='images/hlogod.svg' alt='Canopro Exchange'></img><span><img src='images/hlogo.svg' alt='Canopro Exchange'></img></span></Navbar.Brand>
              <div className='the-ch resp' onClick={() => daytonight()}>
                <img src='images/moon.svg' alt='moon'></img><span><img src='images/sun-icon.svg' alt='moon'></img></span>
              </div>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
              >
                <Offcanvas.Header closeButton>

                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className=" menubar flex-grow-1 pe-3">
                    <Nav.Link href="#action1">Home</Nav.Link>
                    <Nav.Link href="#action2">Exchange</Nav.Link>
                    <Nav.Link href="#action3">Help</Nav.Link>
                  </Nav>
                  <div className='the-ch' onClick={() => daytonight()}>
                    <img src='images/moon.svg' alt='moon'></img><span><img src='images/sun-icon.svg' alt='moon'></img></span>
                  </div>
                  <ul className='logsign'>
                    {show ?
                      (
                        <>
                          <li><button type='button' className='btns' onClick={() => navigate("/auth?tab=signin")}>Sign in</button></li>
                          <li><button type='button' className='btns' onClick={() => navigate("/auth?tab=signup")}>Sign up</button></li>
                        </>

                      )
                      :
                      (

                        <>
                          <li><button type='button' className='btns' onClick={() => navigate("/")}>Home</button></li>
                          <li><button type='button' className='btns' onClick={handleSignout}>Sign out</button></li>
                        </>
                      )

                    }




                  </ul>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

            </Container>
          </Navbar>
        ))} */}
        <Navbar expand="lg" className="bg-body-tertiary  fixed-top">
          <Container className='fiks'>
            <Navbar.Brand href="/"><img src='https://images-ultra.s3.amazonaws.com/hlogod.svg' alt='Canopro Exchange' draggable='false'></img><span><img src='https://images-ultra.s3.amazonaws.com/hlogo.svg' alt='Canopro Exchange' draggable='false'></img></span></Navbar.Brand>
           {show ? (
""
           ):(
            <ul className={text ? "texad profile_support" : "profile_support"} >
            <li className='drp_down'><span  onClick={tex} ><img src="images/profile.svg" alt='profile' draggable='false'></img><span><img src="images/profileb.svg" alt='profile' draggable='false'></img></span></span>
              <div className='drp_cont'>
                <ul>
                  <li onClick={() => { handleNavigate("Profile")}} ><img src="images/profile.svg" alt='profile' draggable='false'></img> <span><img src='images/profileb.svg' alt='profile' draggable='false'></img></span><p><strong>Profile</strong><span>Important Account Details</span></p></li>
                  <li onClick={() => { handleNavigate("Security")}} ><img src="images/security.svg" alt='security' draggable='false'></img><span><img src='images/securityb.svg' alt='security' draggable='false'></img></span><p><strong>Security</strong><span>Setup 2FA For More Security</span></p></li>
                  <li onClick={() => { handleNavigate("Referral")}} ><img src="images/referral.svg" alt='Referral' draggable='false'></img><span><img src='images/referb.svg' alt='referral' draggable='false'></img></span><p><strong>Referral</strong><span>Invite friends To Earn Upto 60% Comission</span></p></li>
                </ul>
                <p onClick={handleSignout}>logout</p>
              </div>
            </li>
            <li className='supports' onClick={() => { handleNavigate("Support")}} ><img src="images/support.svg" alt='support' draggable='false'></img><span><img src='images/supportb.svg' alt='' draggable='false'></img></span></li>

              </ul>

            )}

            <div className='the-ch resp' onClick={() => daytonight()}>
              <img src='https://images-ultrapro.s3.amazonaws.com/night_5293483.svg' alt='moon' draggable='false'></img><span><img src='https://images-ultra.s3.amazonaws.com/sun-icon_12280785_1_1.png' alt='moon' draggable='false' ></img></span>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto menubar ">
                <Nav.Link href="/" active={location.pathname === '/'}>Home</Nav.Link>
                <Nav.Link href="/buyCryptoPage" active={location.pathname === '/buyCryptoPage'}>Buy Crypto</Nav.Link>
                {/* <Nav.Link href="/trade" active={location.pathname === '/trade'}>Trade</Nav.Link> */}
                <a href="https://exchange.canopro.com" target='blank' active={location.pathname === '/trade'}>Trade</a>

                {/* <Nav.Link href="#action3">Help</Nav.Link> */}
              </Nav>
              <Nav className='menuc'>
                {/* <Nav.Link href="#action4" active={location.pathname === '/wallet'} className='temp'>Activity</Nav.Link> */}
                <Nav.Link href="/wallet" active={location.pathname === '/wallet'}>Wallet</Nav.Link>
                <Nav.Link href="/stake" active={location.pathname === '/stake'}>Stake</Nav.Link>
              </Nav>


              <div className='the-ch' onClick={() => daytonight()}>
                <img src='https://images-ultrapro.s3.amazonaws.com/night_5293483.svg' alt='moon' draggable='false'></img><span><img src='https://images-ultra.s3.amazonaws.com/sun-icon_12280785_1_1.png' alt='moon' draggable='false' ></img></span>
              </div>
              <ul className='logsign'>
                {show ?
                  (
                    <>
                      <li><button type='button' className='btns' onClick={() => navigate("/auth?tab=signin")}>Sign In</button></li>
                      <li><button type='button' className='btns' onClick={() => navigate("/auth?tab=signup")}>Sign Up</button></li>
                    </>

                  )
                  :
                  (

                    <>

                      <ul className={`profile_support tro ${text ? "texad" : ""}`} >
                        <li className='drp_down'><span onClick={tex}><img src="images/profile.svg" alt='profile' draggable='false'></img><span><img src="images/profileb.svg" alt='profile' draggable='false'></img></span></span>
                          <div className='drp_cont'>
                            <ul>
                              <li onClick={() => { handleNavigate("Profile") }} ><img src="images/profile.svg" alt='profile' draggable='false'></img> <span><img src='images/profileb.svg' alt='profile' draggable='false'></img></span><p><strong>Profile</strong><span>Important Account Details</span></p></li>
                              <li onClick={() => { handleNavigate("Security") }} ><img src="images/security.svg" alt='security' draggable='false'></img><span><img src='images/securityb.svg' alt='security' draggable='false'></img></span><p><strong>Security</strong><span>Setup 2FA For More Security</span></p></li>
                              <li onClick={() => { handleNavigate("Referral") }} ><img src="images/referral.svg" alt='Referral' draggable='false'></img><span><img src='images/referb.svg' alt='referral' draggable='false'></img></span><p><strong>Referral</strong><span>Invite friends To Earn Upto 60% Comission</span></p></li>
                            </ul>
                            <p onClick={handleSignout}>logout</p>
                          </div>
                        </li>
                        <li className='supports' onClick={() => { handleNavigate("Support") }}  ><img src="images/support.svg" alt='support' draggable='false'></img><span><img src='images/supportb.svg' alt='' draggable='false'></img></span></li>
                        {/* <li><span className='buys' onClick={() => {navigate("/buyCryptoPage")}}>Buy Crypto</span></li> */}
                      </ul>
                    </>
                  )

                }




              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </div>
    </header>
  )
}

export default Header