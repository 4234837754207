import React, { useState, useEffect } from 'react'
import Header from '../../header/Header'
import { Tab } from 'bootstrap'
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate } from 'react-router-dom';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { toast } from 'react-toastify';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ProgressBar from "@ramonak/react-progress-bar";
import { postData, postGetData } from '../../../AxiosInstance/AxiosInstance';

import Cookies from "js-cookie";

import Loader from 'react-js-loader';
import { number } from 'yup';


const StakeHistory = () => {



  const [uproBalance, setUproBalance] = useState({ balance: 0, earn_balance: 0 })

  const [balance, setBalance] = useState({
    UPRO: 0,
    BNB: 0,
    TRX: 0,
    MATIC: 0,
    ETHERS: 0,
  });


  const [jwtCheke, setJwtCheck] = useState(false);


  const jwtCheck = () => {

    const token = Cookies.get("jwtToken");
    if (token) {
      setJwtCheck(true)


    } else {
      setJwtCheck(false)

    }
  }


  const [seti, setSeti] = useState(false);
  const clks = () => {
    setSeti(!seti);
  }

  const [progressPercentages, setProgressPercentages] = useState({}); // Store progress percentages for each stake item
  const [formData, setFormData] = useState([]); // Initialize formData as an array
  const [intervalRefs, setIntervalRefs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redeem, setRedeem] = useState(false);
  const [uproPrice, setUproPrice] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const growth = async (res) => {


    const response = await postData(Apiservices.USER_GROWTH_HISTORY, { current_value: res })


    if (response.status) {
      fetchData();
    } else {
      // Handle error
      console.error("Error fetching stake data:", response.data.message);
    }


  };


  const fetchData = async () => {

    setLoading(true);

    const response = await postGetData(Apiservices.USER_GETSTAKE_HISTORY)
    if (response.status) {
      setLoading(false);


      setFormData(response.stake);
    } else {
      setLoading(false);

      // Handle error
      console.error("Error fetching stake data:", response.message);
    }
  };

  const getUproValue = async () => {
    const response = await postGetData(Apiservices.USER_UPRO_BALANCE)
    if (response.upro) {
      setUproPrice(response.upro.value);
      growth(response.upro.value);
    }
  };

  const getBalance = async () => {
    const response = await postGetData(Apiservices.USER_STAKE_BALANCE)


    if (response.status) {
      setBalance((prevBalance) => ({
        ...prevBalance,
        UPRO: response.balanceDetails.UPRO.balance
      }));
    }

  };

  const getEarnBalance = async () => {
    const response = await postGetData(Apiservices.USER_BALENCE_API);
    if (response.status) {
      const coinBalances = response.CoinBalance;
      setBalance(coinBalances);


      // const totalEarn = coinBalances.find((acc) => coin.earn_balance,0);
      // setTotalEarnBalance(totalEarn);
      const upro = coinBalances.find(coin => coin.cur_Name === "UPRO");
      if (upro) {
        setUproBalance(upro);
      }

    }

  };

  useEffect(() => {
    jwtCheck();
    getBalance();
    getUproValue();
    getEarnBalance();

    return () => {
      intervalRefs.forEach((intervalRef) => clearInterval(intervalRef));
    };
  }, []);

  useEffect(() => {
    // Calculate progress percentage for each stake item
    formData.forEach((stake, index) => {
      const startDate = new Date(stake.stakeStartTime);
      const endDate = new Date(stake.stakeEndTime);
      const localStartDate = new Date(
        startDate.getTime() + startDate.getTimezoneOffset() * 60000
      );
      const localEndDate = new Date(
        endDate.getTime() + endDate.getTimezoneOffset() * 60000
      );

      // console.log(startDate, "START");
      // console.log(endDate, "END");

      const totalTime = endDate.getTime() - startDate.getTime();
      const intervalRef = setInterval(() => {
        const currentTime = new Date();
        const elapsedTime = currentTime.getTime() - startDate.getTime();
        let percentage = (elapsedTime / totalTime) * 100;
        // If the stake duration has elapsed, set the progress to 100%
        if (elapsedTime >= totalTime) {
          setRedeem(true);
          percentage = 100;
          clearInterval(intervalRef);
        }
        setProgressPercentages((prevProgress) => ({
          ...prevProgress,
          [index]: percentage,
        }));
      }, 1000);
      // Update intervalRefs using functional update
      setIntervalRefs((prevRefs) => [...prevRefs, intervalRef]);
    });

    // Clear intervals when component updates or unmounts
    return () => {
      intervalRefs.forEach((intervalRef) => clearInterval(intervalRef));
    };
  }, [formData]);

  const handleRedeem = async (data) => {
    setLoading(true);
    const response = await postData(Apiservices.USER_REDEEM_STAKE, { stakeId: data.stakeId, startTime: data.stakeStartTime })

    if (response.status) {
      fetchData()
      setLoading(false);
      toast.dismiss();
      toast.success(response.message);
    } else {
      setLoading(false);
      toast.dismiss();
      toast.error(response.message);
    }
  };



  const utcToNormalTime = (utcString) => {
    const date = new Date(utcString);
    const offset = date.getTimezoneOffset(); // Get the time zone offset in minutes
    const adjustedDate = new Date(date.getTime() - offset * 60000); // Adjust the date by subtracting the offset in milliseconds

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const localDate = new Date(); // Current local date
    localDate.setMinutes(localDate.getMinutes() - offset); // Adjust current local date to UTC

    // If the date provided is in the future, adjust it backward
    if (adjustedDate > localDate) {
      adjustedDate.setDate(adjustedDate.getDate());
    }

    const formattedDate = `${adjustedDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${monthNames[adjustedDate.getMonth()]
      }-${adjustedDate.getFullYear()}`;
    const formattedTime = `${adjustedDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${adjustedDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${adjustedDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;
    return { date: formattedDate, time: formattedTime };
  };
  const [expandedIndices, setExpandedIndices] = useState({});

  const handleReadMoreClick = (index) => {
    setExpandedIndices((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state for the clicked index
    }));
  };



  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className='bt_tool'>
      <ul className='tools'>
        <li>Before staking your coins, confirm the exact length of the lock-up period. In this case, it's six months.`</li>
        <li>Once you stake your coins, they cannot be retrieved until the end of the staking period.
          There are generally no exceptions, so ensure you are comfortable with locking your coins for that duration.
        </li>
        <li>During the staking period, your coins will earn rewards and they will be added to your wallet.
        </li>
        <li>Once after your 3rd month completion, you can retrieve your coins along with the rewards earned.
        </li>
      </ul>
    </Tooltip>
  );

  return (

    <>
      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />

      ) : (

        <>
          <Header />
          <div className='main-content'>
            <div className='stake_ban stak_his' >
              <div className='container'>
                <div className='stak_con'>
                  <div className='stak_cont'>
                    <h1>Stake History</h1>
                    <p>Grow Your Crypto with Passive Returns</p>
                  </div>
                  <div className='stak_rgt'>
                    <img src='images/cupeg.svg' alt='stake_ban' draggable="false"></img>
                    {jwtCheke ?
                      (
                        <div className='stak_tex'>
                          <strong>Earn Account </strong>
                          {/* <p><span>=$0.00</span> <span>0.00000000 BTC</span></p> */}
                          <p><span>{uproBalance.earn_balance.toFixed(4)} UPRO</span></p>


                          <ul>
                            <li> <button type='button' className='btns' onClick={() => navigate("/wallet")}>Wallet</button></li>
                            {/* <li> <button type='button' className='btns' onClick={() => navigate("/stakeHistory")}>History</button></li> */}
                          </ul>
                          {/* <span className='eye'><img src='images/eye.svg' alt='eye' draggable="false"></img></span> */}
                        </div>
                      )
                      :
                      (

                        <div className='stak_tex stak_con'>
                          <img src='https://images-ultra.s3.amazonaws.com/hlogod.svg' alt='logo' draggable="false"></img>
                          <p>Sign in to view your holdings</p>
                          <button type='button' className='btns'>Sign in</button>
                        </div>
                      )}

                  </div>
                </div>
              </div>
            </div>
            <div className='stake_tab '>
              <div className='container'>
                <div className='stake_tabc'>
                  <Tabs
                    defaultActiveKey="three" className='stak_tab'>
                    <Tab eventKey="three" title=" 3 Months">
                      <div className='stak_stat'>
                        {/* {formData.map((data, index) => (

                      <div className={seti ? "seti stak_bx" : "stak_bx"} >
                        <div className='stak_box'>
                          <strong>{data.stakeDuration}</strong>
                          <ul>
                            <li><span>Duration</span><strong>{data.duration} Days</strong></li>
                            <li><span>Maturity Date </span><strong> {utcToNormalTime(data.stakeEndTime).date}</strong></li>
                            <li><span>Staked Amount </span><strong>{data.stakeAmount} UPRO</strong></li>
                            <li><span>Initial Asset Value  </span><strong> ₹{data.initialStakeValue}</strong></li>
                            <li><span>Growth  </span><strong> ₹ {parseFloat(data.finalStakeValueToINR).toFixed(2)}</strong></li>


                            <li><span></span><strong className='gr' onClick={clks}>Read More</strong></li>
                          </ul>
                          <OverlayTrigger
                            placement="bottom-end"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <span className='infor'><img src='images/information.svg' alt='information'></img></span>
                          </OverlayTrigger>

                          <div className="stak_box stak_boxcon" >
                            <ul>

                              <li><span>Reward Amount  </span><strong> 200 UPRO</strong></li>
                              <li><span>Stake Growth Percentage </span><strong>{parseFloat(data.stakeGrowthPercentage) >= 0 ? parseFloat(data.stakeGrowthPercentage).toFixed(2) + '%' : '0.00%'}</strong></li>
                              <li><span>Stake Date  </span><strong>{utcToNormalTime(data.stakeStartTime).date}</strong></li>
                              <li><span>Growth  </span><strong> +80.00%</strong></li>
                            </ul>
                            <ProgressBar
                              completed={progressPercentages[index] || 0}
                              customLabel={data.stakeDuration}
                              bgColor="#4CAF50"
                              baseBgColor="#DADADA"
                              labelColor="#4CAF50"
                              height="16px"
                              borderRadius="4px"
                            />
                            <p>{`${progressPercentages[
                              index
                            ]
                              ? progressPercentages[
                                index
                              ].toFixed(2)
                              : "0"
                              }%`}</p>
                            <p>{data.stakeDuration}</p>

                          </div>
                          {progressPercentages[index] === 100 &&
                            (data.stakeStatus === "INPROGRESS" ? (
                              <button
                                onClick={() => handleRedeem(data)}
                              >
                                Redeem
                              </button>
                            ) : (
                              <p className="cli">CLAIMED</p>
                            ))}
                        </div>
                      </div>
                    ))} */}

                        {formData.map((data, index) => {

                          console.log(data.initialStakeValue);
                          const stakeGrowthPercentage = ((data.AssetValue - data.current_value) / data.current_value * 100).toFixed(2);

                          return (
                            <div key={index} className={seti ? "seti stak_bx" : "stak_bx"}>
                              <div className='stak_box'>
                                <strong>{data.stakeDuration}</strong>
                                <ul>
                                  <li><span>Stake Date  </span><strong>{utcToNormalTime(data.stakeStartTime).date}</strong></li>
                                  <li><span>Duration</span><strong>{data.duration} Days</strong></li>
                                  <li><span>Maturity Date </span><strong>{utcToNormalTime(data.stakeEndTime).date}</strong></li>
                                  <li><span>Staked Amount </span><strong>{data.stakeAmount} UPRO</strong></li>
                                  <li><span>Initial Asset Value  </span><strong> ₹{data.initialStakeValue}</strong></li>
                                  <li><span>Current Asset Value  </span><strong> ₹{data.AssetValue * data.stakeAmount}</strong></li>
                                  {/* <li><span>Growth  </span><strong> ₹ {parseFloat(data.finalStakeValueToINR).toFixed(2)}</strong></li> */}
                                  <li><span>Growth</span><strong>{stakeGrowthPercentage}%</strong></li>



                                  {
                                    (data.AssetValue * data.stakeAmount) < (data.initialStakeValue) && (data.stakeDuration.split(' ')[0] == 3)
                                      ? <li><span>Minimum Guarantee</span><strong> ₹{Number(data.initialStakeValue) + Number(data.initialStakeValue / 100 * Number(12))}</strong>  </li>
                                      : (data.AssetValue * data.stakeAmount) < (data.initialStakeValue) && (data.stakeDuration.split(' ')[0] == 6)
                                        ? <li><span>Minimum Guarantee</span><strong>₹{Number(data.initialStakeValue) + Number(data.initialStakeValue / 100 * Number(24))} </strong> </li>
                                        : (data.AssetValue * data.stakeAmount) < (data.initialStakeValue) && (data.stakeDuration.split(' ')[0] == 12)
                                          ? <li><span>Minimum Guarantee</span><strong>₹{Number(data.initialStakeValue) + Number(data.initialStakeValue / 100 * Number(50))} </strong> </li>
                                          : data.stakeDuration.split(' ')[0] == 24

                                  }

                                  {
                                    (data.stakeDuration.split(' ')[0] == 3)
                                      ? <li className='higlight'>Minimum Guarantee 12% Amount Worth Coin</li>
                                      : (data.stakeDuration.split(' ')[0] == 6)
                                        ? <li className='higlight'>Minimum Guarantee 24% Amount Worth Coin</li>
                                        : (data.stakeDuration.split(' ')[0] == 12)
                                          ? <li className='higlight'>Minimum Guarantee 50% Amount Worth Coin</li>
                                          : data.stakeDuration.split(' ')[0] == 24

                                  }

                                  {/* <li><span></span><strong className='gr' onClick={() => handleReadMoreClick(index)}>Read More</strong></li> */}
                                </ul>
                                <OverlayTrigger
                                  placement="bottom-end"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}

                                >
                                  <span className='infor'><img src='images/information.svg' alt='information' draggable="false" /></span>
                                </OverlayTrigger>


                                <div className="stak_box stak_boxcon">
                                  <ul>


                                  </ul>
                                  <ProgressBar
                                    completed={progressPercentages[index] || 0}
                                    customLabel={data.stakeDuration}
                                    bgColor="#4CAF50"
                                    baseBgColor="#DADADA"
                                    labelColor="#4CAF50"
                                    height="16px"
                                    borderRadius="4px"
                                  />
                                  <p>{`${progressPercentages[index] ? progressPercentages[index].toFixed(2) : "0"}%`}</p>
                                  <p>{data.stakeDuration}</p>
                                </div>

                                {progressPercentages[index] === 100 &&
                                  (data.stakeStatus === "INPROGRESS" ? (
                                    <button onClick={() => handleRedeem(data)} className='huv btn w-100'>Redeem</button>
                                  ) : (
                                    <p className="cli">CLAIMED</p>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                    </Tab>
                    <Tab eventKey="year" title="1 Year">

                    </Tab>
                    <Tab eventKey="Years" title="2 Years">

                    </Tab>
                  </Tabs>
                  <div className='search_bx inptbx'>
                    <input type='text' placeholder='search'></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>


  )
}

export default StakeHistory