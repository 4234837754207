import React, { useState, useEffect } from "react";
import Header from "../../header/Header";
import "./referral.css";
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";

import { Apiservices } from '../../../ApiServices/ApiServices';

import { postData, getData, putData, deleteData, postGetData } from '../../../AxiosInstance/AxiosInstance';

import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";

import Loader from 'react-js-loader';

import { toast } from "react-toastify";

const Referral = () => {
  const navigate = useNavigate()


  const [loading, setLoading] = useState(false);


  const [formData, setFormData] = useState({
    email: '',
    referralID: '',
    name: '',
    mobineNumber: '',
    address: '',
    dob: '',
    city: '',
    state: '',
    country: '',
  });

  const [refHistory, setRefHistory] = useState([]);



  const getUser = async () => {
    setLoading(true);

    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);

    // console.log("-----------> ", response.user);

    if (response.status) {

      setFormData((prevData) => ({
        ...prevData,
        email: response.user.email,
        referralID: response.user.yourreferralCode,
        name: response.user.name,
        mobineNumber: response.user.mobileNumber,
        address: response.user.address,
        dob: response.user.dob,
        city: response.user.city,
        state: response.user.state,
        country: response.user.country,
      }));

      setLoading(false);
    }
    else {
      setLoading(false);

    }

  }

  useCustomEffect(getUser);


  useEffect(() => {
    const reffLevel = async () => {
      // Assuming postGetData is an async function that fetches data
      const response = await postGetData(Apiservices.USER_REFERRAL_LEVEL_API);
      setRefHistory(response.referralLevels);
      // console.log("Result === >", response.referralLevels);
    };

    reffLevel();
  }, []);


  // const earnBalance = async () => {

  //   // const response = await postGetData(Apiservices.USER_EARN_BANALNCE_API);
  //   const response = await postGetData(Apiservices.USER_REFERRAL_LEVEL_API);


  //   setRefHistory(response.referralLevels)

  //   console.log("Result === > ", response );
  // }

  // useCustomEffect(earnBalance);



  const handleReferrralCopyClick = (copy) => {
    const tempInput = document.createElement("input");
    tempInput.value = copy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toast.success("Copied successfully");
    toast.dismiss();
  };



  return (
    <>
      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />

      ) : (

        <>
          <Header />
          <div className="main-content">
            <div className="referal_cont">
              <div className="container">
                <div className="referal_conts">
                  <div className="referal_lft">
                    <h1>Canopro’s referral program</h1>
                    <p>
                      Refer any of your friends and get a certain percentage as a
                      reward for each referral!
                    </p>
                    <ul>
                      <li>
                        <img src="images/star_icon.svg" alt="staring" draggable='false'></img>
                        <span>Earn hefty rewards for each referral</span>
                      </li>
                      <li>
                        <img src="images/star_icon.svg" alt="staring" draggable='false'></img>
                        <span> Payment within every day, 24/7 </span>
                      </li>
                      <li>
                        <img src="images/star_icon.svg" alt="staring" draggable='false'></img>
                        <span>Boundless rewards and perks</span>
                      </li>
                    </ul>
                    <ul className="refer_cont">
                      <li>
                        <span className="title">Referral Code : </span>
                        <p>
                          {" "}
                          <strong>{formData.referralID}</strong>{" "}
                          <span onClick={() => handleReferrralCopyClick(formData.referralID)}>
                            <img src="images/copyicon.svg" alt="copy_icon" draggable='false'></img>
                          </span>
                        </p>
                      </li>
                      <li>
                        <span className="title">Referral Link : </span>
                        <p>
                          {" "}
                          <strong>https://canopro.com/auth/{formData.referralID}</strong>{" "}
                          {/* <strong>http://localhost:3000/auth/{formData.referralID}</strong>{" "} */}


                          <span>
                            <img onClick={() => handleReferrralCopyClick(`https://canopro.com/auth/${formData.referralID}`)} src="images/copyicon.svg" alt="copy_icon" draggable='false'></img>
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="referal_rgt">
                    <img src="images/referral_im.png" alt="referral image" draggable='false'></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="refer_pro">
              <div className="container">
                <h2>How does our referral program work?</h2>
                <div className="refer">
                  <div className="refer_bx">
                    <img src="images/referaa.png" alt="refer" draggable='false'></img>
                    <h3>Get Canopro’s referral link</h3>
                    <p>
                      By registering as a user in Canopro, you’ll receive a unique
                      referral link. You’ll get some rewards if any other user
                      accesses your referral code.
                    </p>
                  </div>
                  <div className="refer_bx">
                    <img src="images/referbbb.svg" alt="refer" draggable='false'></img>
                    <h3>Share the link</h3>
                    <p>
                      Promote your referral link on various social media platforms like Twitter, Instagram, etc. The more you promote, the more you get rewards in return.
                    </p>
                  </div>
                  <div className="refer_bx">
                    <img src="images/refercc.png" alt="refer" draggable='false'></img>
                    <h3>Earn your rewards</h3>
                    <p>
                      You can earn rewards anonymously for every click the user accesses with your referral link. Once the transaction is over, the rewards will come automatically.
                    </p>
                  </div>
                </div>
                <button type="button" className="btns butc">
                  View the rules
                </button>
              </div>
            </div>
            <div className="refer_pro other_ref">
              <div className="container">
                <h2>Other benefits</h2>
                <div className="refer">
                  <div className="refer_bx">
                    <span><img src="images/bonus.svg" alt="bonus" draggable='false'></img></span>
                    <strong>KYC welcome bonus</strong>
                    <p>A new user will receive 10,000 Ubit tokens in their wallets once after the completion of KYC itself. Earn extra rewards by multiple referrals of our Canopro Exchange</p>
                  </div>
                  <div className="refer_bx">
                    <span><img src="images/dividnd.svg" alt="dividnd" draggable='false'></img></span>
                    <strong>Industry dividend allotment</strong>
                    <p>We always welcome the new and ideal suggestions of our users for developing our exchange. Be a part of Canopro Exchange and we shall earn together!</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='depos_his referhis'>
              <div className="container">
                <div className='depos_conts'>
                  <strong>Recent Deposits</strong>
                  {/* <a>View All</a> */}
                </div>
                <div className='table-responsive con'>
                  <table>
                    <thead>
                      <th>S.no</th>
                      <th>Level</th>
                      <th>Reward</th>
                      <th>Reward Percentage</th>
                      <th>Staking Referral Code</th>
                      <th>Child Name</th>
                      <th>Sub Child Name</th>
                      <th>Currency Name</th>
                      <th>Created At</th>
                    </thead>
                    <tbody>
                      {refHistory.length === 0 ? (
                        <tr>
                          <td colSpan="9" style={{ textAlign: 'center' }}>No Record Found</td>
                        </tr>
                      ) : (
                        refHistory.slice(-3).map((level, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{level.level}</td>
                            <td>{level.reward}</td>
                            <td>{level.rewardPercentage}</td>
                            <td>{level.staking_ReferralCode}</td>
                            <td>{level.child_name}</td>
                            <td>{level.sub_child_name ? level.sub_child_name : "---"}</td>
                            <td>{level.cur_name}</td>
                            <td>{new Date(level.createdAt).toLocaleString()}</td>
                          </tr>
                        ))
                      )}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>

  );
};

export default Referral;
