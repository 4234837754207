import React, { useState, useEffect } from "react";
import Header from "../../header/Header";
import "./Verify.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";
import QRCode from 'qrcode';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { postData, getData, putData, deleteData, postGetData } from '../../../AxiosInstance/AxiosInstance';
// import {
//   GetCountries,
//   GetState,
//   GetCity,
// } from "react-country-state-city";

import { Country, State, City } from "country-state-city";
import Select from "react-select";

import { profileSchema, kycSchema, panSchema } from '../../../Yup'
import { toast } from "react-toastify";
import validator from 'aadhaar-validator';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase.config";

import { useNavigate, useLocation } from 'react-router-dom';

import Loader from 'react-js-loader';


const Verification = () => {
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false);
  const [showNext, setNext] = useState(false);
  const [showLast, setLast] = useState(false);
  const [showNan, setNan] = useState(false);
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [hidePhone, setHidePhone] = useState(true);

  const [hideButton, setHideButton] = useState(true);




  const modClick = () => {
    setShowModal(true)
    // console.log("modal")
  }
  const modClose = () => {
    setShowModal(false)
    setNext(true)
  }
  const modsClose = () => {
    setShowModal(false);
    setStates({
      state1: false,
      state2: true,
      state3: false,
      state4: false,
      state5: false,
      state6: false,
    });

    // setCountdown(1);
    // handleShow();
  }
  const modsbClose = () => {

    // console.log("----");
    setTfa('');
    setNext(false);
    setStates({
      state1: false,
      state2: true,
      state3: false,
      state4: false,
      state5: false,
      state6: false,
    });

    // setCountdown(1);
    // handleShow();
  }
  const modbClose = () => {
    setNext(false)
    setLast(true)
  }
  const modcClose = () => {
    setLast(false)

    handleShow();

    // setState1(false);
    // setState2(true)
  }
  const moddOpen = async () => {


    setNan(true)

  }



  const handleSkip = async () => {

    setLoading(true);
    const response = await postData(Apiservices.USER_SKIP_API, { tfaSkip: true })

    if (response.status) {
      setNan(false);
      getQr();
      setLoading(false);
    }
  }

  const moddClose = async () => {


    setNan(false);
    setCallCount(0);
    handleOk();

  }
  const moddsClose = () => {
    setNan(false);
    setCallCount(0);
    handleOk();
  }



  //muthu

  const [states, setStates] = useState({
    state1: true,
    state2: false,
    state3: false,
    state4: false,
    state5: false,
    state6: false,
  });

  const [kycdoc, setKycDoc] = useState("Proof");

  const [imageqr, setQrImageUrl] = useState('');
  const [qrAddress, setQrAddress] = useState('');




  const [tfa, setTfa] = useState("");

  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    address: '',
    country: '',
    state: '',
    city: '',
  });

  const [kycData, setKycData] = useState({
    aadharNumber: "",
    reAadharNumber: "",
  })

  const [panData, setPanData] = useState({
    panNumber: "",
    rePanNumber: "",
  });

  const [aadharError, setAddharErrors] = useState("")

  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);


  const [profileImage2, setProfileImage2] = useState(null);
  const [profileImage3, setProfileImage3] = useState(null);
  const [profileImage4, setProfileImage4] = useState(null);

  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const [imageError, setImageError] = useState({
    proffront: "",
    profBack: "",
    profSelfi: "",
  });

  const [imageError2, setImageError2] = useState({
    profBack: "",
    profSelfi: "",
  });


  const [errors, setErrors] = useState({});


  const [countdown, setCountdown] = useState(60);

  // const [countriesList, setCountriesList] = useState([]);
  // const [stateList, setStateList] = useState([]);
  // const [cityList, setCityList] = useState([]);

  // const [locationIds, setLocationIds] = useState({
  //   countryId: '',
  //   stateId: '',
  //   cityId: ''
  // });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [callCount, setCallCount] = useState(0);


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown > 0) {
        setCountdown(prevCountdown => prevCountdown - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  // useEffect(() => {
  //   GetCountries().then((result) => {
  //     // console.log('Fetched countries:', result);
  //     setCountriesList(result);
  //   });
  // }, []);




  // useEffect(() => {
  //   if (selectedCountry) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       country: selectedCountry.name,
  //       state: "",
  //       city: "",
  //     }));
  //     setSelectedState(null); // reset state when country changes
  //     setSelectedCity(null); // reset city when country changes
  //   }
  // }, [selectedCountry]);

  // useEffect(() => {
  //   if (selectedState) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       state: selectedState.name,
  //       city: "",
  //     }));
  //     setSelectedCity(null); // reset city when state changes
  //   }
  // }, [selectedState]);

  // useEffect(() => {
  //   if (selectedCity) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       city: selectedCity.name,
  //     }));
  //   }
  // }, [selectedCity]);


  // useEffect(() => {
  //   if (selectedCountry) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       country: selectedCountry.name,
  //       state: "",
  //       city: "",
  //     }));
  //     setSelectedState(null); // reset state when country changes
  //     setSelectedCity(null); // reset city when country changes
  //   }
  // }, [selectedCountry]);

  // useEffect(() => {
  //   if (selectedState) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       state: selectedState.name,
  //       city: "",
  //     }));
  //     setSelectedCity(null); // reset city when state changes
  //   }
  // }, [selectedState]);

  // useEffect(() => {
  //   if (selectedCity) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       city: selectedCity.name,
  //     }));
  //   }
  // }, [selectedCity]);

  useEffect(() => {
    if (selectedCountry) {
      const country = Country.getAllCountries().find(c => c.isoCode === selectedCountry);
      setFormData((prevData) => ({
        ...prevData,
        country: country.name,
        state: "",
        city: "",
      }));
      setSelectedState(""); // reset state when country changes
      setSelectedCity(""); // reset city when country changes
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const state = State.getStatesOfCountry(selectedCountry).find(s => s.isoCode === selectedState);
      setFormData((prevData) => ({
        ...prevData,
        state: state.name,
        city: "",
      }));
      setSelectedCity(""); // reset city when state changes
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      setFormData((prevData) => ({
        ...prevData,
        city: selectedCity,
      }));
    }
  }, [selectedCity]);


  const getProfile = async () => {

    setLoading(true);

    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);

    if (response.status) {

      setLoading(false);
      setCallCount(1);

      if (response.user.isProfileUpdated) {
        setLoading(false);

        setCallCount(3);

        if (response.user.kycDetails != undefined) {
          const kycStatus = response.user.kycDetails.kycStatus;

          if (kycStatus == 1 || kycStatus == 2) {

            navigate('/account-details?tab=profile');

            setLoading(false);


          }
          else {
            handleShow();

          }
        }
        else {
          handleShow();

        }

      }
      else {
        handleShow();
      }
    }
    else {
      setLoading(false);

      console.error('User data not found in the response.');
    }

  }


  const handleKycSkip = async () => {

    setLoading(true);

    const response = await postData(Apiservices.USER_SKIP_API, { kycSkip: true })

    setLoading(false);

    navigate('/');
  }


  const getQr = async () => {

    setLoading(true);


    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);

    // console.log("Response === > ", response);

    if (response.status) {

      if (response.user.mobileNumber != '') {


        setLoading(false);

        setCallCount(0);

        if (!response.user.tfaSkip) {
          if (response.user.tfStatus) {
            setLoading(false);
            setCallCount(1);

            // console.log(response.user.kycSkip);

            if (!response.user.kycSkip) {
              if (response.user.isProfileUpdated) {
                setLoading(false);

                setCallCount(3);



                if (response.user.kycDetails != undefined) {
                  const kycStatus = response.user.kycDetails.kycStatus;

                  if (kycStatus == 1 || kycStatus == 2) {

                    navigate('/account-details?tab=profile');

                    setLoading(false);


                  }
                  else {
                    handleShow();

                  }
                }
                else {
                  handleShow();

                }


              }
              else {
                handleShow();
              }
            }
            else {
              navigate('/account-details?tab=profile');
            }
          }
          else {
            handleShow();
          }
        }
        else if (!response.user.kycSkip) {
          setLoading(false);
          setCallCount(1);
          if (response.user.isProfileUpdated) {
            setLoading(false);

            setCallCount(3);



            if (response.user.kycDetails != undefined) {
              const kycStatus = response.user.kycDetails.kycStatus;

              if (kycStatus == 1 || kycStatus == 2) {

                navigate('/account-details?tab=profile');

                setLoading(false);


              }
              else {
                handleShow();

              }
            }
            else {
              handleShow();

            }


          }
          else {
            setLoading(false);
            handleShow();
          }
        }
        else {
          setLoading(false);
          navigate('/account-details?tab=profile');
          // setNan(false);
          // setCallCount(1);
          // handleOk();
        }




      }

      else {
        setStates({
          state1: true,
          state2: false,
          state3: false,
          state4: false,
          state5: false,
          state6: false,
        })

      }







      setQrImageUrl(response.user.otpauth_url);
      setQrAddress(response.user.tfaSecret)
      if (!response.tfStatus) {
        setLoading(false);

        generateQRCode(response.user.otpauth_url);

      }


    }
    else {
      setLoading(false);

      console.error('User data not found in the response.');
    }
  }

  const generateQRCode = otpUrl => {
    QRCode.toDataURL(otpUrl)
      .then(url => {
        setQrImageUrl(url);
      })
      .catch(error => {
        console.error('Error generating QR code:', error);
      });
  };

  useCustomEffect(getQr);
  // const handleShow = () => {
  //   setStates((prevState) => ({
  //     ...prevState,
  //     state2: false,
  //     state3: true,
  //   }));
  // }
  const [selectedKYC, setSelectedKYC] = useState('');
  const handleOkaySelect = () => {


    if (selectedKYC === '' || selectedKYC === 'Type of KYC') {
      setErrors({ ...errors, userKycSelect: "Please select a valid KYC type." });

    }
    else {
      // getProfile();
      handleShow()
    }

  }
  const handleShow = () => {

    // setErrors({ ...errors, userKycSelect: "" });
    setCallCount((prevCount) => prevCount + 1);
  };

  const handleUserSelectChange = (e) => {
    setSelectedKYC(e.target.value);

    setErrors({ ...errors, userKycSelect: "" });



    // console.log(selectedKYC, "!!!!!!!");
  };

  useEffect(() => {
    const updateStates = (count) => {
      switch (count) {
        case 1:
          return {
            state1: false,
            state2: true,
            state3: false,
            state4: false,
            state5: false,
            state6: false,
          };
        case 2:
          return {
            state1: false,
            state2: false,
            state3: true,
            state4: false,
            state5: false,
            state6: false,
          };
        case 3:
          return {
            state1: false,
            state2: false,
            state3: false,
            state4: true,
            state5: false,
            state6: false,
          };
        case 4:
          return {
            state1: false,
            state2: false,
            state3: false,
            state4: false,
            state5: true,
            state6: false,
          };
        case 5:
          return {
            state1: false,
            state2: false,
            state3: false,
            state4: false,
            state5: false,
            state6: true,
          };
        default:
          return null;  // Return null if no update is needed
      }
    };

    const newStates = updateStates(callCount);
    if (newStates) {
      setStates(newStates);
    }
  }, [callCount]);


  const handleTfaChange = e => {
    const { name, value } = e.target;

    const filteredValue = value.replace(/[^0-9]/g, "")

    setTfa(filteredValue);

  };

  const handletfa = async () => {


    // console.log("TFA === > ", tfa);


    if (tfa == "" || tfa == undefined || tfa == null) {

      setErrors({ ...errors, tfa: "2FA is Required" });

    }
    else if (tfa.length == 6) {

      setErrors({ ...errors, tfa: "" });

      const data = {
        tfaCode: tfa,
        tfStatus: true
      }
      getProfile();

      setLoading(true);

      const response = await postData(Apiservices.USER_TFA_REST_API, data);

      // console.log("Response  ----- > ", response);

      if (response.status) {
        setNext(false);
        setTfa("");
        setLoading(false);

      }
      else {
        // setTfa("");
        // setTfa('');
        setErrors({ ...errors, tfa: "Invalid 2FA Code" });
        setLoading(false);

      }

    }
    else {
      setErrors({ ...errors, tfa: "2FA Should be 6 Digit" });
    }




  }


  const handleChange = (e) => {
    const { name, value } = e.target;

    let filteredValue = value;

    // if (name !== "firstName") {
    //   filteredValue = value.replace(/[^a-zA-Z]/g, "");
    // }
    // if (name !== "middleName") {
    //   filteredValue = value.replace(/[^a-zA-Z]/g, "");
    // }
    // if (name !== "lastName") {
    //   filteredValue = value.replace(/[^a-zA-Z]/g, "");
    // }
    // if (name !== "address") {
    //   filteredValue = value.replace(/\s/g, "");
    // }

    // if (name === "firstName") {
    //   filteredValue = value.trimStart();
    // }
    // if (name === "middleName") {
    //   filteredValue = value.trimStart();
    // }
    // if (name === "lastName") {
    //   filteredValue = value.trimStart();
    // }



    if (name === "firstName" || name === "middleName" || name === "lastName") {
      // Remove non-letter characters and trim leading spaces
      filteredValue = value.replace(/[^a-zA-Z\s]/g, "").trimStart();
    }


    if (name === "address") {
      filteredValue = value.trimStart();
    }

    // Assuming 'name' and 'value' are already defined

    // if (name === "dob") {
    //   // Assuming value contains the date string
    //   filteredValue = value.replace(/(\d{2})(\d{2})(\d{4})/, "$1-$2-$3");
    // }


    if (name === "dob") {
      // Handle backspace correctly
      if (value.length < formData.dob.length) {
        // If the user is deleting, allow it to happen normally
        filteredValue = value;
      } else {
        // Remove all non-numeric characters first
        filteredValue = value.replace(/[^\d]/g, "");
  
        // Add dashes for date formatting
        if (filteredValue.length >= 2) {
          filteredValue = filteredValue.slice(0, 2) + '-' + filteredValue.slice(2);
        }
        if (filteredValue.length >= 5) {
          filteredValue = filteredValue.slice(0, 5) + '-' + filteredValue.slice(5);
        }
        if (filteredValue.length > 10) {
          filteredValue = filteredValue.slice(0, 10); // Ensure max length of 10
        }
      }
    }




    // console.log(filteredValue)


    setFormData({ ...formData, [name]: filteredValue });

    profileSchema
      .validateAt(name, { [name]: filteredValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    profileSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handleKYCBlur = (e) => {
    const { name, value } = e.target;

    kycSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handlePANBlur = (e) => {
    const { name, value } = e.target;

    panSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };


  const handlePost = async (formData) => {
    // console.log("========== > ", formData);


    // const formDataToSend = new FormData();

    // formDataToSend.append("name", formData.firstName);
    // formDataToSend.append("name", formData.middleName);
    // formDataToSend.append("name", formData.lastName);
    // formDataToSend.append("mobileNumber", formData.dob);
    // formDataToSend.append("email", formData.address);
    // formDataToSend.append("city", formData.city);
    // formDataToSend.append("state", formData.state);
    // formDataToSend.append("country", formData.country);
    // formDataToSend.append("image", selectedFile);

    // console.log("========= > ", formData);

    setLoading(true);


    const result = await postData(Apiservices.USER_PROFILE_REST_API, formData);

    if (result.status) {

      // navigate('/signup');

      // console.log(result);

      toast.success(result.message)


      // setStates({
      //   state1: false,
      //   state2: false,
      //   state3: false,
      //   state4: false,
      //   state5: true,
      //   state6: false,
      // });

      // setCountdown();
      handleShow();
      setLoading(false);


      setFormData({
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        dob: '',
        address: '',
        country: '',
        state: '',
        city: '',
      });



    }
    else {

      setFormData({
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        address: '',
        country: '',
        state: '',
        city: '',
      });

      setSelectedFile('');
      setProfileImage('');
      // toast.error(result.message);
      setLoading(true);
      toast.error(result.message)
    }

    // console.log(result, "RESSSS");

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log("FormData", formData);

    // console.log(locationIds)


    const emptyFields = {};

    const labelMapping = {
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      dob: 'DOB',
      address: 'Address',
      country: 'Country',
      state: 'State',
    };

    // console.log()


    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "middleName" && key !== 'lastName' && key !== 'city') {
        if (value === "" || value === undefined || value === null) {
          const label = labelMapping[key] || key;
          emptyFields[key] = `${label} is Required`;
        }
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })

      return Promise.resolve();
    }



    profileSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        return handlePost(formData);
      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });
  };

  const handleFileChange = (event, setSelectedFile, setProfileImage, imageErrorStateToUpdate) => {
    const selectedFile = event.target.files[0]; // Get the selected file

    if (selectedFile) {
      // Set the selected file to the state

      if (selectedFile.size > 4000 && selectedFile.size < 200000) {

        setImageError(prevFormData => ({
          ...prevFormData,
          [imageErrorStateToUpdate]: ""
        }));

        setSelectedFile(selectedFile);

        // Create URL for the file and set it to profileImage state
        setProfileImage(URL.createObjectURL(selectedFile));


        const allowedTypes = ['image/jpeg', 'image/png'];

        if (!allowedTypes.includes(selectedFile.type)) {
          toast.error("Upload valid image format");
          // Reset the input field
          setProfileImage();
          // event.target.form.reset();
        }
      }
      else {
        // toast.error("We Accept Only Image");
        setImageError(prevFormData => ({
          ...prevFormData,
          [imageErrorStateToUpdate]: "Image Size 40 - 200 kb"
        }));
      }


    }
    else {
      console.error("No file selected");
      setImageError((prevData) => ({
        ...prevData,
        proffront: "Image Required"
      }));
    }
  };

  const handleKycChange = (e) => {
    const { name, value } = e.target;

    const filteredValue = value.replace(/[^0-9]/g, "");

    setKycData({ ...kycData, [name]: filteredValue });

    const isValid = validator.isValidNumber(filteredValue);

    // console.log("isValid ", isValid, name);



    if (name == "aadharNumber") {

      // console.log("isValid ", isValid, name);

      if (isValid) {
        setErrors({ ...errors, aadharNumber: "" });
      }
      else {
        setErrors({ ...errors, aadharNumber: "Invalid Aadhaar" });
      }
    }

    kycSchema
      .validateAt(name, { [name]: value })
      .then(() => {

        if (isValid) {
          setErrors({ ...errors, [name]: "" });
        }
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });

  };

  const handlePanChange = async (e) => {
    const { name, value } = e.target;

    const filteredValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");

    setPanData({ ...panData, [name]: filteredValue });

    try {
      await panSchema.validateAt(name, { [name]: filteredValue });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
  };


  const handleOk = () => {
    // setStates({
    //   state1: false,
    //   state2: false,
    //   state3: false,
    //   state4: false,
    //   state5: false,
    //   state6: true,
    // });
    setCountdown(5);
    handleShow();
  }


  const handleSelect = () => {


    if (selectedKYC === '' || selectedKYC === 'Type of KYC') {


      setErrors({ ...errors, userKycSelect: "Please select a valid KYC type." });


    } else {
      setErrors({ ...errors, userKycSelect: "" });
      return true

    }

  }
  const handlePanSubmit = () => {
    if (!handleSelect()) {
      return;
    }


    // selectedFile2.size > 4000 && selectedFile2.size < 200000
    const emptyFields = {};

    const labelMapping = {
      panNumber: "PAN Number",
      rePanNumber: "Re-PAN Number",
    };

    if (profileImage) {
      setImageError(prevFormData => ({
        ...prevFormData,
        proffront: ""
      }));

    }
    else {
      setImageError(prevFormData => ({
        ...prevFormData,
        proffront: "Pan image is required"
      }));
    }

    // console.log("===== ", imageError);


    Object.entries(panData).forEach(([key, value]) => {
      if (value === "" || value === undefined || value === null) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })

      return Promise.resolve();
    }





    panSchema
      .validate(panData, { abortEarly: false })
      .then(() => {
        if (panData.panNumber === panData.rePanNumber) {

          if (profileImage) {
            setErrors({
              panNumber: "",
              rePanNumber: "",
            });
            setImageError(prevFormData => ({
              ...prevFormData,
              proffront: ""
            }));
            handleOk();
          }
        }
        else {
          setErrors({ ...errors, rePanNumber: "Re-Pan Number Should be Same" });
        }
        // Call handleOk if validation passes
      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });


  };


  const handlefinalSubmit = async () => {

    // console.log("===== ", errors.aadharNumber);

    if (profileImage2 && profileImage3 && profileImage4) {

      setImageError(prevFormData => ({
        ...prevFormData,
        profBack: "",
        profSelfi: "",
        aadhaar: ""
      }));

    }
    else if (profileImage2) {
      setImageError(prevFormData => ({
        ...prevFormData,
        profBack: "",
      }));
    }
    else if (profileImage3) {
      setImageError(prevFormData => ({
        ...prevFormData,
        profSelfi: ""
      }));
    }
    else if (profileImage4) {
      setImageError(prevFormData => ({
        ...prevFormData,
        aadhaar: ""
      }));
    }
    else {
      setImageError(prevFormData => ({
        ...prevFormData,
        profBack: "Aadhaar Front Image Required",
        profSelfi: "Aadhaar Back Image Required",
        aadhaar: "Profile Selfie Image Required"
      }));
    }
    const emptyFields = {};
    const labelMapping = {
      aadharNumber: "Aadhaar Number",
      reAadharNumber: "Re Aadhaar Number",
    };

    Object.entries(kycData).forEach(([key, value]) => {
      if (value === "" || value === undefined || value === null) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })
      return Promise.resolve();
    }
    const aadhar = kycData.aadharNumber;

    const isValid = validator.isValidNumber(aadhar);

    // console.log("..... >> ", isValid, aadhar)

    kycSchema
      .validate(kycData, { abortEarly: false })
      .then(() => {

        if (isValid) {
          if (kycData.aadharNumber === kycData.reAadharNumber) {

            if (profileImage2 && profileImage3 && profileImage4) {
              setErrors({
                aadharNumber: "",
                reAadharNumber: "",
              });
              handleaadharOk(); // Call handleOk if validation passes
            }
          }
          else {
            setErrors({
              ...errors,
              reAadharNumber: "Re-Aadhaar Number Should be Same"
            });
          }
        }
        else {
          setErrors({
            ...errors,
            aadharNumber: "Invalid Aadhaar",
            reAadharNumber: "Invalid Aadhaar"
          });
        }

      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });

  }


  const handleaadharOk = async () => {
    const formDataToSend = new FormData();


    formDataToSend.append("aadharNo", kycData.aadharNumber);
    formDataToSend.append("panNo", panData.panNumber);
    formDataToSend.append("type", selectedKYC);

    formDataToSend.append("profFront", selectedFile);
    formDataToSend.append("profBack", selectedFile2);
    formDataToSend.append("userSelfi", selectedFile3);
    formDataToSend.append("aadhaar", selectedFile4);

    if (kycData.aadharNumber === kycData.reAadharNumber) {

      setLoading(true);

      const result = await postData(Apiservices.USER_KYC_REST_API, formDataToSend);
      // console.log("Result ==== > ", result);

      if (result.status) {

        toast.success(result.message);
        navigate('/account-details?tab=profile');
        setKycData({
          aadharNumber: "",
          reAadharNumber: ""
        });
        setPanData({
          panNumber: "",
          rePanNumber: ""
        })
        setSelectedFile('');
        setSelectedFile2('');
        setSelectedFile3('');
        setSelectedFile4('');
        setLoading(false);


      }
      else {
        setFormData({
          firstName: '',
          middleName: '',
          lastName: '',
          dob: '',
          address: '',
          country: '',
          state: '',
          city: '',
        })
        toast.error(result.message);
        setKycData({
          aadharNumber: "",
          reAadharNumber: ""
        });
        setPanData({
          panNumber: "",
          rePanNumber: ""
        })
        setSelectedFile('');
        setSelectedFile2('');
        setSelectedFile3('');
        setSelectedFile4('');


        setStates({
          state1: false,
          state2: true,
          state3: false,
          state4: false,
          state5: false,
          state6: false,
        });

        setCountdown(1);

        setLoading(false);

      }

    }
    else {
      setErrors({ ...errors, reAadharNumber: "Re-Aadhaar Number Should be Same" });
    }
  }

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        },
        auth
      );
    }
  }

  function onSignup() {

    setHideButton(false);


    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;
    // console.log(formatPh,"!!!!!!!!!!!!!!");

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {


        window.confirmationResult = confirmationResult;
        if (confirmationResult) {
          toast.success("OTP Sent SuccessFully")
          // console.log(confirmationResult, "RESULE");
          setLoading(false);
          setHidePhone(false)
          setCountdown(60)

        } else {
          // console.log(confirmationResult, "RESULE");
          toast.error("Please Enter Correct Number")
          setLoading(false);
          setHidePhone(true)

        }
        // setShowOTP(true)
      })
      .catch((error) => {
        console.log(error);


      });
  }

  function onOTPVerify(sanitizedOtp) {
    window.confirmationResult
      .confirm(sanitizedOtp)
      .then((res) => {
        if (res) {


          // console.log(res);
          handlePhoneAPI()
          setLoading(false);
          toast.success("OTP Verified  SuccessFully")
        } else {

          setLoading(false);
          toast.error("Please Enter correct OTP")
        }

      })
      .catch((err) => {
        console.log(err);

      });
  }

  //   const handleOTPChange = (e) => {
  //     const value =e.target.value
  //     setOtp(value)



  // };

  const [getotp, setGetOtp] = useState("");

  const handleOtpChange = (e) => {
    const otpValue = e.target.value;

    if (!isNaN(otpValue)) {
      const sanitizedOtp = otpValue.replace(/\D/g, "");
      setOtp(sanitizedOtp);

      if (sanitizedOtp.length === 6) { // Check if the length is exactly 6
        setGetOtp(sanitizedOtp);
        setLoading(true);
        onOTPVerify(sanitizedOtp);
      } else {
        setErrors("Please enter a 6-digit code");
      }
    } else {
      setErrors("Numbers Only");
    }
  };
  const [phoneError, setPhoneError] = useState('')
  const handlePhoneAPI = async () => {
    if (ph.length !== 12) {
      // console.log(ph);
      setPhoneError("Mobile Number Must Be 10")
    } else {

      setLoading(true);



      const result = await postData(Apiservices.USER_MOBILE_VERIFY_REST_API, { mobileNumber: ph });

      if (result.status) {


        // console.log(result);

        toast.success(result.message)

        // setStates({
        //   state1: false,
        //   state2: true,
        //   state3: false,
        //   state4: false,
        //   state5: false,
        //   state6: false,
        // });
        setCountdown(1);
        handleShow();

        setLoading(false);


      }
      else {
        toast.error(result.message);
        setLoading(false);

      }
    }

    // console.log(result, "RESSSS");

  }
  // const [countdown, setCountdown] = useState(60);
  function startCountdown() {
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : prevCountdown));
    }, 1000);

    return () => clearInterval(intervalId);
  }

  useCustomEffect(() => startCountdown(setCountdown), []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'Aadhaar') {
      setKycDoc('Aadhaar');
    } else if (selectedValue === 'Driving Licence') {
      setKycDoc('Driving Licence');
    } else {
      setKycDoc('');
    }
  };




  const countries = Country.getAllCountries();
  const states1 = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
  const cities = selectedState ? City.getCitiesOfState(selectedCountry, selectedState) : [];


  const copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = qrAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success("Copied successfully");
    toast.dismiss()
  };


  return (

    <>
      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
      ) : (

        <>

          <div id="recaptcha-container"></div>

          <Modal
            show={showModal}

            className="sec_mod"
            backdrop="static"
            centered="bool"
          >
            <Modal.Header closeButton onClick={modsClose}>
              <Modal.Title>2FA Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa">
                <strong>Scan this QR Code</strong>
                <img src={imageqr} alt="dummy" draggable='false'></img>
                <p className="or">OR</p>
                <p>{qrAddress} <span><button onClick={copyToClipboard} className="btns">copy</button></span></p>
                <p className="info">
                  Download <span>Google Authenticator</span> or <span>Authy</span> To scan this code
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="btn btn-success"
                onClick={modClose}
                className="btns"
              >
                Next
              </Button>



            </Modal.Footer>
          </Modal>

          <Modal
            show={showNext}

            className="sec_mod"
            backdrop="static"
            centered="bool"
          >
            <Modal.Header closeButton onClick={modsbClose}>
              <Modal.Title >2FA Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa to_fas">
                <strong>Two-Factor Authentication</strong>
                <p>Enter 6 Digit Authentication Code</p>
                <div className="inptbx  ">

                  <input
                    type="text"
                    autoComplete="off"
                    name="tfa"
                    value={tfa}
                    onChange={handleTfaChange}
                    maxLength={6}
                  />

                  <h6 id="error">{errors.tfa}</h6>
                </div>

                <button type="button" className="btns" onClick={handletfa}>NEXT</button>
                {/* <p>Didn’t receive OTP yet? <br></br>
              Resend in <span>55 Seconds</span></p> */}

              </div>
            </Modal.Body>

          </Modal>


          <Modal
            show={showLast}

            className="sec_mod"
            backdrop="static"
            centered="bool"
          >
            <Modal.Header>
              <Modal.Title>2FA Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa to_fas">
                <strong>Security settings updated!</strong>
                <img src="images/tick.svg" alt="tick" draggable='false'></img>
                <p>Your 2FA Enabled</p>
                <button type="button" className="btns" onClick={modcClose}>OK</button>
              </div>
            </Modal.Body>

          </Modal>
          <Modal
            show={showNan}

            className="sec_mod non"
            backdrop="static"
            centered="bool"
          >
            <Modal.Header closeButton onClick={moddClose}>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa to_fas">

                <img src="images/excliam.svg" alt="tick" draggable='false'></img>
                <p>Are you sure you want to proceed without securing your account?</p>
                <button type="button" className="btns redc" onClick={handleSkip}>OK</button>
                <button type="button" className="btns gob" onClick={moddsClose}>NO, GO BACK</button>
              </div>
            </Modal.Body>

          </Modal>
          <Header />
          <div className="verify col-md-6 col-lg-4">
            <div className="veric">
            <ul className="hed_ver d-flex">
              <li>
                <span>
                  {states.state1 ?
                    <img src="images/rod.svg" alt="Canopro Exchange" draggable='false'></img>
                    :
                    <img src="images/complete.svg" alt="Canopro Exchange" draggable='false'></img>
                  }
                </span>
                <strong> MOBILE</strong>
              </li>
              <li>
                <span>
                  {states.state1 ?

                    ("")

                    :

                    (states.state2 ? <img src="images/rod.svg" alt="Canopro Exchange" draggable='false'></img> : <img src="images/complete.svg" alt="Canopro Exchange" draggable='false'></img>)
                  }
                  {/* // (states.state2 ? <img src="images/rod.svg" alt="Canopro Exchange"></img> : <img src="images/complete.svg" alt="Canopro Exchange"></img>)
            // :
            // (states.state6 ? <img src="images/rod.svg" alt="Canopro Exchange"></img> : "") */}
                </span>
                <strong>SECURITY</strong>
              </li>
              <li>
                <span>
                  {states.state1 ?
                    ("")
                    :
                    (states.state3 ?
                      <img src="images/rod.svg" alt="Canopro Exchange" draggable='false'></img>
                      :
                      (states.state4 ?
                        <img src="images/rod.svg" alt="Canopro Exchange" draggable='false'></img>
                        :
                        (states.state5 ?
                          <img src="images/rod.svg" alt="Canopro Exchange" draggable='false'></img>
                          :
                          (
                            states.state6 ?
                              <img src="images/rod.svg" alt="Canopro Exchange" draggable='false'></img>
                              :
                              ''
                          )
                        )
                      )
                    )
                  }
                </span>
                <strong>WELCOME</strong>
              </li>

            </ul>



            <div className="veri_blk">

              {states.state1 ?
                (
                  <div className="verimobile">
                    <strong>Mobile Verification</strong>
                    {hidePhone ? (


                      <>
                        <div className="inptbx mobile ">
                          <PhoneInput country={"in"} value={ph}
                            onChange={(value) => {
                              setPh(value);
                              setPhoneError('');
                            }} />

                          {/* <input type="text" placeholder="Mobile Number" name="email" /> */}
                        </div>
                        <p id="error">{phoneError}</p>
                        {hideButton ?
                          <button type='submit' className='col-4  btns' onClick={handlePhoneAPI}>Submit </button>
                          :
                          <>
                          </>
                        }

                      </>
                    ) : (
                      <>

                        <p className="eff">Enter the OTP sent to your mobile</p>
                        <div className="inptbx mobl ">
                          <input
                            value={otp}
                            onChange={handleOtpChange}
                            // onKeyDown={handleKeyDownForAccountNumber}
                            id="eye"
                            type="text"
                            placeholder="Enter OTP"
                            name="otp"
                            maxLength={6}
                          />
                          {countdown <= 0 ? (
                            <button
                              className='closeeye'
                              variant="secondary"
                              onClick={() => onSignup()}
                            >
                              Resend
                            </button>
                          ) : (
                            <p className="closeeye">{countdown}</p>
                          )}
                        </div>
                      </>
                    )}
                    {/* <button type='submit' className='col-6  btns' onClick={handleShow}>CREATE </button> */}
                    <p>Haven’t received the SMS? Please check that you’ve entered the right Mobile Number</p>
                  </div>
                )

                :

                (
                  <>
                  </>
                )

              }





              {states.state2 ?

                (
                  <div className="verimobile security">
                    <strong>Security</strong>
                    <div className="security_chek">
                      <label onClick={modClick}>
                        <input type="radio" name="security" id="auth" >

                        </input>
                        <ul className="chek_c">
                          <li><strong>Authenticator App</strong><span>Recommended</span></li>
                          <li><span>Highly Secure</span></li>
                        </ul>
                      </label>
                      {/* <label>
                        <input type="radio" name="security" id="mobile"></input>
                        <ul className="chek_c">
                          <li><strong>Mobile SMS</strong></li>
                          <li><span>Moderately Secure</span></li>
                        </ul>
                      </label> */}
                      <label onClick={moddOpen}>
                        <input type="radio" name="security" id="none"></input>
                        <ul className="chek_c">
                          <li><strong>None</strong></li>
                          <li><span>Not Secure</span></li>
                        </ul>
                      </label>
                    </div>
                  </div>
                )
                :

                (
                  <>
                  </>
                )
              }

              {states.state3 ?
                (
                  <div className="verimobile kyc">
                    <strong>Select your KYC type</strong>
                    <div className="drop_bxs">
                      {/* <select className="sel_cont" placeholder="Country Of Residence">
                    <option>Country Of Residence</option>
                    <option >india</option>
                    <option >USA</option>
                    <option>NETHARLAND</option>
                  </select> */}

                      {/* <select className="sel_cont" placeholder="Country Of Residence"
                        onChange={(e) => {
                          const country = countriesList[e.target.value];
                          setFormData({ ...formData, country: country.name });
                          setLocationIds(prevLocation => ({ ...prevLocation, countryId: country.id }));
                          GetState(country.id).then((result) => {
                            setStateList(result);
                          });
                        }}
                        value={locationIds.countryId}
                      >
                        {countriesList.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select> */}


                      {/* <Select
                        options={Country.getAllCountries()}
                        getOptionLabel={(options) => options.name}
                        getOptionValue={(options) => options.isoCode}
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                      /> */}

                      {/* <select
                        className="sel_cont"
                        value={selectedCountry}
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        placeholder="Country Of Residence"
                      >
                        <option value="">Country Of Residence</option>
                        {countries.map((country) => (
                          <option key={country.isoCode} value={country.isoCode}>
                            {country.name}
                          </option>
                        ))}
                      </select> */}


                      <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>
                    </div>
                    {/* <div className="drop_bx">
                      <select className="sel_cont" onChange={handleUserSelectChange}>


                        <option>Type of KYC</option>
                        <option >USER</option>
                        


                      </select>
                      <p id="error">{errors.userKycSelect}</p>
                      <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>
                    </div> */}
                    <div className="instruc">
                      <div className="with-out">
                        <strong>Without Kyc</strong>
                        <ul>
                          <li>Deposit Crypto</li>
                          <li>Deposit INR</li>
                          <li>Trade</li>
                          <li>P2P</li>
                          <li>Withdraw</li>
                        </ul>
                      </div>
                      <div className="with">
                        <strong>With Kyc</strong>
                        <ul>
                          <li>Deposit Crypto</li>
                          <li>Deposit INR</li>
                          <li>Trade</li>
                          <li>P2P</li>
                          <li>Withdraw</li>
                        </ul>
                      </div>
                    </div>
                    <ul className="buton">
                      <li><button type='submit' className='col-4  btns' onClick={handleShow} >OK </button></li>
                      <li><button type='submit' className='col-4  btns' onClick={handleKycSkip}>SKIP FOR NOW</button></li>
                      {/* <li><button type='submit' className='col-4  btns' onClick={() => navigate('/')}>SKIP FOR NOW</button></li> */}
                    </ul>
                  </div>
                )

                :

                (
                  <>
                  </>
                )
              }

              {states.state4 ?

                (
                  < div className="verimobile person_inf">
                    <strong>Personal Info</strong>


                    <div className="personal_frm">

                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder="First Name"
                          name="firstName"
                          value={formData.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <p id="error">{errors.firstName}</p>
                      </div>

         

                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <p id="error">{errors.lastName}</p>
                      </div>

                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder="Date of Birth (DD-MM-YYYY) "
                          name="dob"
                          value={formData.dob}
                          maxLength={10}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <p id="error">{errors.dob}</p>
                      </div>

                      <div className="inptbx  ">
                        <textarea placeholder="Address"
                          className="msg"
                          name="address"
                          value={formData.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >

                        </textarea>
                        <p id="error">{errors.address}</p>
                      </div>

                      <div className="drop_bx">
                        {/* <select className="sel_cont" placeholder="Country Of Residence"
                          onChange={(e) => {
                            const country = countriesList.find(item => item.id === parseInt(e.target.value));
                            setFormData({ ...formData, country: country.name });
                            setLocationIds(prevLocation => ({ ...prevLocation, countryId: country.id }));
                            GetState(country.id).then((result) => {
                              setStateList(result);
                            });
                          }}
                          value={locationIds.countryId}
                        >
                          <optgroup>
                            {countriesList.map((item) => (

                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>

                            ))}
                          </optgroup>
                        </select> */}

                        {/* <Select
                          options={Country.getAllCountries()}
                          getOptionLabel={(options) => options.name}
                          getOptionValue={(options) => options.isoCode}
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                        /> */}

                        <select
                          className="sel_cont"
                          value={selectedCountry}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value)

                            setErrors({ ...errors, country: "" });


                          }}
                          placeholder="Country Of Residence"
                        >
                          <option value="">Country Of Residence</option>
                          {countries.map((country) => (
                            <option key={country.isoCode} value={country.isoCode}>
                              {country.name}
                            </option>
                          ))}
                        </select>

                        <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>

                        <p id="error">{errors.country}</p>
                      </div>

                      <div className="drop_bx">
                        {/* <select className="sel_cont"
                          onChange={(e) => {
                            const stateIndex = e.target.value;
                            const state = stateList[stateIndex];
                            setFormData({ ...formData, state: state.name });
                            setErrors({ ...errors, state: "" });
                            setLocationIds(prevLocation => ({ ...prevLocation, stateId: state.id }));
                            GetCity(locationIds.countryId, state.id).then((result) => {
                              setCityList(result);
                            });
                          }}

                          value={locationIds.stateId}
                        >

                          <optgroup>
                            {stateList.map((item, index) => (

                              <option key={index} value={index}>
                                {item.name}
                              </option>

                            ))}
                          </optgroup>
                        </select> */}

                        {/* <Select
                          options={State.getStatesOfCountry(selectedCountry?.isoCode)}
                          getOptionLabel={(options) => options.name}
                          getOptionValue={(options) => options.isoCode}
                          value={selectedState}
                          onChange={setSelectedState}
                          isDisabled={!selectedCountry}
                        /> */}

                        <select
                          className="sel_cont"
                          value={selectedState}
                          onChange={(e) => {
                            setSelectedState(e.target.value)

                            setErrors({ ...errors, state: "" });


                          }}
                          disabled={!selectedCountry}
                          placeholder="State Of Residence"
                        >
                          <option value="">State Of Residence</option>
                          {states1.map((state) => (
                            <option key={state.isoCode} value={state.isoCode}>
                              {state.name}
                            </option>
                          ))}
                        </select>

                        <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>

                        <p id="error">{errors.state}</p>
                      </div>


                      <div className="drop_bx">

                        {/* <select className="sel_cont"
                          onChange={(e) => {
                            const selectedCityId = parseInt(e.target.value);

                            setFormData({ ...formData, city: e.target.options[e.target.selectedIndex].text });

                            setErrors({ ...errors, city: "" });

                            setLocationIds(prevLocation => ({ ...prevLocation, cityId: selectedCityId }));
                          }}
                          value={locationIds.cityId}
                        >
                          <optgroup>

                            {cityList.map((city) => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            ))}
                          </optgroup>

                        </select> */}


                        {/* <Select
                          options={City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
                          getOptionLabel={(options) => options.name}
                          getOptionValue={(options) => options.name}
                          value={selectedCity}
                          onChange={setSelectedCity}
                          isDisabled={!selectedState}
                        /> */}


                        <select
                          className="sel_cont"
                          value={selectedCity}
                          onChange={(e) => {
                            setSelectedCity(e.target.value)

                            setErrors({ ...errors, city: "" });


                          }}
                          disabled={!selectedState}
                          placeholder="City Of Residence"
                        >
                          <option value="">City Of Residence</option>
                          {cities.map((city) => (
                            <option key={city.name} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </select>

                        <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>

                        {/* <p id="error">{errors.city}</p> */}
                      </div>

                      <button type='submit' className='col-4  btns' onClick={handleSubmit} >SUBMIT </button>

                    </div>

                  </div>
                )

                :

                (
                  <>

                  </>
                )
              }

              {states.state5 ?
                (
                  <div className="verimobile person_inf">
                    <strong>KYC Info</strong>
                    <div className="pan_det">
                      <div className="drop_bx">
                        <select className="sel_cont" onChange={handleUserSelectChange}>


                          <option value={''}>Type of KYC</option>
                          <option value={'USER'} >USER</option>



                        </select>
                        <p id="error">{errors.userKycSelect}</p>
                        <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>
                      </div>
                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder="PAN NUMBER"
                          name="panNumber"
                          value={panData.panNumber}
                          onChange={handlePanChange}
                          onBlur={handlePANBlur}
                          maxLength={10}
                        />
                        <p id="error">{errors.panNumber}</p>
                      </div>

                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder="CONFIRM PAN NUMBER"
                          name="rePanNumber"
                          value={panData.rePanNumber}
                          onChange={handlePanChange}
                          onBlur={handlePANBlur}
                          maxLength={10}
                        />
                        <p id="error" className="pan">{errors.rePanNumber}</p>
                      </div>

                      <div className="upl">
                        <label>
                          <input name='proofFront' onChange={(event) => handleFileChange(event, setSelectedFile, setProfileImage, "proffront")} type='file'></input>
                          {profileImage ? <p><img src={profileImage} width={150} draggable='false'></img></p> : <p><img src="images/upl.svg" draggable='false'></img><span>Upload Pan Card</span></p>}
                        </label>
                        <p id="error" className="dummy">{imageError.proffront}</p>
                      </div>

                      <button type='submit' className='col-4  btns' onClick={handlePanSubmit} >SUBMIT </button>

                    </div>

                  </div>
                )

                :

                (
                  <>
                  </>
                )

              }
              {states.state6 ?
                (
                  <div className="verimobile person_inf">
                    <strong>Document Type</strong>
                    <div className="dr_conts">
                      <div className="drop_bx">
                        <select className="sel_cont" onChange={handleSelectChange} placeholder="Type of KYC" >
                          <option>Type of KYC</option>
                          <option value={"Aadhaar"} >Aadhaar</option>
                          {/* <option value={"Driving Licence"}>Driving Licence</option> */}

                        </select>
                        <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>
                      </div>
                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder={`${kycdoc} Number`}
                          name="aadharNumber"
                          maxLength={12}
                          value={kycData.aadharNumber}
                          onChange={handleKycChange}
                          onBlur={handleKYCBlur}

                        />
                        <p id="error">{errors.aadharNumber}</p>
                      </div>

                      <div className="inptbx  ">
                        <input type="text"
                          autoComplete='off'

                          placeholder={`Confirm ${kycdoc} Number`}
                          name="reAadharNumber"
                          value={kycData.reAadharNumber}
                          maxLength={12}
                          onChange={handleKycChange}
                          onBlur={handleKYCBlur}
                        />
                        <p id="error">{errors.reAadharNumber}</p>
                      </div>

                      <div className="upl">
                        <label>
                          <input name='proofBack' onChange={(event) => handleFileChange(event, setSelectedFile2, setProfileImage2, "profBack")} type='file'></input>
                          {profileImage2 ? <p><img src={profileImage2} width={150} draggable='false'></img></p> : <p><img src="images/upl.svg" draggable='false'></img><span>{`Upload ${kycdoc} Front Image`}</span></p>}
                        </label>
                        <p id="error">{imageError.profBack}</p>
                      </div>

                      <div className="upl">
                        <label>
                          <input name='userSelfi' onChange={(event) => handleFileChange(event, setSelectedFile3, setProfileImage3, "profSelfi")} type='file'></input>
                          {/* <input type="file" src={profileImage2}></input> */}
                          {profileImage3 ? <p><img src={profileImage3} width={150} draggable='false'></img></p> : <p><img src="images/upl.svg" draggable='false'></img><span>{`Upload ${kycdoc} Back Image`}</span></p>}
                        </label>
                        <p id="error">{imageError.profSelfi}</p>
                      </div>

                      <div className="upl">
                        <label>
                          <input name='aadhaar' onChange={(event) => handleFileChange(event, setSelectedFile4, setProfileImage4, "aadhaar")} type='file'></input>
                          {/* <input type="file" src={profileImage2}></input> */}
                          {profileImage4 ? <p><img src={profileImage4} width={150} draggable='false'></img></p> : <p><img src="images/upl.svg" draggable='false'></img><span>{`Upload User Selfie Image`}</span></p>}
                        </label>
                        <p id="error">{imageError.aadhaar}</p>
                      </div>

                      <button type='submit' className='col-4  btns' onClick={handlefinalSubmit}>SUBMIT </button>
                    </div>
                  </div>
                )

                :

                (
                  <>
                  </>
                )

              }
            </div >
            </div>
          </div >

        </>

      )}
    </>



  );
};

export default Verification;
