import React, { useState } from 'react'
import Header from '../../header/Header'
import "./stake.css"
import { Tab } from 'bootstrap'
import Tabs from 'react-bootstrap/Tabs';
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import Carousel from 'react-bootstrap/Carousel';
import { postGetData, postData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage } from '../../../ErrorMessage/ErrorMessage';
import { InrErrorScheema } from '../../../Yup';
import CurrencyFormat from "react-currency-format";
import Cookies from "js-cookie";
import Slider from "react-slick";
import Loader from 'react-js-loader';

const Stake = () => {
    const navigate = useNavigate();

    const [showNext, setNext] = useState(false);
    const [stakeData, setStakeData] = useState([]);

    const [referral, setReferral] = useState(false);
    const [loading, setLoading] = useState(false);


    const [error, setError] = useState();
    const [premError, setPError] = useState();
    const [referError, setReferError] = useState('');
    const [referName, setReferName] = useState('');

    const [uproBalance, setUproBalance] = useState();
    const [userRefer, setUserRefer] = useState('');
    const [referCode, setRefercode] = useState('');
    const [readOnly, setReadOnly] = useState(false);
    const [show, setShow] = useState(false);

    const [uproPrice, setUproPrice] = useState();

    const [balance, setBalance] = useState({
        UPRO: 0,
        BNB: 0,
        TRX: 0,
        MATIC: 0,
        ETHERS: 0,
    });


    const [formData, setFormData] = useState({
        "stakeId": "",
        "stakeAmount": "",
        "stakeStartTime": "",
        "cur_name": "",
        "stakeProfitPercentage": "",
        "current_value": ""
    });


    const [inrPremium, setInrPremium] = useState({
        inrValue: ''
    })
    const [jwtCheke, setJwtCheck] = useState(false);

    const jwtCheck = () => {

        const token = Cookies.get("jwtToken");
        if (token) {
            setJwtCheck(true)


        } else {
            setJwtCheck(false)

        }
    }

    const getStackDetails = async () => {

        setLoading(true);


        const response = await postGetData(Apiservices.USER_STAKE_API)
        if (response.status) {
            const stakArray = response.stakes;
            setStakeData(stakArray);
        }

        setLoading(false);

    };



    const getBalance = async () => {
        const response = await postGetData(Apiservices.USER_STAKE_BALANCE)


        if (response.status) {
            setBalance((prevBalance) => ({
                ...prevBalance,
                UPRO: response.balanceDetails.UPRO.balance
            }));
        }

    };


    const getUproValue = async () => {
        const response = await postGetData(Apiservices.USER_UPRO_BALANCE)
        if (response.upro) {
            setUproPrice(response.upro.value)
        }

    };
    const getUser = async () => {
        const response = await postGetData(Apiservices.USER_GETUSER_REST_API);

        if (response.status) {
            setUserRefer(response.user.yourreferralCode);
            setRefercode(response.user.stakingReferralCode);
            getName(response.user.stakingReferralCode);
        } else {
            console.error("User data not found in the response.");
        }
    }

    // console.log(userRefer, "@#@#@#");

    const getName = async (referCode) => {

        if (referCode.length == 10) {

            const response = await postData(Apiservices.USER_NAME_REFERRAL, { referralCode: referCode })
            if (response.status) {
                setReferName(response.name)

            }
            if (response.status == false) {
                setReferName("")
                setReferError("Invalid Referral")
            }
        }

    };
    useCustomEffect(() => {
        getUser();
        getBalance();
        getStackDetails();
        getUproValue();
        jwtCheck();

    })


    // console.log("Balance", balance);

    const [isChecked, setIsChecked] = useState(false);
    const [checkError, setCheckError] = useState(false);

    const handleCheckboxClick = () => {
        if (!isChecked) {
            setIsChecked(true);
            setCheckError("")
        }
        else {
            setCheckError(ErrorMessage.STAKE_CHECKBOX_ERROR_MESSAGE)
        }

    };

    const [stakeName, setStakeName] = useState([])



    const [startAndEnd, setStartAndEnd] = useState(
        {
            stakeStartTime: '',
            stakeEndTime: '',
            stakeDuration: ''
        }

    )
    const handleModel = (data) => {
        // Reset some state variables
        setUproBalance('');
        if (jwtCheke) {
            setNext(true);

        } else {
            toast.dismiss()
            toast.error('Login Before Subscribe')
        }

        // Set the stake name from the data
        setStakeName(data);

        // Update the form data with the new stake information
        setFormData((prevData) => ({
            ...prevData,
            "stakeId": data.stakeId,
            "stakeProfitPercentage": data.stakePercentage,
            "cur_name": "UPRO",
            "current_value": uproPrice
        }));

        const duration = parseInt(data.stakeDuration.split(' ')[0], 10);

        const startDate = new Date();

        const endDate = new Date(startDate);
        endDate.setMonth(startDate.getMonth() + duration);

        if (startDate.getDate() !== endDate.getDate()) {
            endDate.setDate(0);
        }

        // Calculate the total duration in days
        const totalDuration = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        // console.log('Duration (in months):', duration);
        // console.log('Start Date:', startDate);
        // console.log('End Date:', endDate);
        // console.log('Total Duration (in days):', totalDuration);

        const startDateString = startDate.toISOString();
        const endDateString = endDate.toISOString();

        setStartAndEnd((prevData) => ({
            ...prevData,
            stakeStartTime: startDateString,
            stakeEndTime: endDateString,
            stakeDuration: totalDuration
        }));
        // console.log('Duration (in months):', formData);

    };




    const handleCurrencyChange = (e) => {

        setError('');

        const { value } = e.target;

        const filteredValue = value;

        if (filteredValue <= balance.UPRO) {
            setUproBalance(filteredValue);

            setFormData((prevData) => ({
                ...prevData,
                "stakeAmount": filteredValue,
            }));

        }

    }



    const [open, setOpen] = useState(false);

    const handleToggle = (isOpen) => {
        setOpen(isOpen);
    };
    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const offset = date.getTimezoneOffset(); // Get the time zone offset in minutes
        const adjustedDate = new Date(date.getTime() - offset * 60000); // Adjust the date by subtracting the offset in milliseconds

        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        const localDate = new Date(); // Current local date
        localDate.setMinutes(localDate.getMinutes() - offset); // Adjust current local date to UTC

        // If the date provided is in the future, adjust it backward
        if (adjustedDate > localDate) {
            adjustedDate.setDate(adjustedDate.getDate());
        }

        const formattedDate = `${adjustedDate
            .getDate()
            .toString()
            .padStart(2, "0")}-${monthNames[adjustedDate.getMonth()]
            }-${adjustedDate.getFullYear()}`;
        const formattedTime = `${adjustedDate
            .getHours()
            .toString()
            .padStart(2, "0")}:${adjustedDate
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${adjustedDate
                    .getSeconds()
                    .toString()
                    .padStart(2, "0")}`;
        return { date: formattedDate, time: formattedTime };
    };


    const modClick = () => {
        setNext(true)
    }


    const modbClose = () => {
        setCheckError("")
        setIsChecked(false)


        setRefercode('')
        setReferName('');
        setInrPremium(prevData => ({
            ...prevData,
            inrValue: ''
        }));

        setInrError({ inrValue: '' });
        setReferError("");
        setNext(false)
        setShow(false)

    }


    const handleReferralChange = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        if (name === "referralCode") {
            sanitizedValue = value.replace(/[^0-9a-zA-Z]/g, '').toUpperCase();

            setRefercode(sanitizedValue);
        }

        setReferError("");



        // setRefercode(filteredValue);
    }

    const handleReferralName = async () => {

        // USER_NAME_REFERRAL

        if (referCode.length === 10) {

            if (referCode !== userRefer) {
                setReferError("");
                const response = await postData(Apiservices.USER_NAME_REFERRAL, { referralCode: referCode })

                if (response.status) {

                    setReferName(response.name)

                }
                if (response.status == false) {
                    setReferName("")
                    setReferError("Invalid Referral")
                }

            }
            else {
                setReferError("Invalid Referral Code");
            }
        }
        else {
            setReferError("Invalid Referral Length");
        }



    }

    const handlePost = async (e) => {
        e.preventDefault();


        var referStatus = false;


        if (referCode == '') {
            referStatus = true
        }
        else {

            if (referCode.length === 10 && referCode !== userRefer) {
                setReferError('')
                referStatus = true
            }
            else if (referCode.length > 1 && referCode.length !== 10) {
                setReferError("Invalid Referral Length");
            }
        }

        // const date = new Date().toISOString();

        // Set stakeStartTime before making the API call
        const updatedPostData = {
            ...formData,
            "stakeStartTime": new Date().toISOString(),
            "referralCode": referCode
        };



        // console.log("Updated Post Data", updatedPostData, referStatus);



        if (updatedPostData.stakeAmount >= 50 && referStatus && referError == '' && isChecked) {
            setLoading(true);


            // USER_STAKE_DETAILS_API



            const response = await postData(Apiservices.USER_STAKE_DETAILS_API, updatedPostData);

            if (response.status) {
                setNext(false)
                toast.success(response.message);
                setLoading(false);

            }
            else {
                setNext(false)
                toast.error(response.message);
                setLoading(false);

            }

        }
        else if (updatedPostData.stakeAmount < 50) {

            setError("Enter the Minimum Subscription value");

            // toast.error("Minimum Stake Amount - 50");
            setLoading(false);
            // setShow(false);
        }
        if (!isChecked) {
            setCheckError(ErrorMessage.STAKE_CHECKBOX_ERROR_MESSAGE)
        }

    }

    const handleOpen = () => {
        if (jwtCheke) {
            setShow(true)

        } else {
            toast.dismiss()
            toast.error('Login Before Subscribe')
        }
    }

    const [uproEquivalent, setUproEquivalent] = useState("");
    const [inrError, setInrError] = useState({
        inrValue: ''
    });


    const handleInrChange = (event) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^\d.]/g, '');
        setInrPremium(prevData => ({
            ...prevData,
            inrValue: numericValue
        }));





        if (!isNaN(numericValue) && uproPrice !== null) {
            const balanceAfterDeduction = parseFloat(numericValue);

            // Your other calculations
            const uproEquivalent = (balanceAfterDeduction) / uproPrice; // changed this line

            if (!isNaN(uproEquivalent)) {
                setUproEquivalent(uproEquivalent);
            } else {
                setUproEquivalent(0);
            }
        } else {
            setUproEquivalent(0);
        }

        InrErrorScheema
            .validateAt('inrValue', { inrValue: String(inputValue) }) // Ensure it's treated as string
            .then(() => {
                setInrError({ inrValue: '' });
            })
            .catch((error) => {
                setInrError({ inrValue: error.message });
            });
    };

    const handleInrSubmit = (e) => {
        // Check if event object is passed and has preventDefault function
        if (e && e.preventDefault) {
            e.preventDefault();
        } else {
            console.error('Event object is missing or invalid');
            return;
        }


        if (inrPremium.inrValue !== "") {

            if (referError == '') {
                setInrError({ inrValue: '' });
                setReferError("")
                handleInrPost(e);
            }
            else {
                setReferError("Referral Error")
            }
        } else {
            setInrError({ inrValue: 'INR Amount Required' });
        }
    };

    const handleInrPost = async (e) => {
        e.preventDefault();

        if (isChecked) {
            setLoading(true)


            const data = {
                referralCode: referCode,
                amount_inr: inrPremium.inrValue,
                uproAmount: uproEquivalent,
                cur_symbol: 'UPRO',
                cur_name: 'UPRO'
            }

            const response = await postData(Apiservices.USER_PREMIUM_STAKE, data);
            if (response.status) {
                toast.success(response.message)
                setShow(false)
                setRefercode('')
                //   navigate('/stake-history')
                setInrPremium((prevFormData) => ({
                    ...prevFormData,
                    inrValue: ''

                }));
                setUproEquivalent('')
                setLoading(false)


            } else {
                setUproEquivalent('')
                setShow(false)
                setRefercode('')

                toast.error(response.message)
                setInrPremium((prevFormData) => ({
                    ...prevFormData,
                    inrValue: ''

                }));
                setLoading(false)

                    ;
            }
        } else {
            setCheckError("Please read and accept the Terms of Service and Privacy Policy")
        }

    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,

        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    loop: true,
                    dots: false,
                    width: 290,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const handleMax = () => {
        setUproBalance(balance.UPRO)
    }

    return (
        <>
            {loading ? (
                <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
            ) : (
                <>
                    <Modal
                        show={showNext}

                        className="sec_mod stakemod"
                        backdrop="static"
                        centered="bool"
                        size='lg'
                    >
                        <Modal.Header closeButton onClick={modbClose}>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='to_mod'>
                                <div className="to_fa to_fas">
                                    <strong>Subscribe to <span>{stakeName.stakeName}</span></strong>

                                    <div className="inptbx  ">
                                        <span>Subscription Amount</span>
                                        <input type="text"
                                            value={uproBalance}
                                            name="uproBalance"
                                            onChange={handleCurrencyChange}
                                            autoComplete='off' />
                                        <p className='closeeye'><span >UPRO |</span><span onClick={handleMax}>MAX</span></p>
                                        {/* <p><span>Est.Interest per day</span><span>0.00000000 UPRO</span></p> */}
                                    </div>
                                    <p id='error'>{error}</p>

                                    <ul>
                                        <li><strong>Available Balance</strong><strong className='ans'>{balance.UPRO} UPRO</strong></li>
                                        <li><strong>Minimum Subscription </strong><strong className='ans'>50 UPRO</strong></li>
                                        <li><p>We wanted to let you know that the referral rewards for this staking plan will be sent to the referral code {referCode}. The rewards will be credited to {referName !== '' ? referName : 'the Referred Person'}. Thank you for participating and using your referral code! <span onClick={() => setReferral(!referral)} > Edit</span></p>

                                            {/* <img src='images/edit.svg' alt='' draggable="false" width={20}></img> */}

                                            {referral &&
                                                <div className="refer_c">
                                                    <div className="inptbx  ">
                                                        <input name='referralCode' type="text" value={referCode} onChange={handleReferralChange} maxLength={10} />
                                                        <span className='closeeye' onClick={handleReferralName}>update</span>
                                                    </div>

                                                    <p id='error'>{referError}</p>
                                                </div>
                                            }

                                        </li>
                                    </ul>
                                    <div className="chek">
                                        <label>
                                            <input type="checkbox" className="chkin"
                                                checked={isChecked}
                                                onClick={handleCheckboxClick}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            ></input>
                                            <p>I have read and agreed to <a href="#">the Canopro Stake Service </a></p>
                                        </label>
                                        <p id='error'>{checkError}</p>
                                    </div>

                                    <button type="button" className="btns" onClick={handlePost}>Confirm</button>


                                </div>
                                <div className='to_info'>
                                    <strong>Summary</strong>
                                    <ul>

                                        <li><span>Interest Start Date</span><span>{utcToNormalTime(startAndEnd.stakeStartTime).date}</span></li>
                                        <li><span>Interest Period</span><span>{startAndEnd.stakeDuration} Days</span></li>
                                        <li><span>Interest Payment Date</span><span>{utcToNormalTime(startAndEnd.stakeEndTime).date}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>

                    <Modal
                        show={show}

                        className="sec_mod stakemod"
                        backdrop="static"
                        centered="bool"
                        size='lg'
                    >
                        <Modal.Header closeButton onClick={modbClose}>

                        </Modal.Header>
                        <Modal.Body>
                            <div className='to_mod'>
                                <div className="to_fa to_fas">
                                    <strong>Premium <span>STAKE</span></strong>

                                    <div className="inptbx  ">
                                        <span>Subscription Amount</span>
                                        {/* <input type="text" 
                                value={inrPremium.inrValue} 
                                name="inrValue" 
                                onChange={handleInrChange} 
                                autoComplete='off' /> */}

                                        <CurrencyFormat
                                            autoComplete='off'
                                            maxLength={12}
                                            value={inrPremium.inrValue}
                                            name="inrValue"
                                            thousandSeparator={true}
                                            prefix={"₹"}
                                            decimalScale={2}
                                            allowNegative={false}
                                            allowEmptyFormatting={false}
                                            allowLeadingZeros={false}
                                            decimalSeparator="."
                                            fixedDecimalScale={true}
                                            inputMode="numeric"
                                            onChange={handleInrChange}
                                        />
                                        <p className='closeeye'><span>UPRO |</span>MAX</p>
                                        {/* <p><span>Est.Interest per day</span><span>0.00000000 UPRO</span></p> */}
                                    </div>
                                    <p><span>UPRO :</span> <span>{uproEquivalent}</span></p>
                                    <p id='error'>{inrError.inrValue}</p>

                                    <ul>
                                        <li><strong>Available Balance</strong><strong className='ans'>{balance.UPRO} UPRO</strong></li>
                                        <li><strong>Minimum Subscription </strong><strong className='ans'>50 UPRO</strong></li>
                                        <li><p>We wanted to let you know that the referral rewards for this staking plan will be sent to the referral code {referCode}. The rewards will be credited to {referName !== '' ? referName : 'Referred Person'}. Thank you for participating and using your referral code!</p>
                                            <div className="ints">
                                                <button onClick={() => setReferral(!referral)} className='btns'>referral</button>

                                                {referral &&
                                                    <div className="refer_c">
                                                        <div className="inptbx  ">
                                                            <input type="text" name='referralCode' value={referCode} onChange={handleReferralChange} maxLength={10} />
                                                        </div>
                                                        <button onClick={handleReferralName} className='btns'>update</button>
                                                        <p id='error'>{referError}</p>
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="chek">
                                        <label>
                                            <input type="checkbox" className="chkin"
                                                checked={isChecked}
                                                onClick={handleCheckboxClick}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            ></input>
                                            <p>I have read and agreed to <a href="#">the Canopro Stake Service Agreement</a></p>
                                        </label>
                                        <p id='error'>{checkError}</p>
                                    </div>

                                    <button type="button" className="btns" onClick={handleInrSubmit}>Confirm</button>


                                </div>
                                <div className='stak-cont'>
                                    <strong>REMARKS :</strong>
                                    <p>If you unstake before maturity date, the following conditions will be applicable.</p>
                                    <ul>
                                        <li>Unstake between  91 days to 180 days from date of staking, 80% of staking amount will be given.</li>
                                        <li>Unstake between  181 days to 365 days from date of staking, 100% of staking amount will be given.</li>
                                        <li>Unstake between  366  days to 729 days from date of staking, 124% of staking amount will be given.</li>
                                    </ul>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>
                    <Header />
                    <div className='main-content'>
                        <div className='stake_ban'>
                            <div className='container'>
                                <div className='stak_con'>
                                    <div className='stak_cont'>
                                        <h1>Canopro Stake</h1>
                                        <p>Grow Your Crypto with Passive Returns</p>
                                    </div>
                                    <div className='stak_rgt'>

                                        <img src='images/coincircle.png' alt='stake_ban' draggable="false"></img>



                                        {jwtCheke ? (

                                            <div className='stak_tex'>
                                                <strong>Account Balance</strong>
                                                <p><span>{balance.UPRO.toFixed(4)}  UPRO</span></p>

                                                <ul>
                                                    <li> <button type='button' className='btns' onClick={() => navigate("/wallet")}>Wallet</button></li>
                                                    <li> <button type='button' className='btns' onClick={() => navigate("/stakeHistory")}>History</button></li>
                                                    {/* <li> <button type='button' className='btns' onClick={() => navigate("/stakePremiumHistory")}>Premium History</button></li> */}

                                                </ul>
                                                {/* <span className='eye'><img src='images/eye.svg' alt='eye' draggable="false"></img></span> */}
                                            </div>

                                        ) : (

                                            <div className='stak_tex stak_con'>
                                                <img src='https://images-ultra.s3.amazonaws.com/hlogod.svg' alt='logo' draggable="false"></img>
                                                <span><img src='https://images-ultra.s3.amazonaws.com/hlogo.svg' alt='logo' draggable="false"></img></span>
                                                <p>Sign in to view your holdings</p>
                                                <ul>
                                                    <li> <button type='button' className='btns' onClick={() => navigate("/auth?tab=signin")}>Sign In</button></li>
                                                </ul>
                                                <span className='eye'><img src='images/eye.svg' alt='eye' draggable="false"></img></span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='stake_off'>
                            <div className='container'>

                                <Slider {...settings}>


                                    {stakeData.map((data, index) => (

                                        <div key={data._id} className='stake'>

                                            <span className='label'>{data.stakeName}</span>
                                            <p><strong> {data.stakeDuration.split(' ')[0]} {data.stakeDuration.split(' ')[1]}</strong>
                                                <span>{data.stakeDescription}</span></p>
                                            <ul >
                                                {
                                                    (data.stakeDuration.split(' ')[0] == 3)
                                                        ? <li className='higlight'>Minimum Guarantee 12% Amount Worth Coin</li>
                                                        : (data.stakeDuration.split(' ')[0] == 6)
                                                            ? <li className='higlight'>Minimum Guarantee 24% Amount Worth Coin</li>
                                                            : (data.stakeDuration.split(' ')[0] == 12)
                                                                ? <li className='higlight'>Minimum Guarantee 50% Amount Worth Coin</li>
                                                                : data.stakeDuration.split(' ')[0] == 24



                                                }
                                                <li>For {data.stakeDuration.split(' ')[0]} months</li>
                                                <li>Percentage {data.stakePercentage}%</li>

                                                <li>Minimum 50 coins to be staked</li>



                                            </ul>
                                            <button type="button" className='btns' onClick={() => handleModel(data)}>Subscribe</button>

                                        </div>
                                    ))}

                                    {/* <div className='stake staks'>
                                        <span className='label'>Premium</span>
                                        <p><strong>24 Months</strong>
                                            <span>Premium Staking</span></p>
                                        <ul>
                                            <li>24 months</li>
                                            <li>Monthly bonus 200% +</li>
                                            <li>Coin growth</li>
                                            <li>Minimum 50 coins to be staked</li>
                                        </ul>
                                        <button type="button" className='btns' onClick={handleOpen}>Subscribe</button>
                                        <span className='prim'> Premium</span>
                                    </div> */}

                                </Slider>



                            </div>
                        </div>
                        {/* <div className='stake_tabl'>
                            <div className='container'>
                                <div className='sp_fut'>
                                    <Tabs
                                        defaultActiveKey="All"

                                    >
                                        <Tab eventKey="All" title="All">

                                            <div className='table-responsive con'>

                                                <table>
                                                    <thead>
                                                        <th>Coin</th>
                                                        <th>Est.APY</th>
                                                        <th>Duration</th>
                                                        <th>Product Type</th>
                                                        <th></th>
                                                    </thead>
                                                    {stakeData.map((data, index) => (
                                                        <tbody key={index}>
                                                            <tr >
                                                                <td className='im-hed'>
                                                                    <ul>
                                                                        <li><img src="images/upro.svg" alt="upro" draggable="false"></img></li>
                                                                        <li><span>UPRO</span></li>
                                                                    </ul>

                                                                </td>
                                                                <td>{data.stakePercentage}%</td>
                                                                <td>{data.stakeDuration}</td>
                                                                <td>{data.stakeName}</td>
                                                                <td><button type='button' className='btns' onClick={() => handleModel(data)}>Subscribe</button></td>
                                                            </tr>


                                                        </tbody>
                                                    ))}
                                                </table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Stake" title="Stake">

                                        </Tab>
                                        <Tab eventKey="Premium" title="Premium">

                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </>
            )}
        </>
    )
}

export default Stake