import React, { useState, useEffect } from "react";
import Header from "../../header/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./account.css"
import Profile from "./Profile";
import Security from "./Security";
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from 'react-js-loader';
import { postGetData } from "../../../AxiosInstance/AxiosInstance";
import { Apiservices } from "../../../ApiServices/ApiServices";
import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";
// import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";

// import { Apiservices } from '../../../ApiServices/ApiServices';

// import { postData, getData, putData, deleteData, postGetData } from '../../../AxiosInstance/AxiosInstance';

const AccountDetail = () => {

  const location = useLocation();

  const [activeTab, setActiveTab] = useState('profile');

  // const [formData, setFormData] = useState({
  //   email: '',
  //   referralID: '',
  //   name: '',
  //   mobineNumber: '',
  //   address: '',
  //   dob: '',
  //   city: '',
  //   state: '',
  //   country: '',
  // });
const [hide,setHide]=useState(true)
  const getUser = async () => {
    setHide(true)
    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);
if(response.status){
  setHide(false)
}else{
  setHide(false)
}
    

  }

  useCustomEffect(getUser);
  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');

    // console.log("------ - ---- ", tabParam);

    if (tabParam === 'profile') {
      setActiveTab('Profile');
    }
    else {
      setActiveTab('Security');
    }
  }, [location.search]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
<>
{hide ? (
  <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />

):(
  <>
  <Header />
  <div className="acc_det">
    <div className="container">
      <Tabs
        activeKey={activeTab}
        // defaultActiveKey="Profile"
        onSelect={handleTabChange}
        id="noanim-tab-example"
        className="mb-3 emil_c col-9"
      >
        <Tab eventKey="Profile" title="Profile" className="sig" >
          <Profile />
        </Tab>
        <Tab eventKey="Security" title="Security" className="sig" >
          <Security />
        </Tab>
        {/* <Tab eventKey="API KEYS" title="API Keys" className="sig"></Tab> */}
      </Tabs>
    </div>
  </div>
</>
)}
   
    </>
  );
};

export default AccountDetail;
