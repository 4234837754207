import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';

const VerifyEmail = () => {
  let { string } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [countdown, setCountdown] = useState(30); // Initial countdown value

  // const getString = async () => {
  //   const token = string;
  //   console.log(token);
  //   try {
  //     const response = await postGetData(Apiservices.USER_VERIFY_REST_API, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error while fetching data:", error);
  //     return {
  //       status: false,
  //       message: "Error occurred while verifying token",
  //     };
  //   }
  // };
  const getString = () => {
    const token = string
    
    return axios
      .post(`${Apiservices.BACKEND_LOCALHOST_SERVER}/user/verify`,{},{
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error while fetching data:", error);
        return {
          status: false,

          message: "Error occurred while verifying token",
        };
      });
  };
  
  // Example usage:
  getString();
  

  useEffect(() => {
    const fetchData = () => {
        getString()
            .then(response => {
                setData(response);
            })
            .catch(error => {
                console.error("Error in fetchData:", error);
            });
    };

    fetchData();
}, [string]);


  useEffect(() => {
    if (data) {
      if (data.status) {
        toast.success(data.message);
          navigate("/auth?tab=signin");
      } else {
        toast.error(data.message);
          navigate("/auth?tab=signup");
      }
    }
  }, [data, countdown, navigate]);

  useEffect(() => {
    // Update countdown every second
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        // If countdown is already 0, clear the interval
        if (prevCountdown === 0) {
          clearInterval(timer);
          return 0;
        }
        // Otherwise, decrement the countdown
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up interval on component unmount
  }, []); // Empty dependency array to run the effect only once

  return (

    <div class="container container234">
    <div class="card text-center c-cc">
      <div class="card-body">
        <div class="center-content1">
          <p class="verify-text">You're being redirected to login within <span class="verify-text1">{countdown}</span> seconds.</p>
          <button class="signup-button1 btnmon btns" onClick={() => navigate("/auth")}>Skip</button>
        </div>
      </div>
    </div>
  </div>
  

   
  );
};

export default VerifyEmail;
