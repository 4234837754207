import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ReactErrorToast = (errorMessage) => {
    try {
        toast.dismiss()
        return toast.error(errorMessage);
    } catch (error) {
        
    }
};


export const ReactWarningToast = (warningMessage) => {
    try {
        toast.dismiss()
        return toast.warning(warningMessage);
    } catch (error) {
        
    }
};


export const ReactSuccessToast = (successMessage) => {
    try {
        toast.dismiss()
        return toast.success(successMessage);
    } catch (error) {
        
    }
};