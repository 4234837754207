import React from 'react';
import * as Yup from "yup";

export const signupSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is Required")
    .matches(/^[A-Za-z\s]+$/, "Numbers and Symbols are not allowed")
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name must be at most 50 characters"),

  email: Yup.string()
    .required("Email is Required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

  password: Yup.string()
    .required("Password is Required")
    .min(8, "Password must be at least 8 characters")
    .matches(/^(?=.*[a-z])/, "Password must contain at least one lowercase")
    .matches(/^(?=.*[A-Z])/, "Password must contain at least one uppercase")
    .matches(/^(?=.*\d)/, "Password must contain at least one number")
    .matches(
      /^(?=.*[@$!%*?&#])/,
      "Password must contain at least one special character"
    ),

  confirmPassword: Yup.string().required("Confirm Password is Required"),
  referralCode: Yup.string().matches(
    /^[a-zA-Z0-9\s]*$/,
    "Referral code must contain only alphabets and numbers"
  ),
});

export const signinScheema = Yup.object().shape({
  signinEmail: Yup.string()
    .required("Email is Required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),

  signinpassword: Yup.string()
    .required("Password is Required")
    .min(8, "Password must be at least 8 characters")
    .matches(/^(?=.*[a-z])/, "Password must contain at least one lowercase")
    .matches(/^(?=.*[A-Z])/, "Password must contain at least one uppercase")
    .matches(/^(?=.*\d)/, "Password must contain at least one number")
    .matches(
      /^(?=.*[@$!%*?&#])/,
      "Password must contain at least one special character"
    ),


});

export const forgetEmailScheema = Yup.object().shape({
  forgetEmail: Yup.string()
    .required("Email is Required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),




});

export const resetPassword = Yup.object().shape({
  password: Yup.string()
    .required("Password is Required")
    .min(8, "Password must be at least 8 characters")
    .matches(/^(?=.*[a-z])/, "Password must contain at least one lowercase")
    .matches(/^(?=.*[A-Z])/, "Password must contain at least one uppercase")
    .matches(/^(?=.*\d)/, "Password must contain at least one number")
    .matches(
      /^(?=.*[@$!%*?&#])/,
      "Password must contain at least one special character"
    ),

  confirmPassword: Yup.string().required("Confirm Password is Required"),
  referralCode: Yup.string().matches(
    /^[a-zA-Z0-9\s]*$/,
    "Referral code must contain only alphabets and numbers"
  ),
});

export const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is Required")
    .matches(/^[a-zA-Z.\s]+$/, "First Name must contain only letters and spaces")
    .min(3, "First Name must be at least 3 characters")
    .max(20, "First Name must be at most 20 characters"),


  lastName: Yup.string()
    .notRequired(),
  dob: Yup.string()
    .required("DOB is Required")
    .matches(/^\d{2}-\d{2}-\d{4}$/, "DOB must be in the format DD-MM-YYYY")
    .test('is-valid-date', 'Invalid date', (value) => {
      if (!value) return false;
      const [day, month, year] = value.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
      );
    })
    .test('is-18-to-100-years-old', 'DOB must be between 18 to 100 years old', (value) => {
      if (!value) return false;
      const [day, month, year] = value.split('-').map(Number);
      const dob = new Date(year, month - 1, day);
      const today = new Date();

      const age = today.getFullYear() - dob.getFullYear();
      const isMonthPast = today.getMonth() > dob.getMonth();
      const isMonthSameButDayPast = today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate();

      const is18OrOlder = (isMonthPast || isMonthSameButDayPast) ? age >= 18 : age > 18;

      const is100OrYounger = (isMonthPast || isMonthSameButDayPast) ? age <= 100 : age < 100;

      return is18OrOlder && is100OrYounger;
    }),
  address: Yup.string()
    .required("Address is Required"),
  country: Yup.string()
    .required("Country is Required"),
  state: Yup.string()
    .required("State is Required"),
  city: Yup.string()
    .notRequired()
});

export const kycSchema = Yup.object().shape({

  aadharNumber: Yup.string()
    .required("Aadhaar Number Required")
    .matches(/^[0-9]{12}$/, "Aadhaar number must be 12 digits"),
  reAadharNumber: Yup.string()
    .required("Re-Aadhaar Number Required")
    .matches(/^[0-9]{12}$/, "Aadhaar number must be 12 digits"),

});

export const panSchema = Yup.object().shape({
  panNumber: Yup.string()
    .required("PAN Number Required")
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN number"),
  rePanNumber: Yup.string()
    .required("Re-PAN Number is Required")
})

export const mobileNumberSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required("Mobile number is Required")
    .matches(/^[0-9]+$/, "Mobile number must contain only numbers")
    .typeError("Mobile number must be a valid number")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number must be at most 10 digits")
    .matches(/^[0-9]+$/, "Mobile number must contain only numbers and no spaces")
});

export const bankSchema = Yup.object().shape({
  accountHolderName: Yup.string()
    .required("Verify your Account Name"),

  accountNumber: Yup.string()
    .required("Account Number is Required")
    .matches(/^[0-9]+$/, "Account Number must contain only numbers")
    .min(6, "Account Number must be at least 6 digits")
    .max(20, "Account Number must be at most 20 digits"),
  reAccountNumber: Yup.string()
    .required("Re-Account Number is Required")
    .matches(/^[0-9]+$/, "Re-Account Number must contain only numbers")
    .min(6, "Re-Account Number must be at least 6 digits")
    .max(20, "Re-Account Number must be at most 20 digits"),

  bankName: Yup.string().required("Bank Name is Required"),

  accountType: Yup.string().required("Account Type is Required"),

  IFSC: Yup.string()
    .required("IFSC Code is Required")
    .matches(/^[A-Za-z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code"),

  branchName: Yup.string().required("Branch Name is Required"),
});


export const utrSchema = Yup.object().shape({
  utrId: Yup.string()
    .required("UTR ID is required")
    .matches(/^[a-zA-Z0-9]{16,22}$/, "Enter Valid UTR ID"),
});

export const InrSchema = Yup.object().shape({
  inrValue: Yup.string()
    .required("INR value is required")
    .test(
      'is-at-least-1000',
      'INR value must be at least 1000',
      value => {
        const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
        return !isNaN(numericValue) && numericValue >= 1000;
      }
    )
    .test(
      'is-at-most-5000000',
      'INR value must be at most 5000000',
      value => {
        const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
        return !isNaN(numericValue) && numericValue <= 5000000;
      }
    ),
});

export const withdrawalSchema = Yup.object().shape({
  cryptoAmount: Yup.string().required("Crypto Amount is Required")
    .test(
      'is-at-least-10',
      'UPRO value must be at least 10',
      value => {
        const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
        return !isNaN(numericValue) && numericValue >= 0;
      }
    )
    .test(
      'is-at-most-1000',
      'UPRO value must be at most 1000',
      value => {
        const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
        return !isNaN(numericValue) && numericValue <= 1000;
      }
    ),
  cryptoName: Yup.string().required("Crypto Name is Required"),
  cryptoAddress: Yup.string().required("Crypto Name is Required"),
  UPRO: Yup.string()
});

export const changeScheema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Old Password is Required")
    .matches(
      /^(?=.*[a-z])/,
      "Old Password must contain at least one lowercase"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "OldPassword must contain at least one uppercase"
    )
    .matches(/^(?=.*\d)/, "Old Password must contain at least one number")
    .matches(
      /^(?=.*[#@$!%*?&])/,
      "Old Password must contain at least one special character"
    )
    .min(8, "Old Password must be at least 8 characters"),

  password: Yup.string()
    .required("New Password is Required")
    .matches(
      /^(?=.*[a-z])/,
      "New Password must contain at least one lowercase"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "New Password must contain at least one uppercase"
    )
    .matches(/^(?=.*\d)/, "New Password must contain at least one number")
    .matches(
      /^(?=.*[#@$!%*?&])/,
      "New Password must contain at least one special character"
    )
    .min(8, "New Password must be at least 8 characters"),

  confirmPassword: Yup.string().required("Confirm Password is required"),
  // newPassword: Yup.string()
});


export const InrErrorScheema = Yup.object().shape({
  inrValue: Yup.string()
    .required("INR value is required")
});

export const tfaSchema = Yup.object().shape({

  tfaCode: Yup.string()
    .required('2FA Code is Required')
    .matches(/^\d{6}$/, '2FA code must be a 6-digit number'),






});
function YupComponent() {
  return (
    <div>Yup</div>
  );
}

export default YupComponent;
