import React from 'react'
import Header from '../../header/Header'
const Nft = () => {
  return (
    <>
      <Header/>
      <div className='main-content'>
        <div className='nft_conts'>
            <div className='container'>
          <div className='nft'>
            <div className='nft_lft'>
               <p>The smartest  digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.</p>
               <button type='button'  className='btns'>COMING SOON</button>
            </div>
            <div className='nft_rgt'>
                <img src='images/headphonekurangu.png' alt='nft'></img>
                <ul className='user_details'>
                    <li><img src='images/useravathar.svg' alt="avathar"></img><p><strong>John</strong><span>NFT #123456</span></p></li>
                    <li><p><img src='images/upro.svg' alt="upro"></img><span>0.45 UPRO</span></p></li>
                </ul>
            </div>
          </div>
          </div>
          </div>
      </div>
    
    </>
  )
}

export default Nft