import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ Component }) => {
     let isShow = Cookies.get("") !== null && Cookies.get("jwtToken") !== undefined  ? true : false ;

    return isShow ? <Component/> : <Navigate to="/auth" />;
};

export default PrivateRoute;