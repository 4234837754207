import React, { useState } from 'react'
import Header from '../../header/Header'
import "./wallet.css"
import { Tab } from 'bootstrap'
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
const Wallet = () => {
    const navigate = useNavigate();
    const [balence, setBalance] = useState([]);
    const [Inrbalence, setInrBalance] = useState(0);
    const [uproBalance, setUproBalance] = useState({ balance: 0, earn_balance: 0 })

    const [ubitBalance, setUbitBalance] = useState({ balance: 0, earn_balance: 0 })


    const getBalance = async () => {
        const response = await postGetData(Apiservices.USER_BALENCE_API);
        if (response.status) {
            const coinBalances = response.CoinBalance;
            setBalance(coinBalances);


            // const totalEarn = coinBalances.find((acc) => coin.earn_balance,0);
            // setTotalEarnBalance(totalEarn);
            const upro = coinBalances.find(coin => coin.cur_Name === "UPRO");
            if (upro) {
                setUproBalance(upro);
            }
            const ubit = coinBalances.find(coin => coin.cur_Name === "UBIT");
            if (ubit) {
                setUbitBalance(ubit);
            }

        }

    };
    
    const getInrBalance = async () => {
        const response = await postGetData(Apiservices.USER_STAKE_BALANCE)


        if (response.status) {
            setInrBalance(response.balanceDetails.INR.balance)
        }

    };


    useCustomEffect(() => {
        getBalance()
        getInrBalance()
    })
    // console.log("@@@@@", uproBalance);




    return (
        <>
            <Header />
            <div className='wallet'>
                <div className="container">
                    <div className='wallet_balan'>
                        <div className='balan'>
                            <div className='bal_bt'>
                                <strong>Estimated Balance</strong>
                                <p><strong>{uproBalance.balance.toFixed(4)} UPRO</strong>
                                    {/* <span>= ₹ 0.00 , $0.00</span> */}
                                </p>
                            </div>
                            <ul>
                                {/* <li><button type='button' className='btns' onClick={() => {navigate("/wallet-deposit")}}>Deposit</button></li> */}
                                <li><button type='button' className='btns' onClick={() => { navigate("/wallet-withdrawal") }} >Withdraw</button></li>
                                {/* <li><button type='button' className='btns'>Transfer</button></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className='acc_difbal'>

                        {/* <div className='spot_acc'>
                    <span>Futures Account</span>
                    <p><strong>0.0000000000 UPRO</strong><span>= ₹ 0.00 , $0.00</span></p>
                </div> */}
                 <div className='spot_hed'>
                            <strong>Spot Account Balance</strong>
                            <div className='spot_acc'>
                            <span>Spot Account</span>
                                <p><strong>0.0000 UPRO</strong></p>
                            </div>
                        </div>
                        <div className='spot_hed'>
                            <strong>Earn Account Balance</strong>
                            
                            <div className='spot_acc'>

                                <span>Earn Account</span>
                                <p><strong>{uproBalance.earn_balance.toFixed(4)} UPRO</strong>
                                    {/* <span>= ₹ 0.00 , $0.00</span> */}
                                </p>
                            </div>
                        </div>
                        {/* <div className='spot_hed'>
                            <strong>UBIT Balance</strong>
                            <div className='spot_acc'>

                                <p><strong>{ubitBalance.balance} UBIT</strong></p>
                            </div>
                        </div> */}
                       
                    </div>
                    <div className='sp_fut'>
            <Tabs
            defaultActiveKey="spot"
            
            >
                <Tab eventKey="spot" title="Spot">
                    {/* <div className='chek'>
                        <label>
                            <input type='checkbox'></input>
                            <p>Hide not held assets</p>
                        </label>
                        <div  className='serchbar'>
                            <input type="text" placeholder='Search'></input>
                        </div>
                    </div> */}
                    <div className='table-responsive con'>
                       <table>
                         <thead>
                            <th>Coin</th>
                            <th>Balance</th>
                            <th>INR Balance</th>
                            <th>Action</th>
                         </thead>
                         <tbody>
                            {/* <tr>
                                <td className='im-hed'>
                                    <ul>
                                        <li><img src="images/trontab.svg" alt="tron" draggable="false"></img></li>
                                        <li><strong>Tron</strong><span>TRX</span></li>                                        
                                    </ul>

                                </td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>
                                    <ul>
                                        <li> <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Trade
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Trade</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Trade</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Trade</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown></li>
                                        <li><button type='button' className='bot'>Deposit</button></li>
                                        <li><button type='button' className='bot'>Withdraw</button></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className='im-hed'>
                                    <ul>
                                        <li><img src="images/bnb.svg" alt="tron" draggable="false"></img></li>
                                        <li><strong>Binance</strong><span>BNB</span></li>                                        
                                    </ul>

                                </td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>
                                    <ul>
                                        <li> <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Trade
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Trade</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Trade</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Trade</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown></li>
                                        <li><button type='button' className='bot'>Deposit</button></li>
                                        <li><button type='button' className='bot'>Withdraw</button></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className='im-hed'>
                                    <ul>
                                        <li><img src="images/bitcoin.svg" alt="tron" draggable="false"></img></li>
                                        <li><strong>Bitcoin</strong><span>BTC</span></li>                                        
                                    </ul>

                                </td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td>
                                    <ul>
                                        <li> <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Trade
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Trade</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Trade</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Trade</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown></li>
                                        <li><button type='button' className='bot'>Deposit</button></li>
                                        <li><button type='button' className='bot'>Withdraw</button></li>
                                    </ul>
                                </td>
                            </tr> */}
                         </tbody>
                       </table>
                    </div>
                </Tab>
              
                <Tab eventKey="earn" title="Earn">
                   
                </Tab>
            </Tabs>
            </div>
                </div>
            </div>
        </>
    )
}

export default Wallet