import React, { useEffect, useState } from 'react'
import getIFSC from "node-ifsc";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { profileSchema } from '../../../Yup';
import { useCustomEffect } from "../../../useEffectCommon/useCommonEffect";

import { Apiservices } from '../../../ApiServices/ApiServices';

import { postData, getData, putData, deleteData, postGetData, deleteBankData } from '../../../AxiosInstance/AxiosInstance';

import { bankSchema, resetPassword } from '../../../Yup'

import axios from "axios";
import { Dropdown } from 'bootstrap';
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from 'react-js-loader';
import { Country, State, City } from "country-state-city";

import { useNavigate, useLocation } from 'react-router-dom';


const Profile = () => {
  const navigate = useNavigate()


  const authToken =
    "T1gwM0U4VWdKRTJEQ01DWDhjLjgyYmZkMDFhZDlmYTg1YWQ3ODNkZTRiZTc0OGEyODNhOmRlMDkzMDFkMGRiZDEzNmMyOTNjZTgwYzIzMjRiNTI2NTEwMmU0Y2ZmYjg2Y2UxOA==";

  const [showModal, setShowModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const [error, setError] = useState(null);

  const [rdonly, setRdOnly] = useState(false);

  const [errors, setErrors] = useState({});

  const [bankDetail, setBankDetails] = useState(null);

  const [kycSts, setKycSts] = useState();

  const [formData, setFormData] = useState({
    email: '',
    referralID: '',
    firstName: '',
    lastName: '',
    mobineNumber: '',
    address: '',
    dob: '',
    city: '',
    state: '',
    country: '',
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const countries = Country.getAllCountries();
  const states1 = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
  const cities = selectedState ? City.getCitiesOfState(selectedCountry, selectedState) : [];

  const [countdown, setCountdown] = useState(60);

  const [loading, setLoading] = useState(false);

  const [getotp, setGetOtp] = useState("");

  const [showNext, setNext] = useState(false);


  const [profilePage, setProfilePage] = useState(true)

  const [showLast, setLast] = useState(true);


  const [bankData, setBankData] = useState({
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    accountType: "",
    branchName: "",
    IFSC: "",
    reAccountNumber: "",
  });

  const [otpError, setOtpError] = useState("");

  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [imageError, setImageError] = useState({
    proffront: "",
  });

  const [storeDataAsArray, setStoreDataAsArray] = useState([]);

  const [cardData, setCardData] = useState([]);

  const getUser = async () => {

    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);

    // console.log("-----------> ", response.user);

    if (response.status) {

      const profileUpdated = response.user.isProfileUpdated;


      console.log("------------ ", response.user.kycDetails);

      var kycStatusV = 0;

      // if(response.user.kycDetails !== undefined)
      //   {
      //     kycStatusV = response.user.kycDetails.kycStatus;
      //   }


      // setKycSts(kycStatusV)

      console.log("-----------> ", profileUpdated)



      if (profileUpdated && profileUpdated !== undefined) {

        if (response.user.kycDetails !== undefined) {
          kycStatusV = response.user.kycDetails.kycStatus;
        }


        setKycSts(kycStatusV)

        setLast(false);
        setFormData((prevData) => ({
          ...prevData,
          email: response.user.email,
          referralID: response.user.yourreferralCode,
          firstName: response.user.firstName,
          lastName: response.user.lastName,
          mobineNumber: response.user.mobileNumber,
          address: response.user.address,
          dob: response.user.dob,
          city: response.user.city,
          state: response.user.state,
          country: response.user.country,
        }));

      }
      else {
        setProfilePage(false);
      }

      setLoading(false);
    }
    else {
      setLoading(false);

    }

  }

  const handleTFAEnable = async () => {
    const response = await postData(Apiservices.USER_SKIP_API, { kycSkip: false });
    navigate('/verification')
  }

  const fetchData = async () => {

    setLoading(true);

    const response = await postGetData(Apiservices.USER_GET_BANK_REST_API);

    if (response.status) {

      const bankingDetailsForTable = response.bankDetails;

      console.log("333333333333333333", bankingDetailsForTable)
      const pushArray = [];

      for (let i = 0; i < bankingDetailsForTable.length; i++) {

        const element = bankingDetailsForTable[i];




        const setDataInTheFrom = {
          accountHolderName: element.accountHolderName,
          accountNumber: element.accountNumber,
          bankName: element.bankName,
          accountType: element.accountType,
          branchName: element.branchName,
          IFSC: element.IFSC,
          reAccountNumber: element.reAccountNumber,
          id: element._id,
          bankVerifyStatus: element.bankVerifyStatus,
          // textColorClass: textColorClass
        };

        pushArray.push(setDataInTheFrom);

        setStoreDataAsArray(pushArray);

        setLoading(false);

        setBankData({
          accountHolderName: "",
          accountNumber: "",
          bankName: "",
          accountType: "",
          branchName: "",
          IFSC: "",
          reAccountNumber: "",
        });


        setSelectedFile('')

        setProfileImage('');

        setRdOnly(false);

      }
      setCardData(response.bankDetails);
    }
    else {
      setLoading(false);

      setBankData({
        accountHolderName: "",
        accountNumber: "",
        bankName: "",
        accountType: "",
        branchName: "",
        IFSC: "",
        reAccountNumber: "",
      });

      setSelectedFile('')

      setProfileImage('');
    }

  }


  useCustomEffect(getUser);

  useCustomEffect(fetchData);

  function startCountdown() {
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : prevCountdown));
    }, 1000);

    return () => clearInterval(intervalId);
  }

  useCustomEffect(() => startCountdown(setCountdown), []);

  const handleResend = async () => {
    setLoading(true);

    const result = await postData(Apiservices.USER_RESEND_OTP_REST_API, { email: formData.email });

    setLoading(true);
    if (result.status) {
      toast.success(result.message);

      setLoading(false);

      setCountdown(60);
      startCountdown(setCountdown)
      // navigate("/login");
    } else {
      toast.success(result.message);

      setLoading(false);
    }

  };

  const modClick = () => {
    setShowModal(true)
    console.log("modal")
  }
  const modClose = () => {
    setSelectedFile("")
    setProfileImage("")

    setBankData((prev) => ({
      ...prev,
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      accountType: "",
      branchName: "",
      IFSC: "",
      reAccountNumber: ""
    }))

    getUser()
    setShowModal(false)
    setRdOnly(false)
    setShowEdit(false)

    setErrors('');

    setImageError((prevData) => ({
      ...prevData,
      proffront: ""
    }));

  }

  const handleChange = async (e) => {
    const { name, value } = e.target;

    let filteredValue = value;





    if (name === "IFSC" || name === "bankName") {

      filteredValue = value.replace(/\s/g, "").toUpperCase();
    }




    if (name === "IFSC" && filteredValue.length === 11) {

      const fetchBankDetails = async () => {

        try {
          const data = await getIFSC(filteredValue);

          setBankDetails(data);

          setBankData((prevData) => ({
            ...prevData,
            bankName: data.BANK,
            branchName: data.BRANCH
          }))

          setErrors({
            ...errors,
            bankName: "",
            branchName: "",
            IFSC: "",
          });

        } catch (error) {
          setError(error);
        }
      };

      fetchBankDetails();
    }


    // if (name === "IFSC" && filteredValue.length === 0) {



    //   setBankData((prevData) => ({
    //     ...prevData,
    //     bankName: "",
    //     branchName: "",
    //     IFSC: "",
    //   }))

    // console.log("....... ", bankData);
    // }

    if (name === "accountNumber" && value.length > 20) {

      setErrors({ ...errors, [name]: "only 20 number below" });

      return;

    }


    if (name === "accountNumber" || name === "reAccountNumber") {
      filteredValue = value.replace(/[^0-9]/g, "")
    }



    if (name === "accountHolderName") {
      filteredValue = value.trimStart();
    }

    console.log("++++++++++ > > > ", filteredValue, name);

    setBankData({ ...bankData, [name]: filteredValue });

    // Validate only if value is not empty
    bankSchema
      .validateAt(name, { [name]: filteredValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
    // Clear error message if field is empty
    setErrors({ ...errors, [name]: "" });
  };



  async function fetchAccountDetails() {

    if (formData.accountNumber === formData.reAccountNumber) {


      try {
        if (formData.reAccountNumber !== "" && formData.IFSC !== "") {
          const apiUrl = "https://api.attestr.com/api/v1/public/finanx/acc";

          // Construct request body
          const requestBody = {
            acc: bankData.reAccountNumber,
            ifsc: bankData.IFSC,
            fetchIfsc: false,
          };

          // Construct request headers
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          };
          try {
            // Make POST request to the API using axios
            setLoading(true)

            const response = await axios.post(apiUrl, requestBody, {
              headers: headers,
            });

            if (response.data) {
              setBankData((prevFormData) => ({
                ...prevFormData,
                accountHolderName: response.data.name,
              }));

              setRdOnly(true);

              setErrors({
                ...errors,
                accountHolderName: "",
              });

              setLoading(false);

              // if (response.data.status == "ACTIVE" && response.data.name != "") {
              //   setShowVerifyButton(false);
              // } else {
              //   setShowVerifyButton(true);
              //   toast.dismiss();
              //   toast.error("pls enter correct acc and IFSC");
              // }
            } else {

              setRdOnly(false);

            }
            return response.data;
            // Parse response JSON
          } catch (error) {
            // setLoading(false);
            // console.error('Error fetching account details:', error.message);
            toast.dismiss();
            setLoading(false);
            setRdOnly(false);
            return toast.error("Please Enter correct Account Number and IFSC");
            // return null;
          }
        } else {
          toast.dismiss();
          setRdOnly(false);
          toast.error("All Fields Are Required");
        }
      } catch (error) { }
    } else {
      setErrors({
        ...errors,
        reAccountNumber: "Account Number's Should be Same",
      });

    }
  }

  const handleDrop = (accName) => {
    console.log("BANK SET DATA", bankData);
    setBankData((prevState) => ({ ...prevState, accountType: accName }));
    setErrors({
      ...errors,
      accountType: "",
    });
  }

  const handleBankChange = (event) => {
    handleDrop(event.target.value);
  }

  const handleFileChange = (event, setSelectedFile, setProfileImage, imageErrorStateToUpdate) => {
    const selectedFile = event.target.files[0]; // Get the selected file

    if (selectedFile) {
      // Set the selected file to the state

      if (selectedFile.size > 4000 && selectedFile.size < 200000) {

        setImageError(prevFormData => ({
          ...prevFormData,
          [imageErrorStateToUpdate]: ""
        }));

        setSelectedFile(selectedFile);

        // Create URL for the file and set it to profileImage state
        setProfileImage(URL.createObjectURL(selectedFile));


        const allowedTypes = ['image/jpeg', 'image/png'];

        if (!allowedTypes.includes(selectedFile.type)) {
          toast.error("We Accept Only Image");
          // Reset the input field
          setProfileImage();
          event.target.form.reset();
        }
      }
      else {
        setImageError(prevFormData => ({
          ...prevFormData,
          [imageErrorStateToUpdate]: "Image Size 40 - 200 kb"
        }));
      }


    }
    else {
      console.error("No file selected");
      setImageError((prevData) => ({
        ...prevData,
        proffront: "Image Required"
      }));
    }
  };

  const handelPost = (e) => {

    e.preventDefault();

    console.log("FormData", bankData);

    const emptyFields = {};


    const labelMapping = {
      accountHolderName: "Account Name",
      accountNumber: "Account Number",
      bankName: "Bank Name",
      accountType: "Account Type",
      branchName: "Branch Name",
      IFSC: "IFSC Code",
      reAccountNumber: "Re-Account Number",
    };

    Object.entries(bankData).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors(emptyFields);
      return Promise.resolve();
    }

    if (!profileImage) {
      setImageError((prevData) => ({
        ...prevData,
        proffront: "Image Required"
      }));
    }




    bankSchema
      .validate(bankData, { abortEarly: false })
      .then(() => {
        if (profileImage) {


          // setErrors({});
          return handleBankOTP();
        }

      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });


  }

  const handleBankOTP = async () => {
    if (bankData.accountNumber === bankData.reAccountNumber) {

      if (profileImage) {


        setShowModal(false);

        setErrors({});

        setImageError((prevData) => ({
          ...prevData,
          proffront: ""
        }));

        setLoading(true);

        const result = await postData(Apiservices.USER_BANK_OTP_REST_API, bankData);
        // console.log("========== > ", result);

        if (result.status) {
          toast.success(result.message);
          setCountdown(60);
          setNext(true);
          setLoading(false);

        }
        else {
          setBankData({
            accountHolderName: "",
            accountNumber: "",
            bankName: "",
            accountType: "",
            branchName: "",
            IFSC: "",
            reAccountNumber: "",
          });

          setProfileImage('')
          setSelectedFile('')

          setLoading(false);

          toast.error(result.message);
        }

      }
      else {
        setImageError((prevData) => ({
          ...prevData,
          proffront: "Proof Image Required"
        }));
      }
    }
    else {
      setErrors({
        ...errors,
        accountNumber: "Account Number Should be Same",
        reAccountNumber: "Re-Account Number Should be Same",
      });
    }

  }

  const handleBankSubmit = async () => {

    const formDataToSend = new FormData();


    formDataToSend.append("accountHolderName", bankData.accountHolderName);
    formDataToSend.append("accountNumber", bankData.accountNumber);
    formDataToSend.append("bankName", bankData.bankName);
    formDataToSend.append("accountType", bankData.accountType);
    formDataToSend.append("branchName", bankData.branchName);
    formDataToSend.append("IFSC", bankData.IFSC);
    formDataToSend.append("reAccountNumber", bankData.reAccountNumber);
    formDataToSend.append("otp", getotp);
    formDataToSend.append("image", selectedFile);


    if (otpError == "") {

      setOtpError("");

      setLoading(true);

      const result = await postData(Apiservices.USER_BANK_REST_API, formDataToSend);


      if (result.status) {
        toast.success(result.message);
        setNext(false);
        setLoading(false);
        fetchData();
        setProfileImage('')
        setSelectedFile('')
      }
      else {
        toast.error(result.message);
        setOtpError("Invalid OTP");
        setLoading(false);


      }
    }




    // if (profileImage) {

    //   setImageError((prevData) => ({
    //     ...prevData,
    //     proffront: ""
    //   }));
    // }
    // else {
    //   setImageError((prevData) => ({
    //     ...prevData,
    //     proffront: "Proof Image Required"
    //   }));
    // }


  }

  const handleOtpChange = (e) => {

    const { name, value } = e.target;


    var otpValue = '';

    if (!isNaN(value)) {
      otpValue = value.replace(/\D/g, "");
      setGetOtp(otpValue);
      setOtpError("");
    }
    else if (otpValue.length < 6) {
      setOtpError("Invalid OTP Lenght");
    }
    else {
      setOtpError("Numbers Only");
    }
  };

  const handleShowModal = () => {
    if (kycSts === 2) {

      setRdOnly(false);
      setShowModal(true);
    } else {
      toast.dismiss()
      toast.error("Plese verify your KYC before Add Bank")
    }
  }


  const handleDelete = async (id) => {
    setLoading(true);

    const result = await deleteBankData(Apiservices.USER_DELETE_BANK_REST_API, id);


    if (result.status) {

      setShowDeleteModal(false)
      setLoading(false);
      toast.success(result.message);

      setStoreDataAsArray(
        storeDataAsArray.filter((item) => item.id !== id)
      );

    }
    else {
      toast.error(result.message);
      setShowDeleteModal(false)
      setLoading(false);
      setBankData({});
    }

  }
  const [showEdit, setShowEdit] = useState(false);

  const handleProfileEdit = async () => {
    setShowEdit(true)
  }
  useEffect(() => {
    if (selectedCountry) {
      const country = Country.getAllCountries().find(c => c.isoCode === selectedCountry);
      setFormData((prevData) => ({
        ...prevData,
        country: country.name,
        state: "",
        city: "",
      }));
      setSelectedState(""); // reset state when country changes
      setSelectedCity(""); // reset city when country changes
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const state = State.getStatesOfCountry(selectedCountry).find(s => s.isoCode === selectedState);
      setFormData((prevData) => ({
        ...prevData,
        state: state.name,
        city: "",
      }));
      setSelectedCity(""); // reset city when state changes
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      setFormData((prevData) => ({
        ...prevData,
        city: selectedCity,
      }));
    }
  }, [selectedCity]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "??????}}}}}}}");
    // console.log("FormData", formData);

    // console.log(locationIds)


    const emptyFields = {};

    const labelMapping = {
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      dob: 'DOB',
      address: 'Address',
      country: 'Country',
      state: 'State',
    };

    // console.log()


    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "lastName" && key !== 'city') {
        if (value === "" || value === undefined || value === null) {
          const label = labelMapping[key] || key;
          emptyFields[key] = `${label} is Required`;
        }
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })

      return Promise.resolve();
    }



    profileSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        return handlePost(formData);
      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });
  };

  const handlePost = async (formData) => {
    // console.log("========== > ", formData);


    // const formDataToSend = new FormData();

    // formDataToSend.append("name", formData.firstName);
    // formDataToSend.append("name", formData.middleName);
    // formDataToSend.append("name", formData.lastName);
    // formDataToSend.append("mobileNumber", formData.dob);
    // formDataToSend.append("email", formData.address);
    // formDataToSend.append("city", formData.city);
    // formDataToSend.append("state", formData.state);
    // formDataToSend.append("country", formData.country);
    // formDataToSend.append("image", selectedFile);

    // console.log("========= > ", formData);

    setLoading(true);


    const result = await postData(Apiservices.USER_PROFILE_REST_API, formData);

    if (result.status) {
      toast.success(result.message)
      setLoading(false)

      setShowEdit(false)
    }
    else {




      setShowEdit(false)

      setLoading(true);
      toast.error(result.message)
    }

    // console.log(result, "RESSSS");

  }
  const handleProfileChange = (e) => {
    const { name, value } = e.target;

    let filteredValue = value;

    // if (name !== "firstName") {
    //   filteredValue = value.replace(/[^a-zA-Z]/g, "");
    // }
    // if (name !== "middleName") {
    //   filteredValue = value.replace(/[^a-zA-Z]/g, "");
    // }
    // if (name !== "lastName") {
    //   filteredValue = value.replace(/[^a-zA-Z]/g, "");
    // }
    // if (name !== "address") {
    //   filteredValue = value.replace(/\s/g, "");
    // }

    // if (name === "firstName") {
    //   filteredValue = value.trimStart();
    // }
    // if (name === "middleName") {
    //   filteredValue = value.trimStart();
    // }
    // if (name === "lastName") {
    //   filteredValue = value.trimStart();
    // }



    if (name === "firstName" || name === "middleName" || name === "lastName") {
      // Remove non-letter characters and trim leading spaces
      filteredValue = value.replace(/[^a-zA-Z\s]/g, "").trimStart();
    }


    if (name === "address") {
      filteredValue = value.trimStart();
    }

    // Assuming 'name' and 'value' are already defined

    // if (name === "dob") {
    //   // Assuming value contains the date string
    //   filteredValue = value.replace(/(\d{2})(\d{2})(\d{4})/, "$1-$2-$3");
    // }
    if (name === "dob") {
      // Handle backspace correctly
      if (value < formData.dob) {
        // If the user is deleting, allow it to happen normally
        filteredValue = value;
      } else {
        // Remove all non-numeric characters first
        filteredValue = value.replace(/[^\d]/g, "");

        // Add dashes for date formatting
        if (filteredValue.length >= 2) {
          filteredValue = filteredValue.slice(0, 2) + '-' + filteredValue.slice(2);
        }
        if (filteredValue.length >= 5) {
          filteredValue = filteredValue.slice(0, 5) + '-' + filteredValue.slice(5);
        }
        if (filteredValue.length > 10) {
          filteredValue = filteredValue.slice(0, 10); // Ensure max length of 10
        }
      }
    }

    // if (name === "dob") {
    //   filteredValue = value.replace(/[^\d-]/g, ""); 
    // }


    console.log(formData, "{{{{{{{}}}}}}}}");

    // console.log(filteredValue)


    setFormData({ ...formData, [name]: filteredValue });

    profileSchema
      .validateAt(name, { [name]: filteredValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };


  const handleOTPClose = () => {
    setNext(false);
    setBankData({
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      accountType: "",
      branchName: "",
      IFSC: "",
      reAccountNumber: "",
    });
    setSelectedFile('')
    setProfileImage('');
    setRdOnly(false);
  };



  return (


    <>
      {profilePage ?

        <>

          {loading ? (
            <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
          ) : (
            <>
              <Modal
                show={showModal}
                className="sec_mod"
                backdrop="static"
                centered="bool"
              >
                <Modal.Header closeButton onClick={modClose}>
                  <Modal.Title>Add Bank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='Bank_det'>
                    <form>
                      <div className="inptbx  ">
                        <input
                          autoComplete='off'
                          type="text"
                          placeholder="IFSC Code"
                          name="IFSC"
                          value={bankData.IFSC}
                          onChange={handleChange}
                          readOnly={rdonly}
                        />
                        <p id="error" className="dummy">{errors.IFSC}</p>
                      </div>

                      <div className="inptbx  ">
                        <input
                          type="text"
                          autoComplete='off'
                          placeholder="Bank Name"
                          name="bankName"
                          value={bankData.bankName}
                          onChange={handleChange}
                          readOnly
                        />
                        <p id="error" className="dummy">{errors.bankName}</p>
                      </div>

                      <div className="inptbx  ">
                        <input
                          type="text"
                          autoComplete='off'
                          placeholder="Branch Name"
                          name="branchName"
                          value={bankData.branchName}
                          onChange={handleChange}
                          readOnly
                        />
                        <p id="error" className="dummy">{errors.branchName}</p>
                      </div>



                      <div className="inptbx  ">
                        <input
                          type="text"
                          autoComplete='off'
                          placeholder="Account Number"
                          name="accountNumber"
                          value={bankData.accountNumber}
                          onChange={handleChange}
                          readOnly={rdonly}
                        />
                        <p id="error" className="dummy">{errors.accountNumber}</p>
                      </div>

                      <div className="inptbx">
                        <input
                          type="text"
                          autoComplete='off'
                          placeholder="Re-Enter Account Number"
                          name="reAccountNumber"
                          value={bankData.reAccountNumber}
                          onChange={handleChange}
                          readOnly={rdonly}
                        />

                        {rdonly ?
                          (
                            <>
                            </>
                          )
                          :
                          (
                            <button
                              className='closeeye'
                              variant="secondary"
                              type="button"
                              onClick={fetchAccountDetails}
                            >
                              Verify
                            </button>
                          )
                        }


                        <p id="error" className="dummy">{errors.reAccountNumber}</p>
                      </div>


                      <div className="inptbx  ">
                        <input
                          type="text"
                          autoComplete='off'
                          placeholder="Account holder Name"
                          name="accountHolderName"
                          value={bankData.accountHolderName}
                          onChange={handleChange}
                          readOnly
                        />
                        <p id="error" className="dummy">{errors.accountHolderName}</p>
                      </div>

                      <div className="drop_bx">
                        <select className="sel_cont" name='accountType' onChange={handleBankChange}>
                          <option value=''>Select Account Type</option>
                          <option value="Savings">Savings</option>
                          <option value="Current">Current</option>
                        </select>
                        <p id="error" className="dummy">{errors.accountType}</p>
                      </div>

                      <div className="upl">
                        <label>
                          <input name='proofFront' onChange={(event) => handleFileChange(event, setSelectedFile, setProfileImage, "proffront")} type='file'></input>
                          {profileImage ? <p><img src={profileImage} width={150} draggable='false'></img></p> : <p><img src="images/upl.svg" draggable='false'></img><span>Upload Bank Proof Front Image</span></p>}
                        </label>
                        <p id="error">{imageError.proffront}</p>
                      </div>
                      <button type='button' className='btns' onClick={handelPost}>Confirm</button>
                    </form>

                  </div>
                </Modal.Body>

              </Modal >

              <Modal
                show={showNext}
                className="sec_mod bankotp"
                backdrop="static"
                centered
              >
                <Modal.Header closeButton onClick={handleOTPClose}>
                  <Modal.Title>Authentication - Banking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="verify">
                    <div className="veri_blk">
                      <div className="veri_otp">
                        <p>
                          Enter the 6 digit code sent to
                        </p>
                        <p><strong>{formData.email}</strong></p>
                        <span>Don’t see it? Check your spam folder</span>
                        <div className="inptbx mobl">
                          <input
                            value={getotp}
                            onChange={handleOtpChange}
                            id="eye"
                            type="text"
                            placeholder="Bank - OTP"
                            name="otp"
                            maxLength={6}
                            autoComplete='off'
                          />
                          <p id="error">{otpError}</p>

                          {countdown <= 0 ? (
                            <button
                              className='closeeye'
                              variant="secondary"
                              onClick={() => handleResend()}
                            >
                              Resend
                            </button>
                          ) : null}
                        </div>
                        <p>
                          Haven’t received it yet? Resend in <span>{countdown <= 0 ? '0' : countdown} seconds</span>
                        </p>
                        <p>
                          If the email address entered is wrong, logout and signup again
                          with the correct email address
                        </p>
                        <div className='d-flex justify-content-center'>
                          <button type="button" className="btns " onClick={handleBankSubmit}>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>


              {showDeleteModal && (
                <Modal
                  show={showDeleteModal}
                  onHide={() => setShowDeleteModal(false)}
                  className="sec_mod semod"
                  backdrop="static"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="to_fa to_fas">
                      <h1>Are you sure want to delete?</h1>
                      <div className="modal-buttons">
                        <Button variant="btn btn-success" onClick={() => handleDelete(itemIdToDelete)}>
                          Delete
                        </Button>
                        <Button
                          variant="btn btn-success"
                          onClick={() => setShowDeleteModal(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              <Modal
                show={showEdit}
                className="sec_mod bankotp editper"
                backdrop="static"
                centered
              >
                <Modal.Header closeButton onClick={modClose}>
                  <Modal.Title>Profile Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    < div className="verimobile person_inf">



                      <div className="personal_frm">

                        <div className="inptbx  ">
                          <input type="text"
                            autoComplete='off'

                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleProfileChange}
                          />
                          <p id="error">{errors.firstName}</p>
                        </div>
                        <div className="inptbx  ">
                          <input type="text"
                            autoComplete='off'

                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleProfileChange}
                          />
                          <p id="error">{errors.lastName}</p>
                        </div>


                        <div className="inptbx  ">
                          <input type="text"
                            autoComplete='off'

                            placeholder="Date of Birth (DD-MM-YYYY) "
                            name="dob"
                            value={formData.dob}
                            maxLength={10}
                            onChange={handleProfileChange}
                          />
                          <p id="error">{errors.dob}</p>
                        </div>

                        <div className="inptbx  ">
                          <textarea placeholder="Address"
                            className="msg"
                            name="address"
                            value={formData.address}
                            onChange={handleProfileChange}
                          >

                          </textarea>
                          <p id="error">{errors.address}</p>
                        </div>

                        <div className="drop_bx">


                          <select
                            className="sel_cont"
                            value={selectedCountry ? (selectedCountry) : (formData.country)}
                            onChange={(e) => {
                              setSelectedCountry(e.target.value)

                              setErrors({ ...errors, country: "" });


                            }}
                            placeholder="Country Of Residence"
                          >
                            <option value="">{formData.country}</option>
                            {countries.map((country) => (
                              <option key={country.isoCode} value={country.isoCode}>
                                {country.name}
                              </option>
                            ))}
                          </select>

                          <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>

                          <p id="error">{errors.country}</p>
                        </div>

                        <div className="drop_bx">


                          <select
                            className="sel_cont"
                            value={selectedState}
                            onChange={(e) => {
                              setSelectedState(e.target.value)

                              setErrors({ ...errors, state: "" });


                            }}
                            disabled={!selectedCountry}
                            placeholder="State Of Residence"
                          >
                            <option value="">{formData.state}</option>
                            {states1.map((state) => (
                              <option key={state.isoCode} value={state.isoCode}>
                                {state.name}
                              </option>
                            ))}
                          </select>

                          <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>

                          <p id="error">{errors.state}</p>
                        </div>


                        <div className="drop_bx">



                          <select
                            className="sel_cont"
                            value={selectedCity}
                            onChange={(e) => {
                              setSelectedCity(e.target.value)

                              setErrors({ ...errors, city: "" });


                            }}
                            disabled={!selectedState}
                            placeholder="City Of Residence"
                          >
                            <option value="">{formData.city}</option>
                            {cities.map((city) => (
                              <option key={city.name} value={city.name}>
                                {city.name}
                              </option>
                            ))}
                          </select>

                          <span className="closeeye"><img src="images/drddwn.svg" alt="dropdown" draggable='false'></img></span>

                          <p id="error">{errors.city}</p>
                        </div>

                        <button type='submit' className='col-4  btns' onClick={handleSubmit}>SUBMIT </button>

                      </div>

                    </div>
                  </form>

                </Modal.Body>
              </Modal>
              <div className='profile'>
                <div className='container'>
                  <div className='accp'>
                    <div className='accp d-flex justify-content-between'>
                      <strong >Profile Info</strong>
                      <div>
                        <button type="button " className="btn btn-success fs-6 px-4" onClick={() => navigate('/buyCryptoPage')}>Buy Crypto</button>
                      </div>
                    </div>
                    <div className='prof_acc'>
                      <strong>Email : </strong><span>{formData.email}</span>
                      <p><strong>Referral ID : </strong> <span>{formData.referralID}</span></p>
                    </div>
                  </div>
                  <div className='accp'>
                    <strong >Personal Details</strong>
                    <div className='prof_acc'>
                      <div className='profil_conts'>
                        <ul className='prfi'>
                          <li><span>Name</span><span>:</span><span>{formData.firstName ? `${formData.firstName} ${formData.lastName}` : <span>N/A</span>}</span></li>
                          <li><span>Mobile Number</span><span>:</span> <span>{formData.mobineNumber ? '+' + formData.mobineNumber : <span>N/A</span>}</span></li>
                          <li className='add'><span>Address</span><span>:</span> <span>{formData.address ? formData.address : <span>N/A</span>}</span></li>
                          <li><span>D.O.B</span><span>:</span> <span>{formData.dob ? formData.dob : <span>N/A</span>}</span></li>
                          <li><span>City</span> <span>:</span><span>{formData.city ? formData.city : <span>N/A</span>}</span></li>
                          <li><span>State</span> <span>:</span><span>{formData.state ? formData.state : <span>N/A</span>}</span></li>
                          <li><span>Country</span> <span>:</span><span>{formData.country ? formData.country : <span>N/A</span>}</span></li>
                        </ul>

                        <div className='profil_rgt'>
                          <div className={`profc ${kycSts === 1 ? 'ped' : ''} ${kycSts === 2 ? 'ver' : ''} ${kycSts === 3 ? 'rej' : ''}`}>
                            <strong>KYC</strong>
                            <h1>{kycSts === 1 ? 'Pending' : ''}{kycSts === 2 ? 'Verified' : ''}{kycSts === 3 ? 'Rejected' : ''}</h1>
                            <p>{kycSts === 1 ? 'Your KYC verification is pending. We will notify you once its complete. Thank you for your patience.' : ''}{kycSts === 2 ? 'Your KYC verification is successful! Welcome aboard, your security is our priority.' : ''}{kycSts === 3 ? 'your KYC verification has been rejected. Please review and resubmit for further processing' : ''}</p>
                            <p>{kycSts === 1 || kycSts === 2 || kycSts === 3 ? '' : 'Remember, completing your KYC ensures uninterrupted access to our services. Tap here to finish now.'}</p>

                            {kycSts === 1 || kycSts === 2 ?
                              <>
                              </>
                              :
                              <button type="button" className='btns' onClick={handleTFAEnable}>Get Started</button>
                            }
                          </div>
                        </div>
                        <div className='prf_bt'>
                          <button className=" btns" onClick={handleProfileEdit}>Edit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accp'>
                    <div className='ac_cps'>
                      <strong>Bank Details</strong>
                      {/* <button type='button' className='btns' onClick={modClick}>+ Add Bank</button> */}
                      {storeDataAsArray.length <= 1 ?

                        (<button type="button " className="btns" onClick={handleShowModal}>+ Add Bank</button>)

                        :
                        (
                          <>
                          </>
                        )

                      }

                    </div>
                    <>

                      {storeDataAsArray.length === 0 ? (
                        <div className='profcd justify-content-center gs'>
                          <p>No Record Found</p>
                        </div>
                      ) : (
                        <>
                          {storeDataAsArray.length <= 3 ? (
                            <div className='profcd'>
                              {storeDataAsArray.map((item, index) => (
                                <div className='prof_acc mb-4' key={index}>
                                  <div className='profil_conts prod'>
                                    <ul>
                                      <li><span>Bank Name</span><span>:</span><span>{item.bankName}</span></li>
                                      <li><span> Branch Name</span><span>:</span> <span>{item.branchName}</span></li>
                                      <li><span> IFSC Code</span><span>:</span> <span>{item.IFSC}</span></li>
                                      {/* <li><span>D.O.B</span><span>:</span> <span>{item.bankName}</span></li> */}
                                      <li><span>Account Number</span><span>:</span> <span>{item.accountNumber}</span></li>
                                      <li><span>Account Holder Name</span><span>:</span> <span>{item.accountHolderName}</span></li>
                                    </ul>
                                    <div className='profil_rgt'>
                                      <div className='profil_card'>
                                        <img src='images/atm.png' alt="atm" draggable='false'></img>
                                        <strong className='bk'>{item.bankName}</strong>
                                        <strong className='num'>{item.accountNumber}</strong>
                                        <div className='bdc'>
                                          <ul>
                                            <li>{item.accountHolderName}</li>
                                            <li>{item.IFSC}</li>
                                            <li>{item.branchName}</li>
                                          </ul>
                                          <ul>
                                            {item.bankVerifyStatus === 1 ? <li><button type='button'><img src='images/bk_p.svg' alt='pending' draggable="false"></img></button></li> : ""}
                                            {item.bankVerifyStatus === 2 ? <li><button type='button'><img src='images/bk_v.svg' alt='pending' draggable="false"></img></button></li> : ""}
                                            {item.bankVerifyStatus === 3 ? <li><button type='button'><img src='images/bk_r.svg' alt='pending' draggable="false"></img></button></li> : ""}
                                            <li onClick={() => {
                                              // handleDelete(item.id)
                                              setShowDeleteModal(true)
                                              setItemIdToDelete(item.id)
                                            }}><button type='button'><img src='images/delete_icon.svg' alt='delete' draggable="false"></img></button></li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </>
                      )}

                    </>
                  </div>
                </div>
              </div>


            </>
          )}
        </>

        :

        <>
          <Modal
            show={showLast}
            className="sec_mod"
            backdrop="static"
            centered="bool"
          >
            <Modal.Header>
              <Modal.Title>Warning!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa to_fas">
                <img src="images/excliam.svg" alt="tick" draggable='false'></img>
                <strong>Complete Your Profile & KYC Before Proceed </strong>
                {/* <p>Complete Your Profile & KYC Before Proceed </p> */}
                <button type="button" className="btns" onClick={handleTFAEnable}>Go To Verification</button>
                {/* <button type="button" className="btns" onClick={() => navigate('/verification')}>Go To Verification</button> */}
                {/* const response = await postData(Apiservices.USER_SKIP_API, { kycSkip: true }) */}
              </div>
            </Modal.Body>

          </Modal>
        </>


      }



    </>





  )
}

export default Profile

