import React, { useState } from 'react'
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { postData, postGetData } from '../../../AxiosInstance/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Header from '../../header/Header';

function BuycryptoHistory() {

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items to display per page
  const [formData, setFormData] = useState([]);
  const pageCount = Math.ceil(formData.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const getBuyHis = async () => {

    const response = await postGetData(Apiservices.USER_BUYCRYPTO_HISTORY);
    if (response.data) {
      setFormData(response.data);

    } else {
    }

  }


  // useEffect(() => {
  //   axiosInstance

  //     .post(`/getUserCryptoDetails`)
  //     .then((response) => {

  //     .catch((error) => {
  //       console.error("Error fetching user data:", error);
  //     });
  // }, []);
  useCustomEffect(getBuyHis)


  const utcToNormalTime = (utcString) => {
    const date = new Date(utcString);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
    return { date: formattedDate, time: formattedTime };
  };
  const [filteredFormData, setFilteredFormData] = useState([]);

  const handleSearchInputChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);

    const filteredData = formData.filter((data) => {
      // Extract the fields you want to filter
      const fieldsToFilter = [
        data.account_number,
        data.ifsc,
        data.admin_accountNo,
        data.admin_ifsc,
        data.from_currency,
        data.fcur_amount,
        utcToNormalTime(data.createdAt).date,
        utcToNormalTime(data.createdAt).time
      ];

      // Check if any of the fields contain the search query
      const found = fieldsToFilter.some((value) => {
        const valueString = value.toString().toLowerCase();
        return valueString.includes(searchQuery);
      });

      return found;
    });

    setFilteredFormData(filteredData);
  };



  // Filtered data based on search query


  const fieldsToFilter = [
    'account_number',
    'ifsc',
    'admin_accountNo',
    'admin_ifsc',
    'from_currency',
    'fcur_amount',
  ];

  const filteredData = formData.filter((data) =>
    fieldsToFilter.some((field) =>
      data[field].toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  // Then use filteredData for pagination
  const displayedData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleKeyDownForSearch = (e) => {

    if (
      !(
        e.key === "Backspace" ||
        /^[a-zA-Z0-9]*$/.test(e.key) ||
        (e.key === "." && !e.target.value.includes("."))
      )
    ) {
      e.preventDefault();
    }
  };








  return (
    <>
      <Header />
      <div className='buychis'>
        <div className='container'>
          <div className='buychead'>
            <strong>Buy Crypto History</strong>
            <div className='inptbx'>
              <input type="text" className="searchbar"
                value={searchQuery}
                onChange={handleSearchInputChange}
                onKeyDown={handleKeyDownForSearch}
                maxLength={25}
              />
            </div>
          </div>
          <div className='table-responsive con'>

            <table>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Date & Time</th>
                  <th>From &nbsp;  Account Number</th>
                  <th>From  &nbsp; IFSC Code</th>
                  <th>To  &nbsp; Account Number</th>
                  <th>To  &nbsp; IFSC Code</th>

                  <th>Currency</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.length > 0 ? (
                  displayedData.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div>{utcToNormalTime(data.createdAt).date}</div>
                        <div>{utcToNormalTime(data.createdAt).time}</div>
                      </td>
                      <td>{data.account_number}</td>
                      <td>{data.ifsc}</td>
                      <td>{data.admin_accountNo}</td>
                      <td>{data.admin_ifsc}</td>
                      <td>{data.from_currency}</td>
                      <td>{data.fcur_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="notr py-2 w-100 ">
                    <td colSpan="8">No Records Found</td>
                  </tr>
                )}
              </tbody>

            </table>

          </div>
          {displayedData.length > 10 &&
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageLinkClassName={"page-link"}
              breakLinkClassName={"break-link"}
              disabledClassName={"disabled"}
              initialPage={currentPage}
              disableInitialCallback={true}
              forcePage={currentPage}
              // disableInitialCallback={true}
              renderLink={(props) => {
                if (
                  props.page === "..." &&
                  props.pageCount > props.pageRangeDisplayed
                ) {
                  return (
                    <a
                      {...props}
                      onClick={() =>
                        handlePageClick({
                          selected: pageCount - 1,
                        })
                      }
                    >
                      {pageCount}
                    </a>
                  );
                }
                return <a {...props} />;
              }}
            />
          }

        </div>
      </div>
    </>
  )
}

export default BuycryptoHistory