import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Accordion } from 'react-bootstrap';
import { changeScheema, tfaSchema } from '../../../Yup';
import { useNavigate } from "react-router-dom";
import { postGetData, postData } from "../../../AxiosInstance/AxiosInstance";
import { Apiservices } from "../../../ApiServices/ApiServices";
import { toast } from 'react-toastify';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import Header from '../../header/Header';
import QRCode from 'qrcode';
import Loader from 'react-js-loader';
import Cookies from "js-cookie";

const Security = () => {
  const [showModal, setShowModal] = useState(false);
  const modClick = () => {
    setShowModal(true)
    console.log("modal")
  }


  const [loading, setLoading] = useState(false);
  const [getotp, setGetOtp] = useState("");
  const [otpurl, setOtpUrl] = useState("");
  const [imageqr, setQrImageUrl] = useState("");
  const [tfStatus, setTfStatus] = useState(true);
  const [otpError, setOtpError] = useState("");

  const navigate = useNavigate();
  // const [showOldPassword,setShowOldPassword]=useState();
  // const [showPassword, setShowPassword] = useState(false);
  // const [showConPassword, setShowConPassword] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [countdown, setCountdown] = useState(60);

  const [email, setEmail] = useState({
    email: '',
    phoneNumber: null

  });

  const [showPassword, setShowPassword] = useState(false);

  const [tfa, setTfa] = useState({
    tfaCode: '',

  });










  const getUser = async () => {

    const response = await postGetData(Apiservices.USER_GETUSER_REST_API);
    if (response.status) {
      if (response.user) {
        setQrImageUrl(response.user.otpauth_url);
        setTfStatus(response.user.tfStatus);
        setEmail(response.user.email)
        setEmail((prevData) => ({
          ...prevData,
          email: response.user.email,
          phoneNumber: response.user.mobileNumber

        }))
        if (!response.tfStatus) {
          generateQRCode(response.user.otpauth_url);

        }
      } else {
        console.error('User data not found in the response.');
        setTfStatus(response.tfStatus);
      }


    }

  }

  useEffect(() => {
    let intervalId;

    if (showModal && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : prevCountdown));
      }, 1000);
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [showModal, countdown]);


  // function startCountdown() {
  //   const intervalId = setInterval(() => {
  //     setCountdown(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : prevCountdown));
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }

  useCustomEffect(() => {
    getUser()
    // startCountdown(setCountdown)
  });



  const handleTfaChange = e => {
    const { name, value } = e.target;

    let sanitizedValue = value;

    if (name == "tfaCode") {
      sanitizedValue = value.replace(/[\s\D]/g, '');
    }
    else {
      sanitizedValue = value.replace(/\s/g, '');
    }


    setTfa({ ...tfa, [name]: sanitizedValue });

    tfaSchema.validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch(error => {
        setErrors({ ...errors, [name]: error.message });
      });

  };




  const generateQRCode = otpUrl => {
    QRCode.toDataURL(otpUrl)
      .then(url => {
        setQrImageUrl(url);
      })
      .catch(error => {
        console.error('Error generating QR code:', error);
      });
  };
  const handletfa = async () => {
    const data = {
      tfaCode: tfa.tfaCode,
      tfStatus: !tfStatus, // Toggle the status
    };

    const emptyFields = {};
    const labelMapping = {
      tfaCode: 'TFA Code',
    };

    Object.entries(tfa).forEach(([key, value]) => {
      if (!value) { // Handles empty string, undefined, and null
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields });
      return;
    }

    try {
      setLoading(true);
      await tfaSchema.validate(data, { abortEarly: false });
      const response = await postData(Apiservices.USER_ENABLE_TFA, data);

      if (response.status) {
        toast.success(response.message);
        setTfStatus(!tfStatus);
        setTfa({ tfaCode: '' });
        setNext(false);
      } else {
        toast.dismiss();
        toast.error(response.message);
        setErrors({ ...errors, tfaCode: "" });
      }
    } catch (error) {
      console.error('An error occurred while posting CMS data:', error.message);
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error('An error occurred while processing your request.');
      }
    } finally {
      setLoading(false);
    }
  };



  // const handletfa = async () => {

  //   const data = {
  //     tfaCode: tfa.tfaCode,
  //     tfStatus: !tfStatus, // Toggle the status
  //   };
  //   console.log(data,"^$^$&&$&$&");
  //   const emptyFields = {};

  //   const labelMapping = {
  //     tfaCode: 'TFA Code',

  //   };

  //   Object.entries(tfa).forEach(([key, value]) => {
  //     if (value === "" || value === undefined || value === null) {
  //       const label = labelMapping[key] || key;
  //       emptyFields[key] = `${label} is Required`;
  //     }
  //   });

  //   if (Object.keys(emptyFields).length > 0) {
  //     setErrors({ ...errors, ...emptyFields })
  //     return; // No need to return Promise.resolve(), just return
  //   }

  //   try {
  //     setLoading(true);
  //     await tfaSchema.validate(data, { abortEarly: false });
  //     const response = postData(Apiservices.USER_ENABLE_TFA, data);
  //     if (response.status) {
  //       toast.success(response.message);
  //       setTfStatus(!tfStatus);
  //       setLoading(false);
  //       setTfa({ tfaCode: '' });
  //       setNext(false)
  // console.log(response.status,"ewrerer");
  //     }
  //     else{
  //       setLoading(false);
  //       toast.dismiss()
  //       toast.error(response.message);
  //       setErrors({ ...errors, tfaCode:"" });
  //       // setNext(false)
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while posting CMS data:', error.message);
  //     setLoading(false);
  //     if (error.inner) {
  //       const errors = {};
  //       error.inner.forEach(err => {
  //         errors[err.path] = err.message;
  //       });
  //       setErrors(errors);
  //     } else {
  //       toast.error('An error occurred while processing your request.');
  //     }
  //   }
  // };





  const handleChange = (e) => {
    const { name, value } = e.target;

    let sanitizedValue = value.replace(/\s/g, '');

    setFormData({ ...formData, [name]: sanitizedValue });


    changeScheema
      .validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handleOtpChange = (e) => {
    const otpValue = e.target.value;

    if (!isNaN(otpValue)) {
      const sanitizedOtp = otpValue.replace(/\D/g, "");
      setGetOtp(sanitizedOtp);
      console.log('!!', getotp);

    } else {
      setErrors("Numbers Only");
    }
  };

  const handleOTP = async () => {
    if (getotp.trim() === "") {
      setOtpError("OTP is required");
    } else if (getotp.length !== 6) {
      setOtpError("OTP length should be 6");
    } else {
      setLoading(true)

      const data = {
        oldPassword: formData.oldPassword,
        newPassword: formData.password, // update newPassword when not work
        otp: getotp,
      };
      const response = await postData(Apiservices.USER_CHANGE_PASSWORD_OTP_API, data);


      if (response.status) {
        localStorage.clear();
        setLoading(false);
        toast.success(response.message);

        setFormData({
          oldPassword: '',
          password:'',
          confirmPassword: ''
        })

        Cookies.remove("jwtToken");
        navigate("/auth");
        // handleSignout()
      } else {
        setLoading(false);
        toast.error(response.message);
        setGetOtp('')
        // setFormData({});
      }


    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    changeScheema
      .validateAt(name, { [name]: formData[name] })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handlePost = async () => {
    setLoading(true);

    const data = {
      oldPassword: formData.oldPassword,
      newPassword: formData.password,
    };
    const response = await postData(Apiservices.USER_CHANGE_PASSWORD_API, data);

    if (response.status) {
      setLoading(false);
      toast.success(response.message);

      setShowModal(true);
      setGetOtp("")
      setCountdown(60)
    } else {
      setLoading(false);
      toast.error(response.message);
      setShowModal(false);
    }


  }

  // const handleSignout = () => {
  //   Cookies.remove("jwtToken");
  //   navigate("/auth");
  // }




  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyFields = {};


    const labelMapping = {
      oldPassword: "Old Password",
      password: "New Password",
      confirmPassword: "Confirm Password",
    };
    Object.entries(formData).forEach(([key, value]) => {
      if (value === "" || value === undefined || value === null) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })
      return Promise.resolve();
    }


    changeScheema
      .validate(formData, { abortEarly: false })
      .then(() => {
        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
          setErrors({
            confirmPassword: "Password do not match",
          });
        }
        else if (formData.oldPassword === formData.password) {
          setErrors({
            password: "New Password and Old Password is same"
          })
        }
        else {
          setErrors({});
          setLoading(true);
          handlePost();


        }
      })
      .catch((error) => {
        // Handle validation errors
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });
  };
  console.log(errors, "%#%^$&$*&&$&$&$&&$");

  const modClose = () => {
    setShowModal(false)
  }
  const modsbClose = () => {
    setNext(false)
  }
  const [showNext, setNext] = useState(false);
  const handleShow = () => {
    setNext(true)
  }

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <>
      {loading ? (
        <Loader type="box-rectangular" bgColor={"#1A8F5C"} size={50} />
      ) : (
        <>


          <Modal
            show={showNext}
            className="sec_mod"
            backdrop="static"
            centered="bool"
          >
            <Modal.Header closeButton onClick={modsbClose}>
              <Modal.Title >2FA Setup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="to_fa to_fas">
                <strong>Two-Factor Authentication</strong>
                <p>Enter 6 Digit Authentication Code</p>
                <div className="inptbx  ">
                  {tfStatus ? null : <img src={imageqr} alt='QR Code' draggable="false" />}

                  <input
                    onChange={handleTfaChange}
                    value={tfa.tfaCode}
                    name='tfaCode'
                    type='text'
                    maxLength={6}
                    autoComplete='off'

                  />

                  {/* <input
                    type="text"
                    autoComplete="off"
                    name="tfa"
                    value={tfa}
                    // onChange={handleTfaChange}
                    maxLength={6}
                  /> */}

                  <p id="error">{errors.tfaCode}</p>
                </div>

                <button type="button" className="btns" onClick={handletfa}>SUBMIT</button>
                {/* <p>Didn’t receive OTP yet? <br></br>
              Resend in <span>55 Seconds</span></p> */}

              </div>
            </Modal.Body>

          </Modal>

          {showModal && countdown > 0 && (


            <Modal
              show={showModal}

              className="sec_mod password"
              backdrop="static"
              centered="bool"
            >
              <Modal.Header closeButton onClick={modClose}>
                <Modal.Title>Enter OTP NUMBER</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="verify ">

                  <div className="veri_blk">
                    <div className="veri_otp">
                      <p>
                        Enter the 6 digit code sent to

                      </p>
                      <span>Don’t see it? Check your spam folder</span>
                      <div className="inptbx mobl ">
                        <input
                          value={getotp}
                          onChange={handleOtpChange}
                          type="text"
                          placeholder="Enter OTP"
                          name="otp"
                          maxLength={6}
                        />
                        <p id="error">{otpError}</p>

                        {/* <span className="closeeye">RESEND</span> */}
                      </div>
                      <p>
                        Haven’t received it yet? Resend in <span> {countdown} seconds</span>
                      </p>
                      <p>
                        If the old password entered is wrong, enter again
                        with the correct password
                      </p>
                    </div>


                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>




                <Button
                  variant="btn btn-success"
                  onClick={() => handleOTP()}

                  className="btns"
                >
                  SUBMIT
                </Button>



              </Modal.Footer>
            </Modal>
          )}





          <div className='secu_set'>
            <div className='container'>
              <div className='twofa_set'>
                <strong>Two-Factor Authentication (2FA)</strong>
                <ul>
                  <li><img src="images/googleauth.svg" alt="google Authentication" draggable="false"></img><span><img src='images/gogled.svg' alt='google Authentication' draggable="false"></img></span> <p><strong>Google Authenticator</strong><span>Protect  your account and transactions</span></p></li>
                  {tfStatus ? (

                    <li><p><img src="images/ticksgre.svg" alt="not-linked" draggable="false"></img><span>Linked</span></p></li>
                  ) : (
                    <li><p><img src="images/ticksgr.svg" alt="not-linked" draggable="false"></img><span>Not Linked</span></p></li>

                  )}

                  <li>

                    <button
                      type='button'

                      className={tfStatus ? 'chan' : 'btns'}
                      onClick={handleShow}
                    >
                      {tfStatus ? 'Disable' : 'Enable'}
                    </button>
                    {/* <button type="button" className='btns'>Enable</button> */}
                  </li>
                </ul>
                <ul>
                  <li><img src="images/emailset.svg" alt="Email" draggable="false"></img><span><img src='images/maild.svg' alt='mail' draggable="false"></img></span> <p><strong>Email</strong><span>Protect your account and transactions</span></p></li>
                  <li ><p><img src='images/ticksgre.svg' alt='ticks' draggable="false"></img><span>{email.email}</span></p></li>
                  <li className='chan'>
                    {/* <button type="button" className='btns'>Change</button> */}
                  </li>
                </ul>
                <ul>
                  <li><img src="images/phone.svg" alt="phone" draggable="false"></img><span><img src='images/mobiled.svg' alt='mobile' draggable="false"></img></span> <p><strong>Phone</strong><span>Protect your account and transactions</span></p></li>
                  {email.phoneNumber ? (
                    <li><p><img src="images/ticksgre.svg" alt="not-linked" draggable="false"></img><span>Linked</span></p></li>
                  ) : (
                    <li><p><img src="images/ticksgr.svg" alt="not-linked" draggable="false"></img><span>Not linked</span></p></li>

                  )}
                  <li></li>
                </ul>
              </div>
              <div className='twofa_set security_set'>
                <strong>Advanced Security Settings</strong>
                <Accordion >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <ul>
                        <li><img src="images/chanpas.svg" alt="login password" draggable="false"></img><span><img src='images/bllogin.svg' alt='login password' draggable="false"></img></span> <p><strong>Login Password</strong><span>You will be logged out automatically after changing your password</span></p></li>

                        <li className='chan'><button type="button" className='btns'>Change</button></li>
                      </ul>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='change_password'>
                        <div className="inptbx  cpeye">
                          <span>Old Password</span>
                          <input
                            onBlur={handleBlur}
                            type={showPassword ? "text" : "password"}
                            value={formData.oldPassword}
                            name="oldPassword"
                            onChange={handleChange}
                            autoComplete='off'


                          />
                          <p id="error">{errors.oldPassword}</p>
                          <span className='closeeye' onClick={handleTogglePassword}>
                            {showPassword ? (
                              <img src='images/openeye.svg' alt="eye" draggable="false" />
                            ) : (
                              <img src='images/closeye.svg' alt='eye' draggable="false"></img>
                            )}
                          </span>
                        </div>
                        <div className="inptbx  ">
                          <span>New Password</span>
                          <input
                            onBlur={handleBlur}
                            type="text"
                            value={formData.password}
                            name="password"
                            onChange={handleChange}
                            autoComplete='off'

                          />
                          <p id="error">{errors.password}</p>

                        </div>
                        <div className="inptbx  ">
                          <span>Confirm Password</span>
                          <input
                            type="text"
                            value={formData.confirmPassword}
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete='off'


                          />
                          <p id="error">{errors.confirmPassword}</p>

                        </div>
                      </div>
                      <button type='button' className='btns' onClick={handleSubmit}>SUBMIT</button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                {/* <ul>
              <li><img src="images/withdraw.svg" alt="withdraw"></img><span><img src='images/blwithdraw.svg' alt='black_withdraw'></img></span> <p><strong>Withdrawal Settings</strong><span>Once this feature is enabled , you will only be able to withdraw to the address in your whitelist</span></p></li>

              <li className='chan'><button type="button" className='btns'>More</button></li>
            </ul> */}

              </div>
              {/* <div className='twofa_set advance'>
            <strong>Devices and  Activities</strong>
            <ul>
              <li><img src="images/device.svg" alt="account activity"></img><span><img src='images/deviceb.png' alt="device"></img></span> <p><strong>Device Management</strong><span>Manage allowed device list</span></p></li>

              <li className='chan'><button type="button" className='btns'>More</button></li>
            </ul>
            <ul>
              <li><img src="images/acc_act.svg" alt="account activity"></img><span><img src='images/acctbl.png' alt="device"></img></span> <p><strong>Account Activity</strong></p></li>

              <li className='chan'><button type="button" className='btns'>More</button></li>
            </ul>

          </div> */}
            </div>

          </div>

        </>
      )}
    </>
  )
}

export default Security