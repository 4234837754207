import React, { useState } from "react";
import Header from "../../header/Header";
import { resetPassword } from '../../../Yup';
import { ReactErrorToast, ReactWarningToast, ReactSuccessToast } from '../../../ReactToast/ReactToast';
import { postData, postGetData } from '../../../AxiosInstance/AxiosInstance';
import { Apiservices } from '../../../ApiServices/ApiServices';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useCustomEffect } from '../../../useEffectCommon/useCommonEffect';
import Cookies from "js-cookie";
import "../sign/sign.css";
import { toast } from "react-toastify";

const Reset = () => {
  const navigate = useNavigate()
  let { string } = useParams();
  const [showConPassword, setShowConPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});


  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",

  });

  const handleSignout = () => {
    Cookies.remove("jwtToken");
    navigate("/auth");

  }

  // console.log("EEEEEEEEEEEEEEE");
  const getString = () => {
    const token = string;

    // console.log(token, "EEEEEEEEEEEEEEE");
    // Cookies.set("jwtToken", token, { expires: 1 });
    // const gettoken = Cookies.get("token");

    postGetData(
      `${Apiservices.BACKEND_USER_SERVER, Apiservices.USER_VERIFY_TOKEN_REST_API}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
  useCustomEffect(getString)


  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;
    let spaceError = "";



    setFormData({ ...formData, [name]: sanitizedValue });
    setErrors({ ...errors, [`${name}SpaceError`]: spaceError }); // Set space error separately for each field

    resetPassword
      .validateAt(name, { [name]: sanitizedValue })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };


  const handleBlur = (e) => {
    const { name } = e.target;
    resetPassword
      .validateAt(name, { [name]: formData[name] })
      .then(() => {
        setErrors({ ...errors, [name]: "" });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.message });
      });
  };

  const handleConfirmTogglePassword = () => {
    setShowConPassword((prevState) => !prevState);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };


  const handleReset = async (e) => {
    e.preventDefault();

    const emptyFields = {};
    const labelMapping = {


      password: "Password",
      confirmPassword: "Confirm Password",

    };

    Object.entries(formData).forEach(([key, value]) => {
      if (
        (value === "") ||
        value === undefined ||
        value === null
      ) {
        const label = labelMapping[key] || key;
        emptyFields[key] = `${label} is Required`;
      }
    });

    if (Object.keys(emptyFields).length > 0) {
      setErrors({ ...errors, ...emptyFields })

      return Promise.resolve();
    }
    resetPassword
      .validate(formData, { abortEarly: false })
      .then(async () => {

        Cookies.set("jwtToken", string, { expires: 1 });

        const result = await postData(Apiservices.USER_RESET_REST_API, { newPassword: formData.confirmPassword });

        if (result.status) {

          Cookies.remove("jwtToken");
          navigate("/auth");

          toast.success(result.message)
          setFormData((prevFormData) => ({
            ...prevFormData,
            password: "",
            confirmPassword: ""

          }));

        } else {
          toast.error(result.message)
          setFormData((prevFormData) => ({
            ...prevFormData,
            password: "",
            confirmPassword: ""
          }));
        }
        // console.log(result, "RESSSS");

        setErrors({});

      })
      .catch((error) => {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });



  };



  return (
    <>
      <div className="lgc res">
        <button onClick={handleSignout}>Home</button>


        <div className="log_c frgt reset">

          <strong>Reset Password</strong>
          <div className="inptbx  ">

            <input
              autoComplete='off'
              type={showPassword ? "text" : "password"}
              id="eye"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              onBlur={handleBlur}

            />
            <span id='error'>{errors.password}</span>
            <span className='closeeye' onClick={handleTogglePassword}>
              {showPassword ? (
                <img src='https://images-ultra.s3.amazonaws.com/view_709612_1.png' alt="eye" draggable="false" />
              ) : (
                <img src='https://images-ultra.s3.amazonaws.com/Vector.png' alt='eye' draggable="false"></img>
              )}
            </span>
          </div>
          <div className="inptbx  ">

            <input
              autoComplete='off'
              id="eye"
              type={showConPassword ? "text" : "password"}

              placeholder="Confirm Password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span id='error'>{errors.confirmPassword}</span>
            <span onClick={handleConfirmTogglePassword} className='closeeye'>
              {showConPassword ? (
                <img src="https://images-ultra.s3.amazonaws.com/view_709612_1.png" alt="eye" draggable="false" />
              ) : (
                <img src="https://images-ultra.s3.amazonaws.com/Vector.png" alt='eye' draggable="false"></img>
              )}
            </span>
          </div>
          <button type='submit' onClick={handleReset} className='col-6  btns'>SUBMIT </button>
        </div>
      </div>
    </>
  );
};
export default Reset;
